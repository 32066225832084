import React, {PropsWithChildren, useEffect, useRef} from "react";
import ReactDOM from "react-dom";


const dialogRoot = document.getElementById('dialogs')!

interface DialogPortalProps {
    mounted?: (el: HTMLDivElement) => void;
}

const DialogPortal: React.FC<PropsWithChildren<DialogPortalProps>> = (props) => {
    const el = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (el.current) {
            props.mounted?.(el.current)
        }
    }, [])

    return <>
        {ReactDOM.createPortal(
            <div ref={el}>{props.children}</div>,
            dialogRoot
        )}
    </>
}
export default DialogPortal
