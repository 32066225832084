/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import ProgramCloneRequest from "./ProgramCloneRequest";
import PaginationResponse from "./PaginationResponse";
import ProductLookUpResponse from "./ProductLookUpResponse";
import PaginationProductLookupRequest from "./PaginationProductLookupRequest";
import ProgramRequest from "./ProgramRequest";
import MaterProgramLookups from "./MaterProgramLookups";
import RenameRequest from "./RenameRequest";
import FileContentResult from "./FileContentResult";
import SummaryType from "./SummaryType";
import ContentResult from "./ContentResult";
import ProgramSaveRequest from "./ProgramSaveRequest";
import PlayFieldResponse from "./PlayFieldResponse";
import IdRequest from "./IdRequest";
import ProductSummaryDisplay from "./ProductSummaryDisplay";
import UpdateViewRequest from "./UpdateViewRequest";
import PlayFieldTemplateResponse from "./PlayFieldTemplateResponse";
import TemplateRequest from "./TemplateRequest";
import ProgramHistoryResponseList from "./ProgramHistoryResponseList";
import ProgramFilterRequest from "./ProgramFilterRequest";
import PaginationRequestSearch from "./PaginationRequestSearch";
import TemplateNameId from "./TemplateNameId";
import TemplateListResult from "./TemplateListResult";
import SubmitProgramRequest from "./SubmitProgramRequest";
import ProgramBaseResponseList from "./ProgramBaseResponseList";
import UserShort from "./UserShort";
import RegionResponseByLanguage from "./RegionResponseByLanguage";
import ProgramLandSetup from "./ProgramLandSetup";
import CultivarAllResponse from "./CultivarAllResponse";
import EmailDeclineRequest from "./EmailDeclineRequest";

const urls = {
    pdf(id: number, summary: SummaryType, render: boolean, products: number[]): string {
        return queryUrl("/program/pdf", {id, summary, render, products});
    },
    html(id: number, summary: SummaryType, render: boolean, products: number[]): string {
        return queryUrl("/program/html", {id, summary, render, products});
    },
    templates(): string {
        return "/program/templates";
    },
    templatesRegionSpecific(): string {
        return "/program/templatesregionspecific";
    },
    byUserRegions(): string {
        return "/program/byuserregions";
    },
    userRegionsByLanguage(): string {
        return "/program/userregionsbylanguage";
    },
    excel(id: number, type: SummaryType, selectedProducts: number[]): string {
        return queryUrl("/program/excel", {id, type, selectedProducts});
    }
}

export default {
    urls,
    clone(request: ProgramCloneRequest): Promise<number> {
        return contentOnly(Axios.post("/program/clone", request, defaultConfig));
    },
    productsLookupPaged(request: PaginationProductLookupRequest): Promise<PaginationResponse<ProductLookUpResponse>> {
        return contentOnly(Axios.post("/program/productslookuppaged", request, defaultConfig));
    },
    generate(request: ProgramRequest): Promise<number> {
        return contentOnly(Axios.post("/program/generate", request, defaultConfig));
    },
    lookups(): Promise<MaterProgramLookups> {
        return contentOnly(Axios.post("/program/lookups", defaultConfig));
    },
    rename(request: RenameRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/rename", request, defaultConfig));
    },
    pdf(id: number, summary: SummaryType, render: boolean, products: number[]): Promise<FileContentResult> {
        return contentOnly(Axios.get(queryUrl("/program/pdf", {id, summary, render, products}), defaultConfig));
    },
    html(id: number, summary: SummaryType, render: boolean, products: number[]): Promise<ContentResult> {
        return contentOnly(Axios.get(queryUrl("/program/html", {id, summary, render, products}), defaultConfig));
    },
    save(request: ProgramSaveRequest): Promise<number[]> {
        return contentOnly(Axios.post("/program/save", request, defaultConfig));
    },
    get(request: IdRequest): Promise<PlayFieldResponse> {
        return contentOnly(Axios.post("/program/get", request, defaultConfig));
    },
    summaryProducts(request: IdRequest): Promise<ProductSummaryDisplay[]> {
        return contentOnly(Axios.post("/program/summaryproducts", request, defaultConfig));
    },
    updateView(request: UpdateViewRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/updateview", request, defaultConfig));
    },
    getTemplate(playFieldId: number): Promise<PlayFieldTemplateResponse> {
        return contentOnly(Axios.post("/program/gettemplate", playFieldId, defaultConfig));
    },
    saveTemplate(request: TemplateRequest): Promise<number> {
        return contentOnly(Axios.post("/program/savetemplate", request, defaultConfig));
    },
    history(request: ProgramFilterRequest): Promise<PaginationResponse<ProgramHistoryResponseList>> {
        return contentOnly(Axios.post("/program/history", request, defaultConfig));
    },
    archives(request: PaginationRequestSearch): Promise<PaginationResponse<ProgramHistoryResponseList>> {
        return contentOnly(Axios.post("/program/archives", request, defaultConfig));
    },
    remove(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/remove", request, defaultConfig));
    },
    removeTemplate(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/removetemplate", request, defaultConfig));
    },
    templates(): Promise<TemplateNameId[]> {
        return contentOnly(Axios.get("/program/templates", defaultConfig));
    },
    templatesRegionSpecific(): Promise<TemplateNameId[]> {
        return contentOnly(Axios.get("/program/templatesregionspecific", defaultConfig));
    },
    templatesPaged(request: PaginationRequestSearch): Promise<PaginationResponse<TemplateListResult>> {
        return contentOnly(Axios.post("/program/templatespaged", request, defaultConfig));
    },
    submit(request: SubmitProgramRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/submit", request, defaultConfig));
    },
    approvals(request: PaginationRequestSearch): Promise<PaginationResponse<ProgramBaseResponseList>> {
        return contentOnly(Axios.post("/program/approvals", request, defaultConfig));
    },
    approve(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/approve", request, defaultConfig));
    },
    restore(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/restore", request, defaultConfig));
    },
    byUserRegions(): Promise<UserShort[]> {
        return contentOnly(Axios.get("/program/byuserregions", defaultConfig));
    },
    userRegionsByLanguage(): Promise<RegionResponseByLanguage[]> {
        return contentOnly(Axios.get("/program/userregionsbylanguage", defaultConfig));
    },
    lands(farmId: number): Promise<ProgramLandSetup[]> {
        return contentOnly(Axios.post("/program/lands", farmId, defaultConfig));
    },
    cultivarsByCrop(request: IdRequest): Promise<CultivarAllResponse[]> {
        return contentOnly(Axios.post("/program/cultivarsbycrop", request, defaultConfig));
    },
    decline(request: EmailDeclineRequest): Promise<boolean> {
        return contentOnly(Axios.post("/program/decline", request, defaultConfig));
    },
    excel(id: number, type: SummaryType, selectedProducts: number[]): Promise<FileContentResult> {
        return contentOnly(Axios.get(queryUrl("/program/excel", {id, type, selectedProducts}), defaultConfig));
    }
}
