import React, {useContext, useMemo} from 'react'
import JsonPlayField from '../../controllers/JsonPlayField'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import {conversions, summaryUnit, unitDisplay} from './units'
import ProductUnit from '../../controllers/ProductUnit'
import ElementValues, {divMeasureValue, FormatValue, getValue, makeProductObject, measureValue} from './Helpers'
import AppContext from '../../appContext'
import {cellsToArray, isPricingColumn, isProductColumn} from './Functions'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import {customUnits} from "./CustomUnits";
import {classNames} from "../../wrapper";
import MeasureValue from "../../controllers/MeasureValue";
import ProductColumn from "../../controllers/ProductColumn";

const Summary: React.FC<{
    data: JsonPlayField,
    products: ProductLookUpResponse[],
    symbol?: string,
    pricingModule?: boolean,
    totalFarmSize: MeasureValue
}> = (props) => {
    const {initial: {system}} = useContext(AppContext)
    // convert the spares tables to jagged array
    const cells = useMemo(() => cellsToArray(props.data, system), [props])
    const context = useContext(AppContext)

    const products = useMemo(() => {
        const ret = props.data.columns
            .filter(isProductColumn)
            .map(c => {
                const def = props.products.find(p => p.id === c.productId)
                const unit = summaryUnit(system, conversions[c.unit].form)

                return {
                    key: c.productId,
                    name: def?.nameKey !== null ? context.word(def!.nameKey) : def?.name ?? '',
                    unit: conversions[unit].display
                }
            });
        
        if(props.pricingModule) {
            ret.push({
                key: -1,
                name: context.word('pricing'),
                unit: props.symbol ?? ''
            })
        }
        
        customUnits.kgha_lbAc
        
        return ret;
    }, [props.data.columns])

    function costPerRow(rowTitle: string, getVal: (col: ProductColumn) => MeasureValue) {
        return <tr className="font-bold">
            <td colSpan={2} className="text-right">{rowTitle}</td>
            {props.data.columns.map((column, i) => {
                if (isPricingColumn(column)) {
                    return <td key={i} className="border ">
                        -
                    </td>
                } else if (isProductColumn(column)) {

                    return <td key={i} className="border">
                        <div className="flex justify-between items-center px-1 ">
                            {/*// if the metric is 0 then so is the imperial */}
                            <div
                                className="pr-1">{column.priceTotal != null && column.priceTotal.metric != 0 ? props.symbol : "-"}</div>
                            <div>
                                <FormatValue
                                    value={getValue(system, getVal(column))}
                                    decimalPlaces={1}/>
                            </div>
                        </div>
                    </td>
                }
            })}
        </tr>
    }

    return (
        <table className="text-sm w-full border-collapse">
            <thead>

            <tr>
                <th className="bg-gray-200 font-normal text-left relative" colSpan={2}/>
                {
                    products.map((column) => {
                        return <th key={'name-' + column.key}
                                   className={classNames("border-l border-r text-xs text-gray-800", column.key == -1 ? "bg-[#8B4513] text-[#ffffff]" : "")}>
                            {column.name}
                        </th>
                    })
                }


                <th className="bg-gray-300 text-gray-600 text-center align-bottom" colSpan={6}>
                    {context.word(unitDisplay(system, ProductUnit.KgHa, ProductUnit.LbAc))}
                </th>
                <th className="bg-gray-400 text-gray-600 text-center align-bottom" colSpan={5}>
                    {context.word(unitDisplay(system, ProductUnit.GHa, ProductUnit.OzAc))}
                </th>
            </tr>
            <tr>
                <th className="bg-gray-200 text-gray-800 text-center">{context.word('phenologically')}</th>
                <th className="bg-gray-200 text-gray-800 text-center">{context.word('month')}</th>

                {products.map((column) =>
                    <th key={'unit-' + column.key} className={classNames("border-l border-r text-xs text-gray-800", column.key == -1 ? "bg-[#8B4513] text-[#ffffff]" : "")}>
                        {context.word(column.unit)}
                    </th>)}

                <th className="align-bottom bg-gray-300">{context.word('n')}</th>
                <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('p') : context.word('p205')}</th>
                <th className="align-bottom bg-gray-300">{system === MeasurementUnit.METRIC ? context.word('k') : context.word('k20')}</th>
                <th className="align-bottom bg-gray-300">{context.word('ca')}</th>
                <th className="align-bottom bg-gray-300">{context.word('mg')}</th>
                <th className="align-bottom bg-gray-300">{context.word('s')}</th>
                <th className="align-bottom bg-gray-400">{context.word('fe')}</th>
                <th className="align-bottom bg-gray-400">{context.word('zn')}</th>
                <th className="align-bottom bg-gray-400">{context.word('b')}</th>
                <th className="align-bottom bg-gray-400">{context.word('mn')}</th>
                <th className="align-bottom bg-gray-400">{context.word('cu')}</th>

            </tr>
            </thead>

            <tbody>
            {props.data.rows.map((row, rowIndex) =>
                <tr key={row.desc + row.month}>
                    <td className="border bg-white text-gray-800 whitespace-nowrap">
                        <div className="flex">
                            {row.desc}
                        </div>
                    </td>
                    <td className="border-t border-l border-b bg-white text-gray-800">
                        {row.month}
                    </td>

                    {props.data.columns.map((column, colindex) => {
                            if (isPricingColumn(column) && props.pricingModule) {
                                return <td className="bg-[#8B4513] text-[#ffffff]" key={colindex}>
                                    <div className="flex justify-between items-center px-1">
                                        <div className="pr-1">{props.symbol}</div>
                                        <div>
                                            {
                                                context.initial.system === MeasurementUnit.METRIC
                                                    ? column.rows[rowIndex]!.metric.toFixed(2)
                                                    : column.rows[rowIndex]!.imperial.toFixed(2)
                                            }
                                        </div>
                                    </div>
                                </td>
                            } else if (isProductColumn(column)) {
                                return <td key={column.productId} className="border">
                                    <FormatValue value={getValue(system, cells[colindex]![rowIndex]!.standard)}
                                                 decimalPlaces={1}/>
                                </td>
                            }
                        }
                    )}

                    <ElementValues system={system} values={row.elements} emptyColor="text-white"/>
                </tr>
            )}

            <tr className="font-bold">
                <td colSpan={2} className="text-right">{context.word("Total")}</td>
                {props.data.columns.map((column, i) => {
                    if (isPricingColumn(column) && props.pricingModule) {
                        return <td key={i} className="border bg-[#8B4513] text-[#ffffff]">
                            <div className="flex justify-between items-center px-1">
                                <div className="pr-1">{props.symbol}</div>

                                <div>{column.total!.metric.toFixed(2)}</div>
                            </div>
                        </td>
                    } else if (isProductColumn(column)) {

                        return <td key={i} className="border">
                            <FormatValue value={getValue(system, column.standard)} decimalPlaces={1}/>
                        </td>
                    }
                })}

                <ElementValues system={system} values={makeProductObject(k => props.data.elementTotals[k].standard)}
                               emptyColor="text-white"/>
            </tr>

            {!props.pricingModule ? <></> :
                <>
                    {/*This is for the cost per product*/}
                    {costPerRow(
                        context.word("cost_per_product"), 
                        column => column.priceTotal ?? measureValue(0, 0))}
                    {/*THis is for the cost per ha*/}
                    {costPerRow(
                        context.word("cost_per") + " " + context.wordUnit("ha", "ac"), 
                        column => divMeasureValue(column.priceTotal ?? measureValue(0, 0), props.totalFarmSize))}
                </>
            }

            </tbody>
        </table>
    )
}

export default Summary
