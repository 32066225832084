import React, {useContext, useEffect, useState} from 'react'
import Dialog from '../../components/Dialog'
import AppContext from '../../appContext'
import PlayFieldViewBlock from '../../controllers/PlayFieldViewBlock'
import {CustomUnit, customUnits, measureValueCustomUnit, measureValueProductUnit, unitCustom} from './CustomUnits'
import {getValue, measureValue} from './Helpers'
import cropType from '../../controllers/CropType'
import TableColumns from '../../components/TableColumns'
import Icons from './Icons'
import {onInputHandler, useAjaxData} from '../../wrapper'
import {buildSetter} from '../../immutableState'
import Number from '../../components/Number'
import Input from '../../components/Input'
import {AutoComplete} from '../../components/AutoComplete'
import CropController from '../../controllers/CropController'
import CultivarController from '../../controllers/CultivarController'
import convert, {makeValue, Unit} from './converter'
import {MeasureValueField} from './MeasureValueField'
import CropResponse from '../../controllers/CropResponse'
import CultivarAllResponse from '../../controllers/CultivarAllResponse'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import MeasureValue from '../../controllers/MeasureValue'
import ProductUnit from '../../controllers/ProductUnit'
import {conversions, oppositeUnit, unitDisplay} from './units'
import SelectString from "../../components/SelectString";
import {AimUnitsOptionsImperial, AimUnitsOptionsMetric} from "./SetupLandFieldsColumns";
import ProgramController from "../../controllers/ProgramController";

function buildLabel (image: React.ReactNode, word: string): React.ReactNode {
    return <div className="whitespace-nowrap pr-3">
        {image}
        <div className="inline-block">{word}:</div>
    </div>
}

export function roundTo (number: number, decimalPlaces: number): number {
    return Math.round(number * Math.pow(10, decimalPlaces)) / (Math.pow(10, decimalPlaces))
}

export function roundToNullable (number: number | null, decimalPlaces: number): number | null {
    if (number === null) {
        return null;
    }
    return Math.round(number * Math.pow(10, decimalPlaces)) / (Math.pow(10, decimalPlaces))
}

interface ViewBlockProps {
    viewBlock: PlayFieldViewBlock;
    readonly: boolean;
    setViewBlock?: (data: Partial<PlayFieldViewBlock>) => void
}

function readonlyText (system: MeasurementUnit, type: MeasureValue, unit: CustomUnit| ProductUnit, word: (key: string) => string) {
    return <div className='flex'>
        <div className="font-bold pl-2">{roundTo(getValue(system, type), 1)}</div>
        
        <div className='pr-2 pl-1 font-bold'>{word(unitCustom(system, unit))}</div>
        
    </div>
}


function readonlyTextEx(system: MeasurementUnit, type: MeasureValue, metric: ProductUnit, imperial: ProductUnit, word: (key: string) => string) {
    return <div className='flex'>
        <div className="font-bold pl-2">{roundTo(getValue(system, type), 1)}</div>
        
        <div className='pr-2 pl-1 font-bold'>{word(unitDisplay(system, metric, imperial))}</div>
        
    </div>
}

const ViewBlockEditAnnual: React.FC<ViewBlockProps & { waterUsed: boolean }> = (props) => {
    const {
        word,
        initial: { system }
    } = useContext(AppContext)

    const data = props.viewBlock
    const readonly = props.readonly
    const setData = props.setViewBlock
    const [unit, setSetUnit] =  useState<ProductUnit>(system == MeasurementUnit.METRIC ? data.estimateProductionUnitMetric : data.estimateProductionUnitImperial)
    const [preUnit, setSetPreUnit] =  useState<ProductUnit>(system == MeasurementUnit.METRIC ? data.totalProductionUnitMetric : data.totalProductionUnitImperial)
    const setUnit: (value: ProductUnit) => void = (value: ProductUnit) => {
        setSetUnit(value)
        if (system == MeasurementUnit.METRIC) {
            setData?.({ estimateProductionUnitMetric: value, estimateProductionUnitImperial: oppositeUnit(value)})
            return 
        }
        setData?.({ estimateProductionUnitMetric: oppositeUnit(value), estimateProductionUnitImperial: value })
    }
    const setPreUnit: (value: ProductUnit) => void = (value: ProductUnit) => {
        setSetPreUnit(value)
        if (system == MeasurementUnit.METRIC) {
            setData?.({ totalProductionUnitMetric: value, totalProductionUnitImperial: oppositeUnit(value) })
            return 
        }
        setData?.({ totalProductionUnitMetric: oppositeUnit(value), totalProductionUnitImperial: value })
    }

    
    const columns = [
        buildLabel(Icons.irrigation, word('irrigation')),
        readonly ? <div className="font-bold px-2">{data.irrigation} </div> :
            <Input value={data.irrigation} change={value => setData?.({ irrigation: value })}/>,

        buildLabel(Icons.trees, word('plantspha')),
        readonly ? <div className="font-bold px-2">{data.plantsperSize} </div> :
            <Number value={roundTo(data.plantsperSize, 2)} change={value => setData?.({ plantsperSize: value })}/>,

        buildLabel(Icons.size, `${word('rowwidth')} ${readonly ? "" : ' (' + unitCustom(system, customUnits.mm_Feet) +')'}`),
        readonly
            ? readonlyText(system, data.rowWidth, customUnits.mm_Feet, word)
            : <Number value={roundTo(getValue(system, data.rowWidth), 2)}
                      change={value => setData?.({ rowWidth: measureValueCustomUnit(value, customUnits.mm_Feet, system) })}/>,
        
        buildLabel(Icons.aim, `${word('aim ')}`),
        readonly
            ? readonlyTextEx(system, data.estimateProduction, data.estimateProductionUnitMetric, data.estimateProductionUnitImperial, word)
            :<div className={"flex "}>
                <Number value={roundTo(getValue(system, data.estimateProduction), 2)}
                          change={value => setData?.({ estimateProduction: measureValueProductUnit(value, data.estimateProductionUnitMetric, data.estimateProductionUnitImperial, system) })}/>
                <SelectString options={system === MeasurementUnit.METRIC ? AimUnitsOptionsMetric : AimUnitsOptionsImperial}
                              textFunc={t => conversions[t].display}
                              valueFunc={t => t}
                              value={unit}
                              onChange={c => {setUnit(c as ProductUnit)} }/>
            </div>,

        buildLabel(Icons.size, `${word('stickwidth')} ${readonly ? "" : ' (' + unitCustom(system, customUnits.mm_Feet) + ')'}`),
        readonly
            ? readonlyText(system, data.stickWidth, customUnits.mm_Feet, word)
            : <Number value={roundTo(getValue(system, data.stickWidth), 2)}
                      change={value => setData?.({ stickWidth: measureValueCustomUnit(value, customUnits.mm_Feet, system) })}/>,

        buildLabel(Icons.previous, `${word('previous_production')} ${readonly ? "" : ' (' + word(unitDisplay(system, data.totalProductionUnitMetric, data.totalProductionUnitImperial)) +')'}`),
        readonly
            ? readonlyTextEx(system, data.totalProduction, data.totalProductionUnitMetric, data.totalProductionUnitImperial, word)
            : <div className="flex inline">
                <Number value={getValue(system, measureValue(roundTo(data.totalProduction.metric, 2), roundTo(data.totalProduction.imperial, 2)))}
                        change={value => setData?.({ totalProduction: measureValueProductUnit( value,data.totalProductionUnitMetric, data.totalProductionUnitImperial ,system) })}/>
                <SelectString options={system === MeasurementUnit.METRIC ? AimUnitsOptionsMetric : AimUnitsOptionsImperial}
                              textFunc={t => conversions[t].display}
                              valueFunc={t => t}
                              value={preUnit}
                              onChange={c => {setPreUnit(c as ProductUnit)} }/>
            </div>,
    ]

    if (props.waterUsed) {
        columns.push(
            buildLabel(Icons.water, `${word('water_used')} ${readonly ? "" : ' (' + word(unitCustom(system, customUnits.lHa_gallonAc)) + ')'}`)
        )
        columns.push(readonly
            ? readonlyText(system, data.water, customUnits.lHa_gallonAc, word)
            : <Number value={roundTo(getValue(system, data.water), 2)} change={value => {
                setData?.({ water: measureValueCustomUnit(value, customUnits.lHa_gallonAc, system) })
            }}/>)
    }

    return <TableColumns countCount={4} columns={columns}/>
}

const ViewBlockEditPerennial: React.FC<ViewBlockProps & { waterUsed: boolean }> = ({
    readonly,
    viewBlock: data,
    setViewBlock: setData,
    waterUsed
}) => {
    const {
        word,
        initial: { system }
    } = useContext(AppContext)
    const context = useContext(AppContext)

    useEffect(() => {
        const treeAge = new Date().getFullYear() - data.yearPlanted
        const size = convert(makeValue(data.size.metric, Unit.Hectare), Unit.Meter2)
        const rowSpacing = convert(makeValue(data.rowSpacing.metric, Unit.Millimeter), Unit.Meter)
        const treeSpacing = convert(makeValue(data.treeSpacing.metric, Unit.Millimeter), Unit.Meter)
        if (size === null || rowSpacing === null || treeSpacing === null) {
            return
        }
        const totalTrees = Math.floor(size / (rowSpacing * treeSpacing))
        const treesPerSize = Math.floor(totalTrees / getValue(system, data.size))
        setData?.({
            treeAge,
            plantsperSize: treesPerSize
        })
    }, [])

    const [unit, setSetUnit] =  useState<ProductUnit>(system == MeasurementUnit.METRIC ? data.estimateProductionUnitMetric : data.estimateProductionUnitImperial)
    const [preUnit, setSetPreUnit] =  useState<ProductUnit>(system == MeasurementUnit.METRIC ? data.totalProductionUnitMetric : data.totalProductionUnitImperial)
    const setUnit: (value: ProductUnit) => void = (value: ProductUnit) => {
        setSetUnit(value)
        if (system == MeasurementUnit.METRIC) {
            setData?.({ estimateProductionUnitMetric: value as ProductUnit, estimateProductionUnitImperial: oppositeUnit(value as ProductUnit) })
            return
        }
        setData?.({ estimateProductionUnitMetric: oppositeUnit(value as ProductUnit), estimateProductionUnitImperial: value as ProductUnit })
    }
    const setPreUnit: (value: ProductUnit) => void = (value: ProductUnit) => {
        setSetPreUnit(value)
        if (system == MeasurementUnit.METRIC) {
            setData?.({ totalProductionUnitMetric: value, totalProductionUnitImperial: oppositeUnit(value) })
            return
        }
        setData?.({ totalProductionUnitMetric: oppositeUnit(value), totalProductionUnitImperial: value })
    }
    const columns = [
        buildLabel(Icons.plant, word('planted_in')),
        readonly
            ? <div className="font-bold px-2">{roundTo(data.yearPlanted, 0)} </div>
            : <Number value={Math.floor(data.yearPlanted)} change={value => {
                const treeAge = new Date().getFullYear() - value
                setData?.({
                    yearPlanted: value,
                    treeAge
                })
            }}/>,

        buildLabel(Icons.irrigation, word('irrigation')),
        readonly ? <div className="font-bold px-2">{data.irrigation} </div> :
            <Input value={data.irrigation} change={value => setData?.({ irrigation: value })}/>,
        
        buildLabel(Icons.aim, `${word('aim ')}`),
        readonly
            ? readonlyTextEx(system, data.estimateProduction, data.estimateProductionUnitMetric, data.estimateProductionUnitImperial, word)
            :<div className="flex inline">
                <Number value={roundTo(getValue(system, data.estimateProduction), 2)}
                              change={value => setData?.({ estimateProduction: measureValueProductUnit( value, data.estimateProductionUnitMetric, data.estimateProductionUnitImperial, system) })}/>
                <SelectString options={system === MeasurementUnit.METRIC ? AimUnitsOptionsMetric : AimUnitsOptionsImperial}
                              textFunc={t => conversions[t].display}
                              valueFunc={t => t}
                              value={unit}
                              onChange={c => {setUnit(c as ProductUnit)} }/>
            </div> ,

        buildLabel(Icons.trees, `${word('trees_p')} ${unitCustom(system, customUnits.ha_ac)}`),
        // eslint-disable-next-line react/jsx-key
        <div className="font-bold px-2">{Math.floor(data.totalTrees / getValue(context.initial.system, data.size))}</div>,

        buildLabel(Icons.size, `${word('rowSpacing')} ${readonly ? "" : ' (' + unitCustom(system, customUnits.mm_Feet) + ')'}`),
        readonly
            ? readonlyText(system, data.rowSpacing, customUnits.mm_Feet, word)
            : <MeasureValueField unit={customUnits.mm_Feet} value={measureValue(roundTo(data.rowSpacing.metric, 2), roundTo(data.rowSpacing.imperial, 2))} update={value => {
                const size = convert(makeValue(data.size.metric, Unit.Hectare), Unit.Meter2)
                const rowSpacing = convert(makeValue(value.metric, Unit.Millimeter), Unit.Meter)
                const treeSpacing = convert(makeValue(data.treeSpacing.metric, Unit.Millimeter), Unit.Meter)
                if (size === null || rowSpacing === null || treeSpacing === null) {
                    return
                }
                const totalTrees = Math.floor(size / (rowSpacing * treeSpacing))
                const treesPerSize = Math.floor(totalTrees / getValue(system, data.size))
                setData?.({
                    rowSpacing: value,
                    totalTrees,
                    plantsperSize: treesPerSize
                })
            }}/>,

        buildLabel(Icons.age, `${word('tree_age')}`),
        readonly
            ? <div className="font-bold px-2">{roundTo((new Date().getFullYear() - data.yearPlanted), 0)} </div>
            : <Number value={roundTo(data.treeAge, 2)} change={value => {
                setData?.({
                    yearPlanted: new Date().getFullYear() - value,
                    treeAge: value
                })
            }}/>,

        buildLabel(Icons.size, `${word('treeSpacing')} ${readonly ? '' : ' (' + unitCustom(system, customUnits.mm_Feet) + ')'}`),
        readonly
            ? readonlyText(system, data.treeSpacing, customUnits.mm_Feet, word)
            : <MeasureValueField unit={customUnits.mm_Feet} value={measureValue(roundTo(data.treeSpacing.metric, 2), roundTo(data.treeSpacing.imperial, 2))} update={value => {
                const size = convert(makeValue(data.size.metric, Unit.Hectare), Unit.Meter2)
                const rowSpacing = convert(makeValue(data.rowSpacing.metric, Unit.Millimeter), Unit.Meter)
                const treeSpacing = convert(makeValue(value.metric, Unit.Millimeter), Unit.Meter)
                if (size === null || rowSpacing === null || treeSpacing === null) {
                    return
                }
                const totalTrees = Math.floor(size / (rowSpacing * treeSpacing))
                const treesPerSize = Math.floor(totalTrees / getValue(system, data.size))
                setData?.({
                    treeSpacing: value,
                    totalTrees,
                    plantsperSize: treesPerSize
                })
            }}/>,
        
        buildLabel(Icons.previous, `${word('previous_production')}`),
         readonly
            ? readonlyTextEx(system, data.totalProduction, data.totalProductionUnitMetric, data.totalProductionUnitImperial, word)
            : <div className="flex">
                <Number value={getValue(system, measureValue(roundTo(data.totalProduction.metric, 2), roundTo(data.totalProduction.imperial, 2)))}
                      change={value => setData?.({ totalProduction: measureValueProductUnit( value,data.totalProductionUnitMetric, data.totalProductionUnitImperial ,system) })}/>
                <SelectString options={system === MeasurementUnit.METRIC ? AimUnitsOptionsMetric : AimUnitsOptionsImperial}
                              textFunc={t => conversions[t].display}
                              valueFunc={t => t}
                              value={preUnit}
                              onChange={c => {setPreUnit(c as ProductUnit)} }/>
            </div>, 

        buildLabel(Icons.plant, word('root_stock')),
        readonly ? <div className="font-bold px-2">{data.rootStock}</div> :
            <Input value={data.rootStock} change={value => setData?.({ rootStock: value })}/>

    ]

    if (waterUsed) {
        columns.push(
            buildLabel(Icons.water, `${word('water_used')} ${readonly ? '' : ' (' + word(unitCustom(system, customUnits.lHa_gallonAc)) + ')'}`)
        )
        columns.push(
            readonly
                ? readonlyText(system, data.water, customUnits.lHa_gallonAc, word)
                : <Number value={roundTo(getValue(system, data.water),2)}
                          change={value => setData?.({ water: measureValueCustomUnit(value, customUnits.lHa_gallonAc, system) })}/>
        )
    }

    return <TableColumns countCount={4} columns={columns}/>
}

const ViewBlockEdit: React.FC<{
    viewBlock: PlayFieldViewBlock;
    readonly: boolean;

    discard: () => void;
    update: (data: PlayFieldViewBlock) => void;
    farmName: string;
    waterUsed: boolean
}> = (props) => {
    const {
        word,
        initial: { system }
    } = useContext(AppContext)

    const [viewBlock, _setViewBlock] = useState(props.viewBlock)
    const setViewBlock = buildSetter(viewBlock, _setViewBlock)

    const [crops, setCrops] = useState<CropResponse[]>([])
    const [cultivars, setCultivars] = useState<CultivarAllResponse[]>([])

    useAjaxData(() => CropController.index(), setCrops)

    useEffect(() => {
        ProgramController.cultivarsByCrop({ id: viewBlock.cropId }).then(resp => {
            setCultivars(resp)
        })
    }, [viewBlock.cropId])

    return <div className="m-4" onKeyUp={e => e.key ==="Enter" ? props.update(viewBlock) : null}>

        <table className="w-full">
            <tbody>
            <tr>
                <td>{word('farm_name')}</td>
                <td>{props.farmName }</td>
            </tr>
            <tr>
                <td>{word('block_name')}</td>
                <td>
                    <input className="input" value={viewBlock.blockName}
                           onInput={onInputHandler(v => setViewBlock({ blockName: v }))}/>
                </td>
            </tr>
            <tr>
                <td>{word('crop_name')}</td>
                <td>
                    <AutoComplete options={crops} textFunc={c => c.name} valueFunc={c => c.id} value={viewBlock.cropId}
                                  onChange={(value, option) => {
                                      setViewBlock({
                                          cropId: value,
                                          cropNameKey: option.name
                                      })
                                  }}/>
                </td>
            </tr>
            <tr>
                <td>{word('cultivar_name')}</td>
                <td>
                    <AutoComplete options={cultivars} textFunc={c => c.name} valueFunc={c => c.id}
                                  value={viewBlock.cultivarId} onChange={(value, option) => {
                        setViewBlock({
                            cultivarId: value,
                            cultivarNameKey: option.name
                        })
                    }}/>
                </td>
            </tr>
            <tr>
                <td>{word('size')} ({unitCustom(system, customUnits.ha_ac)})</td>
                <td>
                    <Number value={roundTo(getValue(system, viewBlock.size), 2)} change={value => {
                        const size = measureValueCustomUnit(value, customUnits.ha_ac, system)
                        const plantsperSize = viewBlock.totalTrees / getValue(system, size)

                        setViewBlock({
                            size,
                            plantsperSize
                        })
                    }}/>
                </td>
            </tr>

            {/* show trees only on Perennial. Share the same layout */}
            {viewBlock.cropTYpe === cropType.Perennial
                ? <tr>
                    <td>{word('total_trees')}</td>
                    <td>
                        <Number value={viewBlock.totalTrees} change={v => {
                            setViewBlock({
                                totalTrees: Math.floor(v),
                                plantsperSize: Math.floor(v / getValue(system, viewBlock.size))
                            })
                        }}/>
                    </td>
                </tr>
                : null}
            </tbody>
        </table>

        {props.viewBlock.cropTYpe === cropType.Perennial
            ? <ViewBlockEditPerennial readonly={props.readonly} waterUsed={props.waterUsed} viewBlock={viewBlock}
                                      setViewBlock={setViewBlock}/>
            : <ViewBlockEditAnnual waterUsed={props.waterUsed} readonly={props.readonly} viewBlock={viewBlock}
                                   setViewBlock={setViewBlock}/>}

        <div className="border-gray-100 border-t mt-2 text-right">
            <div className="btn bg-red-500 m-2" onClick={() => props.discard()}>{word('discard')}</div>
            <div className="btn bg-primary m-2" onClick={() => props.update(viewBlock)}>{word('update')}</div>
        </div>

    </div>
}

const ViewBlock: React.FC<{
    data: PlayFieldViewBlock;
    update: (data: PlayFieldViewBlock) => void;
    waterUsed: boolean;
    farmName: string;
}> = (props) => {
    const [show, setShow] = useState(false)
    const app = useContext(AppContext)

    return (
        <div>
            <Dialog
                title={props.data.cropTYpe === cropType.Perennial ? app.word('edit_perennial') : app.word('edit_annual')}
                body={
                    <ViewBlockEdit
                        readonly={false}
                        viewBlock={props.data}
                        discard={() => setShow(false)}
                        update={viewBlocks => {
                            setShow(false)
                            props.update(viewBlocks)
                        }}
                        waterUsed={props.waterUsed}
                        farmName= {props.farmName}
                    />}

                show={show} setShow={setShow}/>

            <div className="absolute m-1 right-0 bg-primary btn-sm"
                 onClick={() => setShow(true)}>{app.word('edit')}</div>

            <img src={props.data.imageLow} alt="" className="ml-4 inline-block shadow-lg w-32 h-32 rounded-full"/>
            <div className="pl-4 inline-block align-middle h-full">
                <div className="text-2xl"><span>{props.farmName}</span><span
                    className="w-1 h-1 bg-gray-700 rounded-full inline-block align-middle mx-1"></span><span>{props.data.blockName}</span>
                </div>
                <div className='flex items-end'>
                    <div className="text-gray-700 tracking-wider"><span
                        className="uppercase text-s">{app.word(props.data.cropNameKey)}</span>
                        <div className="uppercase text-xs">{app.word(props.data.cultivarNameKey)}</div>
                    </div>
                    {props.data.cropTYpe === cropType.Perennial
                        ? <div className="pl-4 text-right"><span
                            className="ml-4 text-base font-bold">{props.data.totalTrees}</span><span
                            className="text-xs text-gray-700">{app.word('total_trees')}</span>
                        </div>
                        : null
                    }
                </div>
                <div className="text-left text-xs"><span
                    className="text-base font-bold">{roundTo(getValue(app.initial.system, props.data.size), 1)} ({unitCustom(app.initial.system, customUnits.ha_ac)})</span>
                </div>
            </div>
            <div className="pl-4 inline-block align-middle">
                {props.data.cropTYpe === cropType.Perennial
                    ? <ViewBlockEditPerennial waterUsed={props.waterUsed} readonly={true} viewBlock={props.data}/>
                    : <ViewBlockEditAnnual waterUsed={props.waterUsed} readonly={true} viewBlock={props.data}/>}
            </div>
        </div>
    )
}

export default React.memo(ViewBlock)
