/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import CultivarAllResponse from "./CultivarAllResponse";
import PaginationResponse from "./PaginationResponse";
import CultivarResponse from "./CultivarResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import CultivarRequest from "./CultivarRequest";
import IdActiveRequest from "./IdActiveRequest";

const urls = {
    index(): string {
        return "/cultivar/index";
    }
}

export default {
    urls,
    index(): Promise<CultivarAllResponse[]> {
        return contentOnly(Axios.get("/cultivar/index", defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<CultivarResponse>> {
        return contentOnly(Axios.post("/cultivar/paged", request, defaultConfig));
    },
    upsert(request: CultivarRequest): Promise<boolean> {
        return contentOnly(Axios.post("/cultivar/upsert", request, defaultConfig));
    },
    toggleActive(request: IdActiveRequest): Promise<boolean> {
        return contentOnly(Axios.post("/cultivar/toggleactive", request, defaultConfig));
    }
}
