/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import Role from "./Role";
import RolesRequest from "./RolesRequest";
import IdRequest from "./IdRequest";
import PaginationResponse from "./PaginationResponse";
import RoleDisplay from "./RoleDisplay";
import PaginationRequestSearch from "./PaginationRequestSearch";

const urls = {
    index(): string {
        return "/role/index";
    }
}

export default {
    urls,
    index(): Promise<Role[]> {
        return contentOnly(Axios.get("/role/index", defaultConfig));
    },
    upsert(request: RolesRequest): Promise<boolean> {
        return contentOnly(Axios.post("/role/upsert", request, defaultConfig));
    },
    delete(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/role/delete", request, defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<RoleDisplay>> {
        return contentOnly(Axios.post("/role/paged", request, defaultConfig));
    }
}
