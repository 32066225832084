import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useEffect, useRef, useState } from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import AppContext from '../appContext'
import ProductController from '../controllers/ProductController'
import ProductResponse from '../controllers/ProductResponse'
import CategoryController from '../controllers/CategoryController'
import CompanyController from '../controllers/CompanyController'
import RegionController from '../controllers/RegionController'
import ProductRequest from '../controllers/ProductRequest'
import { useAjaxData, useStateAjax } from '../wrapper'
import LanguageController from '../controllers/LanguageController'
import { AutoComplete } from '../components/AutoComplete'
import { useValidation } from '../validation'
import { buildSetter } from '../immutableState'
import CheckBox from '../components/CheckBox'
import KeysLookup from '../controllers/KeysLookup'
import Input from '../components/Input'
import SelectNumber from '../components/SelectNumber'
import NumberNullable from '../components/NumberNullable'
import Number from '../components/Number'
import { MultiCheckbox } from '../components/MultiCheckBoxString'
import { showSuccessOrFailed } from '../Snacks'
import {arrayToRec} from "../array";
import {roundTo} from "./program/ViewBlock";

interface ProductRequestForm extends ProductRequest {
    nameTranslatable: boolean,
    nameKeyIdForm: number
}

const emptyProduct: ProductRequestForm = {
    nameTranslatable: false,
    nameKeyId: null,
    nameKeyIdForm: 0,
    brochure: null,
    label: null,
    msds: null,
    active: true,
    b: 0,
    ca: 0,
    brochureId: null,
    msdsId: null,
    labelId: null,
    categoryId: 0,
    companyId: 0,
    cu: 0,
    fe: 0,
    id: 0,
    k: 0,
    k20: 0,
    liquid: false,
    maxQuantityPHa: '',
    mg: 0,
    mn: 0,
    mo: 0,
    n: 0,
    name: '',
    ni: 0,
    p: 0,
    p205: 0,
    packaging: '',
    regions: [],
    s: 0,
    sg: 0,
    solid: false,
    zn: 0
}

function toForm (item: ProductResponse): ProductRequestForm {
    return {
        nameKeyIdForm: item.nameKeyId ?? 0,
        nameTranslatable: item.nameKeyId !== null,
        nameKeyId: item.nameKeyId,
        labelId: item.labelId,
        msdsId: item.msdsId,
        brochureId: item.brochureId,
        active: item.active,
        b: item.b,
        brochure: null,
        ca: item.ca,
        categoryId: item.categoryId,
        companyId: item.companyId,
        cu: item.cu,
        fe: item.fe,
        id: item.id,
        k: item.k,
        k20: item.k20,
        label: null,
        liquid: item.liquid,
        maxQuantityPHa: item.maxQuantityPHa,
        mg: item.mg,
        mn: item.mn,
        mo: item.mo,
        msds: null,
        n: item.n,
        name: item.name,
        ni: item.ni,
        p: item.p,
        p205: item.p205,
        packaging: item.packaging,
        regions: item.regions,
        s: item.s,
        sg: item.sg,
        solid: item.solid,
        zn: item.zn
    }
}

function calc(value: number | null, factor: number): number | null {
    return value 
        ? roundTo(value * factor,1) 
        : null;
}

const pToP205 = 2.2915;
const kToK20 = 1.2047;


const Products: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<ProductResponse>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<ProductRequestForm>(emptyProduct)
    const setData = buildSetter(upsertData, setUpsertData)

    function showPopup (preset: ProductRequestForm) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertCallback (data: ProductRequestForm) {
        if (!validation.validate()) { return }
        data.nameKeyId = data.nameTranslatable ? data.nameKeyIdForm : null

        showSuccessOrFailed(context, ProductController.upsert(data), upsertData.id === 0 ? context.word('product_added') : context.word('product_updated'))
            .then(() => {
                pagedTableRef.current?.refresh()
                setShow(false)
            })
    }

    function setActive (id: number, state: boolean) {
        ProductController.toggleActive({
            active: state,
            id
        })
        pagedTableRef.current?.updateRow(r => r.id === id, r => {
            r.active = state
        })
        showSuccessOrFailed(context, ProductController.toggleActive({
            id,
            active: state
        })).then(() => {
            pagedTableRef.current?.refresh()
        })
    }

    const strikeOut = (product: ProductResponse): string => {
        return !product.active ? 'text-red-500 italic line-through' : ''
    }

    const [categories] = useStateAjax(() => CategoryController.index())
    const [companies] = useStateAjax(() => CompanyController.index())

    const [regions, setRegions] = useState<Record<number, string>>({})

    const [langOptions, setLangOptions] = useState<KeysLookup[]>([])
    useAjaxData(() => LanguageController.keysLookup(), setLangOptions)

    useEffect(() => {
        RegionController.index().then(r => setRegions(arrayToRec(r, i => i.id, i => i.name)))
    }, [])

    const validation = useValidation({
        categoryRequired: () => upsertData.categoryId !== 0,
        typeRequired: () => (upsertData.solid || upsertData.liquid),
        nameTranslatableRequired: () => !upsertData.nameTranslatable || upsertData.nameKeyIdForm > 0,
        nameRequired: () => upsertData.nameTranslatable || upsertData.name.length > 0,
        companyRequired: () => upsertData.companyId !== 0,
        sgRequired: () => upsertData.sg > 0,
        regionsRequired: () => upsertData.regions.length > 0
    })

    return (
        <>
            <Dialog mounted={focusFirstInput}
                title={upsertData.id === 0 ? context.word('add_product') : context.word('edit_product')} show={show}
                setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)}
                        saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('category'),
                            <AutoComplete options={categories} textFunc={t => t.name} valueFunc={t => t.id}
                                value={upsertData.categoryId} onChange={v => setData({ categoryId: v })}/>,
                            validation.rules.categoryRequired,
                            context.word('category_required')
                        )}
                        {EditRow(
                            context.word('liquid'),
                            <CheckBox value={upsertData.liquid} onChange={v => setData({ liquid: v })}/>,
                            validation.rules.typeRequired,
                            context.word('type_required')
                        )}
                        {EditRow(
                            context.word('solid'),
                            <CheckBox value={upsertData.solid} onChange={v => setData({ solid: v })}/>
                        )}
                        {EditRow(
                            context.word('name_translatable'),
                            <CheckBox value={upsertData.nameTranslatable}
                                onChange={v => setData({ nameTranslatable: v })}/>
                        )}
                        {upsertData.nameTranslatable
                            ? EditRow(
                                context.word('translatable_name'),
                                langOptions.length === 0
                                    ? <div>{context.word('loading_languages')}</div>
                                    : <AutoComplete options={langOptions} textFunc={t => t.text} valueFunc={v => v.keyId}
                                        value={upsertData.nameKeyIdForm}
                                        onChange={v => setData({ nameKeyIdForm: v })}/>,
                                validation.rules.nameTranslatableRequired,
                                context.word('name_translatable_required')
                            )
                            : EditRow(
                                context.word('name'),
                                <Input value={upsertData.name} change={v => setData({ name: v })}/>,
                                validation.rules.nameRequired,
                                context.word('name_required')
                            )}
                        {EditRow(
                            context.word('company'),
                            <SelectNumber options={companies} textFunc={t => t.name ?? ''} valueFunc={v => v.id}
                                value={upsertData.companyId} onChange={v => setData({ companyId: v })}/>,
                            validation.rules.companyRequired,
                            context.word('company_required')
                        )}
                        {EditRow(
                            context.word('n'),
                            <NumberNullable value={upsertData.n} change={v => setData({ n: v })}/>
                        )}
                        {EditRow(
                            context.word('p'),
                            <NumberNullable value={upsertData.p} change={v => setData({ p: v , p205: calc(v, pToP205)})}/>
                        )}
                        {EditRow(
                            context.word('p205'),
                            <NumberNullable value={upsertData.p205} change={v => setData({ p205: v, p: calc(v, 1 / pToP205)}) } />
                        ) }
                        {EditRow(
                            context.word('k'),
                            <NumberNullable value={upsertData.k} change={v => setData({ k: v , k20: calc(v, kToK20)})}/>
                        )}
                        {EditRow(
                            context.word('k20'),
                            <NumberNullable value={upsertData.k20} change={v => setData({ k20: v ,k: calc(v, 1/ kToK20)})}/>
                        )}
                        {EditRow(
                            context.word('ca'),
                            <NumberNullable value={upsertData.ca} change={v => setData({ ca: v })}/>
                        )}
                        {EditRow(
                            context.word('mg'),
                            <NumberNullable value={upsertData.mg} change={v => setData({ mg: v })}/>
                        )}
                        {EditRow(
                            context.word('s'),
                            <NumberNullable value={upsertData.s} change={v => setData({ s: v })}/>
                        )}
                        {EditRow(
                            context.word('b'),
                            <NumberNullable value={upsertData.b} change={v => setData({ b: v })}/>
                        )}
                        {EditRow(
                            context.word('fe'),
                            <NumberNullable value={upsertData.fe} change={v => setData({ fe: v })}/>
                        )}
                        {EditRow(
                            context.word('mn'),
                            <NumberNullable value={upsertData.mn} change={v => setData({ mn: v })}/>
                        )}
                        {EditRow(
                            context.word('zn'),
                            <NumberNullable value={upsertData.zn} change={v => setData({ zn: v })}/>
                        )}
                        {EditRow(
                            context.word('cu'),
                            <NumberNullable value={upsertData.cu} change={v => setData({ cu: v })}/>
                        )}
                        {EditRow(
                            context.word('mo'),
                            <NumberNullable value={upsertData.mo} change={v => setData({ mo: v })}/>
                        )}
                        {EditRow(
                            context.word('ni'),
                            <NumberNullable value={upsertData.ni} change={v => setData({ ni: v })}/>
                        )}
                        {EditRow(
                            context.word('sg'),
                            <Number value={upsertData.sg} change={v => setData({ sg: v })}/>,
                            validation.rules.sgRequired,
                            context.word('greater_than_zero')
                        )}
                        {EditRow(
                            context.word('packaging_size'),
                            <Input value={upsertData.packaging} change={v => setData({ packaging: v })}/>
                        )}
                        {EditRow(
                            context.word('regions'),
                            <MultiCheckbox options={regions} value={upsertData.regions}
                                setValue={v => setData({ regions: v })}/>,
                            validation.rules.regionsRequired,
                            context.word('region_required')
                        )}
                    </EditTable>
                }
            />

            <div className="btn bg-primary m-2"
                onClick={() => showPopup(emptyProduct)}>{context.word('new_product')}</div>
            <PagedSearchTable<ProductResponse>
                componentRef={pagedTableRef}
                call={ProductController.paged}
                columns={[
                    { header: context.word('id'), row: item => <div className={strikeOut(item)}> {item.id} </div> },
                    {
                        header: context.word('category'),
                        row: item => <div className={strikeOut(item)}> {item.category} </div>
                    },
                    {
                        header: context.word('type'),
                        row: item => <div
                            className={strikeOut(item)}> {item.solid && item.liquid ? context.word('other') : item.solid ? context.word('solid') : context.word('liquid')} </div>
                    },
                    {
                        header: context.word('name'),
                        row: item => <div
                            className={strikeOut(item)}> {item.nameKeyId !== null && item.nameKey ? context.word(item.nameKey) : item.name} </div>
                    },
                    {
                        header: context.word('company'),
                        row: item => <div className={strikeOut(item)}> {item.company} </div>
                    },
                    { header: context.word('n'), row: item => <div className={strikeOut(item)}> {item.n} </div> },
                    { header: context.word('p'), row: item => <div className={strikeOut(item)}> {item.p} </div> },
                    { header: context.word('k'), row: item => <div className={strikeOut(item)}> {item.k} </div> },
                    { header: context.word('ca'), row: item => <div className={strikeOut(item)}> {item.ca} </div> },
                    { header: context.word('mg'), row: item => <div className={strikeOut(item)}> {item.mg} </div> },
                    { header: context.word('s'), row: item => <div className={strikeOut(item)}> {item.s} </div> },
                    { header: context.word('b'), row: item => <div className={strikeOut(item)}> {item.b} </div> },
                    { header: context.word('fe'), row: item => <div className={strikeOut(item)}> {item.fe} </div> },
                    { header: context.word('mn'), row: item => <div className={strikeOut(item)}> {item.mn} </div> },
                    { header: context.word('zn'), row: item => <div className={strikeOut(item)}> {item.zn} </div> },
                    { header: context.word('cu'), row: item => <div className={strikeOut(item)}> {item.cu} </div> },
                    { header: context.word('mo'), row: item => <div className={strikeOut(item)}> {item.mo} </div> },
                    { header: context.word('ni'), row: item => <div className={strikeOut(item)}> {item.ni} </div> },
                    { header: context.word('sg'), row: item => <div className={strikeOut(item)}> {item.sg} </div> },
                    { header: context.word('packaging'), row: item => item.packaging },
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="underline cursor-pointer"
                                onClick={() => showPopup(toForm(item))}>{context.word('edit')}</span>
                            <span>&nbsp;|&nbsp;</span>
                            <span className="underline cursor-pointer"
                                onClick={() => setActive(item.id, !item.active)}> {item.active ? context.word('deactivate') : context.word('activate')} </span>
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}/>
        </>
    )
}

export default Products
