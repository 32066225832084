import React, {useContext, useRef, useState} from "react";
import GroundData from "../../controllers/GroundData";
import {ProgramLandSetupEx} from "./SetupLandHelpers";
import AppContext from "../../appContext";
import SoilSummary from "./SoilSummary";
import NameId from "./NameId";

const SoilSummaryDialog: React.FC<{
    data: GroundData[];
    assignedLandId?: number;
    lands?: NameId[]
    replaced?: boolean;

    onSave: (data: GroundData[]) => void;
    onDiscard: () => void;
}> = (props) => {

    const [data, setData] = useState<GroundData[]>([]);
    const changed = useRef<boolean>(false);
    const app = useContext(AppContext)

    return <>

        <SoilSummary
            assignedLandId={props.assignedLandId}
            data={props.data}
            setData={d => {
                setData(d)
                changed.current = true
            }}
            replaced={props.replaced}
            lands={props.lands}
        />

        <div className="btn bg-red-500 m-1" onClick={() => props.onDiscard()}>{app.word('discard')}</div>
        <div className="btn bg-primary m-1" onClick={() => {
            changed.current ? props.onSave(data) : props.onDiscard()
        }} >{app.word('save')}</div>
    </>
}

export default SoilSummaryDialog;