import React from 'react'

const TableColumns: React.FC<{
    columns: React.ReactNode[];
    countCount: number;

    cellClass?: (column: number, row: number) => string
    rowClass?: (row: number) => string
}> = (props) => {
    const rows = React.useMemo(() => props.columns.reduce<React.ReactNode[][]>((prev, cur) => {
        const last = prev[prev.length - 1]!
        if (last.length < props.countCount) {
            last.push(cur)
        } else {
            prev.push([cur])
        }

        return prev
    }, [[]]), [props.columns, props.countCount])

    return <table className="text-xs">
        <tbody>
            {rows.map((row, rowIndex) =>
                <tr key={rowIndex} className={props.rowClass?.(rowIndex)}>
                    {row.map((col, colIndex) =>
                        <td key={colIndex} className={props.cellClass?.(colIndex, rowIndex)}>{col}</td>
                    )}
                </tr>
            )}
        </tbody>
    </table>
}

export default TableColumns
