import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import AppContext from '../appContext'
import RegionController from '../controllers/RegionController'
import RegionResponse from '../controllers/RegionResponse'
import { AutoComplete } from '../components/AutoComplete'
import LanguageController from '../controllers/LanguageController'
import Region from '../controllers/Region'
import { useAjaxData } from '../wrapper'
import { useValidation } from '../validation'
import { buildSetter } from '../immutableState'
import KeysLookup from '../controllers/KeysLookup'
import { showSuccessOrFailed } from '../Snacks'

const emptyRegion: Region = {
    id: 0,
    languageKeyId: 0
}

const Regions: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<RegionResponse>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<Region>(emptyRegion)
    const setData = buildSetter(upsertData, setUpsertData)

    function showPopup (preset: Region) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertCallback (data: Region) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, RegionController.add(data)).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    const [langOptions, setLangOptions] = useState<KeysLookup[]>([])
    useAjaxData(() => LanguageController.keysLookup(), setLangOptions)

    const validation = useValidation({
        regionRequired: () => upsertData.languageKeyId > 0
    })

    return (
        <>
            <Dialog mounted={focusFirstInput} title={upsertData.id === 0 ? context.word('new_region') : context.word('edit_region')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('region'),
                            <AutoComplete options={langOptions} textFunc={t => t.text} valueFunc={t => t.keyId} value={upsertData.languageKeyId} onChange={v => setData({ languageKeyId: v })}/>,
                            validation.rules.regionRequired,
                            context.word('region_required')
                        )}
                    </EditTable>
                }/>

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyRegion)}>{context.word('new_region')}</div>
            <PagedSearchTable<RegionResponse>
                componentRef={pagedTableRef}
                call={RegionController.paged}
                columns={[
                    { header: context.word('id'), row: item => item.id },
                    { header: context.word('name'), row: item => item.name }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Regions
