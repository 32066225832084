/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import PaginationResponse from "./PaginationResponse";
import DistributorPagedResponse from "./DistributorPagedResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import DistributorUpsertRequest from "./DistributorUpsertRequest";

const urls = {
    all(): string {
        return "/distributor/all";
    }
}

export default {
    urls,
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<DistributorPagedResponse>> {
        return contentOnly(Axios.post("/distributor/paged", request, defaultConfig));
    },
    all(): Promise<DistributorPagedResponse[]> {
        return contentOnly(Axios.get("/distributor/all", defaultConfig));
    },
    upsert(request: DistributorUpsertRequest): Promise<boolean> {
        return contentOnly(Axios.post("/distributor/upsert", toFormData(request), defaultFormConfig));
    }
}
