
export type UnitCategory = ('weight' | 'length' | 'liquid' | 'area');

export enum Unit {
    ShortTon, // Metric ton = 2,240 lbs (1016 kg)
    LongTon, // Imperial ton = 2000 lbs (907.18474 kg)
    Tonne, // Megagram = 1000 kg
    Stone,
    Pounds,
    Ounce,
    Gram,
    ShortHundredWeight,
    LongHundredWeight,

    Kilometer,
    Meter,
    Centimeter,
    Millimeter,
    Mile,
    Yard,
    Foot,
    Inch,
    NauticalMile,

    Gallon,
    CubicInches,
    MilliLiter,
    Liter,

    Millimeter2,
    Meter2,
    Hectare,
    Acre

}

export interface UnitValue {
    type: Unit;
    value: number;
}

export interface UnitDef {
    display: string;
    type: UnitCategory;
    abbr: string;
    convert: UnitValue | null
}

export function makeValue (value: number, type: Unit): UnitValue {
    return {
        value,
        type
    }
}

function resolveToCommon (value: number, type: Unit): UnitValue | null {
    const visited: Unit[] = []

    while (true) {
        if (visited.includes(type)) { return null }
        visited.push(type)

        const def = definitions[type]
        if (def === null) { return null }

        const convert = def.convert
        if (convert === null) { return { type, value } }

        value = convert.value * value
        type = convert.type
    }
}

export default function convert (from: UnitValue, to: Unit): number | null {
    const source = resolveToCommon(from.value, from.type)
    const dest = resolveToCommon(1, to)
    if (source !== null && dest !== null && source.type === dest.type) { return source.value / dest.value }

    return null
}

const definitions: Record<Unit, UnitDef> = {
    // weight
    [Unit.ShortTon]: {
        display: 'US ton (short ton)',
        abbr: 'US ton',
        type: 'weight',
        convert: makeValue(20, Unit.ShortHundredWeight)
    },
    [Unit.LongTon]: {
        display: 'imperial ton (long ton)',
        abbr: 'imperial ton',
        type: 'weight',
        convert: makeValue(20, Unit.LongHundredWeight)
    },
    [Unit.Tonne]: {
        display: 'ton (metric)',
        abbr: 'tonne',
        type: 'weight',
        convert: makeValue(1000 * 1000, Unit.Gram)
    },
    [Unit.Ounce]: {
        display: 'ounce',
        abbr: 'oz',
        type: 'weight',
        convert: makeValue(1 / 16, Unit.Pounds)
    },
    [Unit.Stone]: {
        display: 'stone',
        abbr: 'st',
        type: 'weight',
        convert: makeValue(14, Unit.Pounds)
    },
    [Unit.Pounds]: {
        display: 'pounds',
        abbr: 'lb',
        type: 'weight',
        convert: makeValue(453.59237, Unit.Gram)
    },
    [Unit.Gram]: {
        display: 'gram',
        abbr: 'g',
        type: 'weight',
        convert: null
    },
    [Unit.ShortHundredWeight]: {
        display: 'short hundredweight',
        abbr: 'cwt(short)',
        type: 'weight',
        convert: makeValue(100, Unit.Pounds)
    },
    [Unit.LongHundredWeight]: {
        display: 'long hundredweight',
        abbr: 'cwt',
        type: 'weight',
        convert: makeValue(8, Unit.Stone)
    },

    // Length
    [Unit.Kilometer]: {
        display: 'Kilometer',
        abbr: 'km',
        type: 'length',
        convert: makeValue(1000, Unit.Meter)
    },
    [Unit.Meter]: {
        display: 'meter',
        abbr: 'm',
        type: 'length',
        convert: null
    },
    [Unit.Centimeter]: {
        display: 'Centimeter',
        abbr: 'cm',
        type: 'length',
        convert: makeValue(0.01, Unit.Meter)
    },
    [Unit.Millimeter]: {
        display: 'Millimeter',
        abbr: 'mm',
        type: 'length',
        convert: makeValue(0.1, Unit.Centimeter)
    },
    [Unit.Mile]: {
        display: 'Mile',
        abbr: 'mi',
        type: 'length',
        convert: makeValue(1760, Unit.Yard)
    },
    [Unit.Yard]: {
        display: 'Yard',
        abbr: 'yd',
        type: 'length',
        convert: makeValue(3, Unit.Foot)
    },
    [Unit.Foot]: {
        display: 'Foot',
        abbr: 'ft',
        type: 'length',
        convert: makeValue(12, Unit.Inch)
    },
    [Unit.Inch]: {
        display: 'Inch',
        abbr: 'in',
        type: 'length',
        convert: makeValue(2.54, Unit.Centimeter)
    },
    [Unit.NauticalMile]: {
        display: 'Nautical mile',
        abbr: 'M',
        type: 'length',
        convert: makeValue(1.852, Unit.Kilometer)
    },

    [Unit.MilliLiter]: {
        display: 'Milliliter',
        abbr: 'ml',
        type: 'liquid',
        convert: null
    },
    [Unit.Liter]: {
        display: 'Liter',
        abbr: 'l',
        type: 'liquid',
        convert: makeValue(1000, Unit.MilliLiter)
    },
    [Unit.CubicInches]: {
        display: 'Cubic inches',
        abbr: 'ci',
        type: 'liquid',
        convert: makeValue(16.3871, Unit.MilliLiter)
    },
    [Unit.Gallon]: {
        display: 'Gallon',
        abbr: 'gal',
        type: 'liquid',
        convert: makeValue(231, Unit.CubicInches)
    },

    [Unit.Millimeter2]: {
        display: 'Squared millimeter',
        abbr: 'mm^2',
        type: 'area',
        convert: makeValue(0.0000001, Unit.Meter2)
    },
    [Unit.Meter2]: {
        display: 'Squared meter',
        abbr: 'm^2',
        type: 'area',
        convert: null
    },
    [Unit.Hectare]: {
        display: 'Hectare',
        abbr: 'ha',
        type: 'area',
        convert: makeValue(10000, Unit.Meter2)
    },
    [Unit.Acre]: {
        display: 'Acre',
        abbr: 'ac',
        type: 'area',
        convert: makeValue(4046.86, Unit.Meter2)
    }
}
