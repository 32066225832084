import ProgramLandSetup from '../../controllers/ProgramLandSetup'
import MeasureValue from '../../controllers/MeasureValue'
import {measureValue} from './Helpers'
import {CSVPopulate} from './CSVParse'
import {customUnits, measureValueCustomUnit} from './CustomUnits'
import {roundTo} from './ViewBlock'
import Originate from "../../controllers/Originate";
import ProductUnit from '../../controllers/ProductUnit'

export interface ProgramLandSetupEx extends ProgramLandSetup {
    selected: boolean;

    // keep separate values for annual and perennial when in editing mode
    cropIdAnnual: number | null;
    cropIdPerennial: number | null;
    cultivarIdAnnual: number | null;
    cultivarIdPerennial: number | null;
}

export function emptyLand (id: number, name: string, size: MeasureValue): ProgramLandSetupEx {
    return {
        selected: false,
        id,
        ground: [],
        leaf: [],
        cropId: null,
        originate: Originate.Local,

        cropIdAnnual: null,
        cropIdPerennial: null,
        cultivarIdAnnual: null,
        cultivarIdPerennial: null,

        data: {
            name,
            cultivar: null,
            vigourOfOrchard: 0,
            waterPSize: measureValue(0, 0),
            cultivarId: null,
            estimateProduction: measureValue(0, 0),
            irrigation: '',
            plantsperSize: 0,
            rowSpacing: measureValue(0, 0),
            rowWidth: measureValue(0, 0),
            size,
            stickWidth: measureValue(0, 0),
            totalProduction: measureValue(0, 0),
            totalTrees: 0,
            treeAge: 0,
            totalProductionUnitMetric: ProductUnit.TonHa,
            totalProductionUnitImperial: ProductUnit.STonAc,
            estimateProductionUnitMetric: ProductUnit.TonHa,
            estimateProductionUnitImperial: ProductUnit.STonAc,
            
            treeSpacing: measureValue(0, 0),
            yearPlanted: 0,
            rootStock: 'true'
        }
    }
}

export const CSVPerennialData: Record<string, CSVPopulate<ProgramLandSetupEx>> = {
    'Land Name': (value, entry) => {
        entry.data.name = value
    },
    'Land Size': (value, entry, system) => {
        entry.data.size = measureValueCustomUnit(roundTo(parseFloat(value), 2), customUnits.ha_ac, system)
    },
    Irrigation: (value, entry) => {
        entry.data.irrigation = value
    },
    Rootstock: (value, entry) => {
        entry.data.rootStock = value
    },
    'Row Spacing': (value, entry, system) => {
        entry.data.rowSpacing = measureValueCustomUnit(parseInt(value), customUnits.mm_Feet, system)
    },
    'Tree Spacing': (value, entry, system) => {
        entry.data.treeSpacing = measureValueCustomUnit(parseInt(value), customUnits.mm_Feet, system)
    },
    'Year Planted': (value, entry) => {
        entry.data.yearPlanted = parseInt(value)
    },
    'Aim Production': (value, entry, system) => {
        entry.data.estimateProduction = measureValueCustomUnit(roundTo(parseFloat(value), 2), customUnits.tonHa_tonAc, system)
    },
    'Previous Production': (value, entry, system) => {
        entry.data.totalProduction = measureValueCustomUnit(roundTo(parseFloat(value), 2), customUnits.tonHa_tonAc, system)
    }
}

export const CSVAnnualData: Record<string, CSVPopulate<ProgramLandSetupEx>> = {
    'Land Name': (value, entry) => {
        entry.data.name = value
    },
    'Land Size': (value, entry, system) => {
        entry.data.size = measureValueCustomUnit(roundTo(parseFloat(value), 2), customUnits.ha_ac, system)
    },
    Irrigation: (value, entry) => {
        entry.data.irrigation = value
    },
    'Row Width': (value, entry, system) => {
        entry.data.rowWidth = measureValueCustomUnit(parseInt(value), customUnits.mm_Feet, system)
    },
    'Stick Width': (value, entry, system) => {
        entry.data.stickWidth = measureValueCustomUnit(parseInt(value), customUnits.mm_Feet, system)
    },
    'Aim Production': (value, entry, system) => {
        entry.data.estimateProduction = measureValueCustomUnit(roundTo(parseFloat(value), 2), customUnits.tonHa_tonAc, system)
    },
    'Previous Production': (value, entry, system) => {
        entry.data.totalProduction = measureValueCustomUnit(roundTo(parseFloat(value), 2), customUnits.tonHa_tonAc, system)
    }
}

export function filled (value: MeasureValue): boolean {
    return value.metric > 0 && value.imperial > 0
}
