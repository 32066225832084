import React, { useContext, useEffect, useState } from 'react'
import PlayFieldTemplateResponse from '../controllers/PlayFieldTemplateResponse'
import AppContext from '../appContext'
import TemplateNameId from '../controllers/TemplateNameId'
import ProgramController from '../controllers/ProgramController'
import { AutoCompleteNullable } from '../components/AutoComplete'

const ChooseTemplate: React.FC<{
    onChange: (value: number | null, data: PlayFieldTemplateResponse | null) => void;
    value: number | null;
    filterByRegions: boolean;
}> = (props) => {
    const context = useContext(AppContext)
    const [list, setList] = useState<TemplateNameId[]>([])

    useEffect(() => {
        let prom = props.filterByRegions ? ProgramController.templatesRegionSpecific() : ProgramController.templates(); 
        prom.then(resp => {
            setList(resp)
        })
    }, [props.filterByRegions])

    function templateChanged (item: number | null, fromButton: boolean) {
        if (item === null) {
            // only fire event when explicit clicked the clear button
            // prevent accidentally clear autocomplete
            if (fromButton) { props.onChange(item, null) }
            return
        }

        // get playfield data for the given template
        ProgramController.getTemplate(item).then(res => {
            props.onChange(item, res)
        })
    }

    return <div>
        <div className="inline-block">{context.word('select_template')}</div>
        <div className="inline-block align-middle">
            <AutoCompleteNullable options={list} textFunc={item => item.name} valueFunc={item => item.id}
                value={props.value} onChange={item => templateChanged(item, false)}/>

        </div>
        <div className="inline-block align-middle">
            <button className="m-3 btn btn-small bg-error-500" onClick={() => templateChanged(null, true)}>X</button>
        </div>
    </div>
}

export default ChooseTemplate
