import Client from '../controllers/Client'
import ClientController from '../controllers/ClientController'
import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import Originate from '../controllers/Originate'
import Dialog from '../components/Dialog'
import AppContext from '../appContext'
import Input from '../components/Input'
import { buildSetter } from '../immutableState'
import { useValidation } from '../validation'
import { showSuccessOrFailed } from '../Snacks'

const emptyClient: Client = {
    id: 0, local: false, mfwId: 0, name: '', originate: Originate.Local

}

const Clients: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<Client>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<Client>(emptyClient)
    const setData = buildSetter(upsertData, setUpsertData)
    const context = useContext(AppContext)

    function showUpsert (data: Client) {
        setUpsertData(data)
        setShow(true)
    }

    function upsert (data: Client) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, ClientController.upsert({
            name: data.name,
            id: data.id
        })).then(() => {
            setShow(false)
            pagedTableRef.current?.refresh()
        })
    }

    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0
    })

    return (<>
        <Dialog mounted={focusFirstInput} title={upsertData.id === 0 ? context.word('new_client') : context.word('edit_client')} show={show} setShow={setShow}
            body={
                <EditTable save={() => upsert(upsertData)} discard={() => setShow(false)}
                    saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                    {EditRow(
                        context.word('name'),
                        <Input value={upsertData.name} change={v => setData({ name: v })} />,
                        validation.rules.nameRequired,
                        context.word('name_required')
                    )}
                </EditTable>
            }
        />

        <div className="btn bg-primary m-2" onClick={() => showUpsert(emptyClient)}>{context.word('add_client')}</div>

        <PagedSearchTable<Client>
            componentRef={pagedTableRef}
            call={ClientController.paged}
            columns={[
                {
                    header: context.word('name'),
                    row: (item) => item.name
                },
                {
                    header: context.word('Originate'),
                    row: (item) => item.originate.toString()
                },
                {
                    header: context.word('Action'),
                    row: (item) => (<span onClick={() => showUpsert(item)} className="cursor-pointer underline font-medium">{context.word('edit')}</span>)
                }
            ]}
            keyExtractor={i => i.id}/>
    </>)
}

export default Clients
