import {useState} from "react";
export function useShowState<T>(empty: T) {

    const [show, setShow] = useState(false);
    const [data, setData] = useState<T>(empty);

    return {
        visible: show,
        data,
        show(data: T) {
            setData(data);
            setShow(true);
        },
        hide: () => setShow(false),
    }
}