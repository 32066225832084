import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Program from './program/Program'
import PlayFieldResponse from '../controllers/PlayFieldResponse'
import ProgramController from '../controllers/ProgramController'
import Dialog from '../components/Dialog'
import AppContext from '../appContext'
import { bind } from '../wrapper'
import Success from '../components/Success'

const Approval: React.FC = () => {
    const context = useContext(AppContext)
    const params = useParams()
    const [data, setData] = useState<PlayFieldResponse | null>(null)
    const [showDecline, setShowDecline] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)
    const navigate = useNavigate()
    const programId = parseInt(params.programId as string)

    useEffect(() => {
        ProgramController.get({ id: programId }).then(r => {
            setData(r)
        })
    }, [])

    if (data === null) {
        return <div>Loading...</div>
    }

    function approve () {
        setShowConfirm(false)
        ProgramController.approve({ id: programId }).then(() => {
            context.showSnack(<Success title={context.word('successfully_approved')} />)
            // navigate back to approvals
            navigate('/approvals')
        })
    }

    function decline (message: string) {
        setShowDecline(false)

        ProgramController.decline({ id: programId, message }).then(() => {
            context.showSnack(context.word('declined'))
            // navigate back to approvals
            navigate('/approvals')
        })
    }

    return <>
        <Program id={programId} data={data} footer={<>
            <div className="btn bg-red-500 m-2" onClick={() => setShowDecline(true)}>
                {context.word('decline')}
            </div>

            <div className="btn bg-primary m-2" onClick={() => setShowConfirm(true)}>
                {context.word('approve')}
            </div>
        </>}/>

        {/* <div className="w-full text-right pr-8"> */}
        {/*    */}
        {/* </div> */}

        <Dialog title="Warning" body={<div>
            <div className='p-4'>{context.word('approve_program')}</div>
            <div className="w-full text-center p-2">
                <div className="btn bg-primary" onClick={() => approve()}>Yes</div>
                <div className="btn bg-red-500" onClick={() => setShowConfirm(false)}>No</div>
            </div>
        </div>} show={showConfirm} setShow={setShowConfirm}/>

        <Dialog
            setShow={setShowDecline}
            body={<DeclineDialog decline={decline} discard={() => setShowDecline(false)}/>}
            show={showDecline}
            title={context.word('decline_message')}
        />

    </>
}

const DeclineDialog: React.FC<{
    discard: () => void
    decline: (message: string) => void
}> = (props) => {
    const context = useContext(AppContext)
    const [reason, setReason] = useState('')
    return <div className="m-2">
        <div>{context.word('message')}</div>
        <textarea className="input" {...bind(reason, setReason)}></textarea>
        <div>

            <div className="btn bg-red-500 m-2" onClick={() => props.discard()}>
                {context.word('discard')}
            </div>

            <div className="btn bg-primary m-2" onClick={() => props.decline(reason)}>
                {context.word('send')}
            </div>
        </div>
    </div>
}

export default Approval
