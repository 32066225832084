import AppContext from './appContext'
import React, {PropsWithChildren, ReactNode, useRef, useState} from 'react'
import AppState from './controllers/AppState'
import DialogPortal from './components/DialogPortal'
import PermissionEnum from './controllers/PermissionEnum'
import Snacks, { SnackOperations } from './Snacks'
import { isLocalhost } from './controllers/helper'

const Loader: React.FC = () => {
    return (
        <DialogPortal>
            <div className="overlay z-20">
                <div className="loader-box">
                    <div className="self-building-square-spinner">
                        <div className="square bg-primary-800"/>
                        <div className="square bg-primary-800"/>
                        <div className="square bg-primary-800"/>
                        <div className="square clear bg-primary-800"/>
                        <div className="square bg-primary-800"/>
                        <div className="square bg-primary-800"/>
                        <div className="square clear bg-primary-800"/>
                        <div className="square bg-primary-800"/>
                        <div className="square bg-primary-800"/>
                    </div>
                </div>
            </div>
        </DialogPortal>
    )
}

// keep count of how many loaders there are
let count = 0

const AppContextProvider: React.FC<PropsWithChildren<{ initial: AppState }>> = ({
    children,
    initial
}) => {
    const [loading, setLoading] = useState(false)
    const snacksRef = useRef<SnackOperations>(null)

    function showLoader (): () => void {
        setLoading(true)
        count++
        return () => {
            if (--count === 0) {
                setLoading(false)
            }
        }
    }

    function word (key: string): string {
        if (isLocalhost()) {
            return '*' + key
        }
        return initial.language[key] || '*' + key
    }

    function wordUnit (metricKey: string, imperialKey: string): string {
        if (isLocalhost()) {
            return initial.system === 'METRIC' ? ('*' + metricKey) : ('*' + imperialKey)
        }
        return initial.system === 'METRIC' ? (initial.language[metricKey] || '*' + metricKey) : (initial.language[imperialKey] || '*' + imperialKey)
    }

    function showSnack (node: ReactNode): () => void {
        if (snacksRef.current) {
            return snacksRef.current.showSnack(node)
        }

        return () => {
        }
    }

    function hasPermission (permission: PermissionEnum): boolean {
        const foundPermission = !!initial.permissions.find(s => s === permission)
        return initial.superUser || foundPermission
    }

    return (
        <AppContext.Provider value={{
            word,
            wordUnit,
            hasPermission,
            isLoading: loading,
            showLoader,
            initial,
            showSnack,
        }}>
            {loading ? <Loader/> : null}

            <DialogPortal>
                <Snacks ref={snacksRef}/>
            </DialogPortal>

            {children}
        </AppContext.Provider>
    )
}

export default AppContextProvider
