import React, {useContext, useState} from "react";
import AppContext from "../../appContext";
import Switch from "../../components/Switch";
import {useStateAjax} from "../../wrapper";
import CurrencyController from "../../controllers/CurrencyController";
import SelectNumberNullable from "../../components/SelectNumberNullable";

const SetupPricing: React.FC<{
    next: (includePricingModule: boolean, selectedCurrency: number | null) => void
}> = (props) => {
    const context = useContext(AppContext)
    const [includePricingModule, setIncludePricingModule] = useState(false)
    const [currencies, setCurrencies] = useStateAjax(() => CurrencyController.index())
    const [selectedCurrency, setSelectedCurrency] = useState<number | null>(null)
    function next() {
        props.next(includePricingModule, selectedCurrency)
    }
    
    return <div className="p-2">
        <div className="text-xl underline tracking-wider">4. {context.word('setup_pricing')}</div>
        <div className="flex my-1 items-center">
            <label className='flex items-center'>
                <span className='text-xl'>{context.word('pricing_module')}</span>
                <div className='mx-3 my-2'>
                    <Switch checked={includePricingModule}
                            setChecked={v => setIncludePricingModule(v)} />
                </div>
            </label>
        </div>
        <div className={includePricingModule ? "" : "invisible"}>
            <div className="inline-block pr-3">{context.word('select_currency')}</div>
            <div className="inline-block align-middle">
                <SelectNumberNullable options={currencies} 
                                      textFunc={t => t.name} 
                                      valueFunc={t => t.id} 
                                      value={selectedCurrency} 
                                      onChange={v => setSelectedCurrency(v)}/>

            </div>
        </div>
        <div className="text-right my-2">
            <div className="btn bg-primary" onClick={() => next()}>{context.word('next')}</div>
        </div>
    </div>
}

export default SetupPricing;