import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import CropController from '../controllers/CropController'
import CropType from '../controllers/CropType'
import AppContext from '../appContext'
import { useAjaxData, useStateAjax } from '../wrapper'
import LanguageController from '../controllers/LanguageController'
import { AutoComplete, AutoCompleteNullable } from '../components/AutoComplete'
import { buildSetter } from '../immutableState'
import KeysLookup from '../controllers/KeysLookup'
import ImageUploader from '../components/ImageUploader'
import { useValidation } from '../validation'
import { showSuccessOrFailed } from '../Snacks'
import { customUnits, unitCustom } from './program/CustomUnits'
import CropPageResponse from '../controllers/CropPageResponse'
import { MeasureValueField } from './program/MeasureValueField'
import { getValue, measureValue } from './program/Helpers'
import MeasurementUnit from '../controllers/MeasurementUnit'
import { roundTo } from './program/ViewBlock'
import MeasureValue from '../controllers/MeasureValue'
import { SelectEnum } from '../components/SelectEnum'

interface CropForm {
    id: number,
    label: File | null,
    type: CropType,
    languageKeyId: number,
    imagePath: string;

    withdrawal_N: MeasureValue,
    withdrawal_P: MeasureValue,
    withdrawal_P205: MeasureValue,
    withdrawal_K: MeasureValue,
    withdrawal_K20: MeasureValue,
    withdrawal_Ca: MeasureValue,
    withdrawal_Mg: MeasureValue,
}

const emptyCrop: CropForm = {
    imagePath: '',

    id: 0,
    label: null,
    languageKeyId: 0,
    type: CropType.Annual,
    withdrawal_Ca: { metric: 0, imperial: 0 },
    withdrawal_K: { metric: 0, imperial: 0 },
    withdrawal_K20: { metric: 0, imperial: 0 },
    withdrawal_Mg: { metric: 0, imperial: 0 },
    withdrawal_N: { metric: 0, imperial: 0 },
    withdrawal_P: { metric: 0, imperial: 0 },
    withdrawal_P205: { metric: 0, imperial: 0 }
}

function toFrom (item: CropPageResponse): CropForm {
    return {
        ...item,
        label: null
    }
}

const CropUpsert: React.FC<{
    data: CropForm;
    discard: () => void;
    save: (data: CropForm) => void;
}> = (props) => {
    const [data, _setData] = useState<CropForm>(props.data)
    const setData = buildSetter(data, _setData)
    const context = useContext(AppContext)

    const [langOptions, setLangOptions] = useState<KeysLookup[]>([])
    useAjaxData(() => LanguageController.keysLookup(), setLangOptions)

    const validation = useValidation({
        image: data.label !== null || data.imagePath !== '',
        language: data.languageKeyId > 0
    })

    function submit () {
        if (validation.validate()) {
            props.save(data)
        }
    }

    return <EditTable discard={props.discard} save={() => submit()} saveWord={data.id === 0 ? 'insert' : 'update'}>

        {EditRow(context.word('crop_photo'), <ImageUploader imageUrl={data.imagePath} setImage={v => setData({ label: v })}/>, validation.rules.image, context.word('image_required'))}
        {EditRow(context.word('name'), <AutoComplete options={langOptions} textFunc={t => t.text} valueFunc={t => t.keyId} value={data.languageKeyId}
            onChange={v => setData({ languageKeyId: v })}/>, validation.rules.language, context.word('language_required'))}
        {EditRow(context.word('type'), <SelectEnum value={data.type} onChange={val => setData({ type: val as CropType })} options={CropType}/>)}

        <tr>
            <td colSpan={2}>
                {context.word('withdrawals') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc))}):
                <table>
                    <tbody>

                        <tr>
                            <td className="text-gray-600 text-sm">{context.word('n')}</td>
                            <td className="text-gray-600 text-sm">{context.initial.system === MeasurementUnit.METRIC ? context.word('p') : context.word('p205')}</td>
                            <td className="text-gray-600 text-sm">{context.initial.system === MeasurementUnit.METRIC ? context.word('k') : context.word('k20')}</td>
                            <td className="text-gray-600 text-sm">{context.word('ca')}</td>
                            <td className="text-gray-600 text-sm">{context.word('mg')}</td>
                        </tr>
                        <tr>
                            <td className="w-20 pr-2">
                                <MeasureValueField unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} value={data.withdrawal_N} update={v => setData({ withdrawal_N: v })}/>
                            </td>
                            <td className="w-20 pr-2">
                                {context.initial.system === MeasurementUnit.METRIC
                                    ? <MeasureValueField value={data.withdrawal_P} unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} update={v => setData({
                                        withdrawal_P: measureValue(v.metric, 0),
                                        withdrawal_P205: measureValue(0, v.metric * 2.2915)
                                    })}/>
                                    : <MeasureValueField value={data.withdrawal_P205} unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} update={v => setData({
                                        withdrawal_P205: measureValue(0, v.imperial),
                                        withdrawal_P: measureValue(v.imperial / 2.2915, 0)
                                    })}/>
                                }
                            </td>
                            <td className="w-20 pr-2">
                                {context.initial.system === MeasurementUnit.METRIC
                                    ? <MeasureValueField value={data.withdrawal_K} unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} update={v => setData({
                                        withdrawal_K: measureValue(v.metric, 0),
                                        withdrawal_K20: measureValue(0, v.metric * 1.2047)
                                    })}/>
                                    : <MeasureValueField value={data.withdrawal_K20} unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} update={v => setData({
                                        withdrawal_K20: measureValue(0, v.imperial),
                                        withdrawal_K: measureValue(v.imperial / 1.2047, 0)
                                    })}/>
                                }
                            </td>
                            <td className="w-20 pr-2">
                                <MeasureValueField unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} value={data.withdrawal_Ca} update={v => setData({ withdrawal_Ca: v })}/>
                            </td>
                            <td className="w-20 pr-2">
                                <MeasureValueField unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} value={data.withdrawal_Mg} update={v => setData({ withdrawal_Mg: v })}/>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </td>
        </tr>
    </EditTable>
}

const DeleteCrop: React.FC<{
    onDiscard: () => void;
    onDelete: (crop: number | null) => void;
}> = (props) => {
    const context = useContext(AppContext)
    const [crops] = useStateAjax(() => CropController.index())
    const [crop, setCrop] = useState<number | null>(null)

    return <div className="p-4">
        <div>Move linked cultivars to:</div>
        <AutoCompleteNullable options={crops} textFunc={c => c.name} valueFunc={c => c.id} value={crop} onChange={v => setCrop(v)}/>

        <div className="mt-2">
            <div className="bg-error-600 btn" onClick={() => props.onDelete(crop)}>{context.word('delete')}</div>
            <div className="bg-primary btn" onClick={() => props.onDiscard()}>{context.word('discard')}</div>
        </div>
    </div>
}

const Crops: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<CropPageResponse>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<CropForm>(emptyCrop)

    const [showDelete, setShowDelete] = useState(false)
    const cropToDelete = useRef<number | null>(null)

    const newCategory = upsertData.id === 0 ? context.word('new_crop') : context.word('update_crop')

    function showPopup (preset: CropForm) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertCallback (data: CropForm) {
        showSuccessOrFailed(context, CropController.upsert({
            id: data.id,
            label: data.label,
            languageKeyId: data.languageKeyId,
            type: data.type,
            withdrawal_Ca_Imperial: data.withdrawal_Ca.imperial,
            withdrawal_Ca_Metric: data.withdrawal_Ca.metric,
            withdrawal_K20_Imperial: data.withdrawal_K20.imperial,
            withdrawal_K20_Metric: data.withdrawal_K20.metric,
            withdrawal_K_Imperial: data.withdrawal_K.imperial,
            withdrawal_K_Metric: data.withdrawal_K.metric,
            withdrawal_Mg_Imperial: data.withdrawal_Mg.imperial,
            withdrawal_Mg_Metric: data.withdrawal_Mg.metric,
            withdrawal_N_Imperial: data.withdrawal_N.imperial,
            withdrawal_N_Metric: data.withdrawal_N.metric,
            withdrawal_P205_Imperial: data.withdrawal_P205.imperial,
            withdrawal_P205_Metric: data.withdrawal_P205.metric,
            withdrawal_P_Imperial: data.withdrawal_P.imperial,
            withdrawal_P_Metric: data.withdrawal_P.metric

        })).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    function deleteCrop (crop: number | null) {
        if (cropToDelete.current === null) { return }

        const call = CropController.delete({
            cropId: cropToDelete.current,
            moveCultivarToId: crop
        })

        showSuccessOrFailed(context, call).then(() => {
            setShowDelete(false)
        })
    }

    function showDeleteCrop (id: number) {
        cropToDelete.current = id
        setShowDelete(true)
    }

    return (
        <>
            <Dialog mounted={focusFirstInput} title={newCategory} show={show} setShow={setShow}
                body={<CropUpsert data={upsertData} discard={() => setShow(false)} save={upsertCallback}/>}/>

            <Dialog title={context.word('delete_crop')} setShow={setShowDelete} body={<DeleteCrop onDelete={crop => deleteCrop(crop)} onDiscard={() => setShowDelete(false)}/>} show={showDelete}/>

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyCrop)}>{context.word('add_crop')}</div>

            <PagedSearchTable<CropPageResponse>
                componentRef={pagedTableRef}
                call={CropController.paged}
                columns={[
                    {
                        header: context.word('name'),
                        row: item => item.name
                    },
                    {
                        header: context.word('type'),
                        row: item => item.type
                    },
                    {
                        header: context.word('withdrawal_n') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                        row: item => roundTo(getValue(context.initial.system, item.withdrawal_N), 2)
                    },
                    context.initial.system === MeasurementUnit.METRIC
                        ? {
                            header: context.word('withdrawal_p') + ' (' + context.word(unitCustom(MeasurementUnit.METRIC, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                            row: item => roundTo(getValue(MeasurementUnit.METRIC, item.withdrawal_P), 2)
                        }
                        : {
                            header: context.word('withdrawal_p205') + ' (' + context.word(unitCustom(MeasurementUnit.IMPERIAL, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                            row: item => roundTo(getValue(MeasurementUnit.IMPERIAL, item.withdrawal_P205), 2)
                        },
                    context.initial.system === MeasurementUnit.METRIC
                        ? {
                            header: context.word('withdrawal_k') + ' (' + context.word(unitCustom(MeasurementUnit.METRIC, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                            row: item => roundTo(getValue(MeasurementUnit.METRIC, item.withdrawal_K), 2)
                        }
                        : {
                            header: context.word('withdrawal_k20') + ' (' + context.word(unitCustom(MeasurementUnit.IMPERIAL, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                            row: item => roundTo(getValue(MeasurementUnit.IMPERIAL, item.withdrawal_K20), 2)
                        },
                    {
                        header: context.word('withdrawal_ca') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                        row: item => roundTo(getValue(context.initial.system, item.withdrawal_Ca), 2)
                    },
                    {
                        header: context.word('withdrawal_mg') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                        row: item => roundTo(getValue(context.initial.system, item.withdrawal_Mg), 2)
                    },
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="underline cursor-pointer" onClick={() => showPopup(toFrom(item))}>{context.word('edit')}</span>
                            <span>&nbsp;|&nbsp;</span>
                            <span className="underline cursor-pointer" onClick={() => showDeleteCrop(item.id)}>{context.word('delete')}</span>
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Crops
