import React, { useContext, useEffect, useState } from 'react'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import { customUnits, unitCustom } from './CustomUnits'
import CheckBox from '../../components/CheckBox'
import { FormatValue, getValueNullable, parseNullableNumber } from './Helpers'
import AppContext from '../../appContext'
import GroundData from '../../controllers/GroundData'
import { DataTable } from '../../components/DataTable'
import { PlusCircleIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/solid'
import { arrayPush, arrayRemoveIndex, arrayUpdate, arrayUpdatePartial } from '../../immutableState'
import PBrayType from '../../controllers/PBrayType'
import NullableMeasureValue from '../../controllers/NullableMeasureValue'
import { min } from '../../wrapper'
import Dialog from '../../components/Dialog'
import EditSoil from './EditSoil'
import { CSVPopulate, parseCSVFile } from './CSVParse'
import { parseMeasureValueNullable } from './MeasureValueField'
import FileUploader from '../../components/FileUploader'
import SelectNumber from '../../components/SelectNumber'
import { TableColumn } from '../../components/PagedSearchTable'
import { parseIntSafe } from '../../controllers/helper'
import SelectNumberNullable from '../../components/SelectNumberNullable'
import NameId from "./NameId";

function nullableValue (): NullableMeasureValue {
    return {
        metric: null,
        imperial: null
    }
}

interface GroundDataCsv extends GroundData {
    _block: string;
}

function emptySoil (id: number, assignedLandId: number): GroundDataCsv {
    return {
        _block: '',
        id,
        sample: null,
        ph: nullableValue(),
        pbrayType: PBrayType.Bray1,
        pbray: null,
        k: nullableValue(),
        na: nullableValue(),
        ca: nullableValue(),
        mg: nullableValue(),
        uit: null,
        caPercent: null,
        mgPercent: null,
        kPercent: null,
        ma: null,
        suur: null,
        caToMg: null,
        caPlusMg: null,
        mgToK: null,
        sWaarde: null,
        naToK: null,
        t: null,
        digtheid: null,
        sAmAc: nullableValue(),
        fe: nullableValue(),
        mn: nullableValue(),
        cu: nullableValue(),
        zn: nullableValue(),
        c: nullableValue(),
        nh4N: nullableValue(),
        no3: nullableValue(),
        cl: nullableValue(),
        year: new Date().getFullYear(),
        landId: assignedLandId,
        measurementUnit: MeasurementUnit.METRIC,
        showOnPdf: false
    }
}

function filterTableColumns<T>(columns: (TableColumn<T> | null)[]): TableColumn<T>[] {
    return columns.filter(c => c != null) as TableColumn<T>[];
}

function parsePBrayType (value: string): PBrayType {
    switch (value.toLowerCase()) {
    case 'bray2':
        return PBrayType.Bray2
    case 'olsen':
        return PBrayType.Olsen
    case 'colwell':
        return PBrayType.Colwell
    case 'ambic1':
        return PBrayType.Ambic1
    default:
        return PBrayType.Bray1
    }
}


const yearPopulate: CSVPopulate<GroundData> = (value, entry) => {
    entry.year = parseIntSafe(value);
};


const CSVGroundData: Record<string, CSVPopulate<GroundDataCsv>> = {
    Block: (value, entry) => {
        entry._block = value;
    },
    Year:yearPopulate,
    Jaar: yearPopulate,
    'Sample#': (value, entry) => {
        entry.sample = value
    },
    'pH (Kcl)': (value, entry, system) => {
        entry.ph = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    PBray: (value, entry) => {
        entry.pbray = parseFloat(value)
    },
    'PbrayType (Bray1, Bray2, Olsen, Colwell, Ambic1)': (value, entry) => {
        entry.pbrayType = parsePBrayType(value)
    },
    'K (mg/kg)': (value, entry, system) => {
        entry.k = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Na (mg/kg)': (value, entry, system) => {
        entry.na = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Ca (mg/kg)': (value, entry, system) => {
        entry.ca = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Mg (mg/kg)': (value, entry, system) => {
        entry.mg = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'OUT H+ (cmol(+)/kg)': (value, entry) => {
        entry.uit = parseFloat(value)
    },
    'UIT H+ (cmol(+)/kg)': (value, entry) => {
        entry.uit = parseFloat(value)
    },
    'Ca (%)': (value, entry) => {
        entry.caPercent = parseFloat(value)
    },
    'Mg (%)': (value, entry) => {
        entry.mgPercent = parseFloat(value)
    },
    'K (%)': (value, entry) => {
        entry.kPercent = parseFloat(value)
    },
    'ma (%)': (value, entry) => {
        entry.ma = parseNullableNumber(value)
    },
    'Na (%)': (value, entry) => {
        entry.ma = parseNullableNumber(value)
    },
    'SOUR.V (%)': (value, entry) => {
        entry.suur = parseNullableNumber(value)
    },
    'SUUR.V (%)': (value, entry) => {
        entry.suur = parseNullableNumber(value)
    },
    'Ca:Mg (1.5-4.5)': (value, entry) => {
        entry.caToMg = parseNullableNumber(value)
    },
    '(Ca+Mg)/K (10.0-20.0)': (value, entry) => {
        entry.caPlusMg = parseNullableNumber(value)
    },
    'Mg:K (3.0-4.0)': (value, entry) => {
        entry.mgToK = parseNullableNumber(value)
    },
    'S-Value (cmol(+)/kg)': (value, entry) => {
        entry.sWaarde = parseNullableNumber(value)
    },
    'S-Waarde (cmol(+)/kg)': (value, entry) => {
        entry.sWaarde = parseNullableNumber(value)
    },
    'Na:K': (value, entry) => {
        entry.naToK = parseNullableNumber(value)
    },
    'T (cmol(+)/kg)': (value, entry) => {
        entry.t = parseNullableNumber(value)
    },
    'Density (g/cm3)': (value, entry) => {
        entry.digtheid = parseNullableNumber(value)
    },
    'Digtheid (g/cm3)': (value, entry) => {
        entry.digtheid = parseNullableNumber(value)
    },
    'S AmAc (mg/kg)': (value, entry, system) => {
        entry.sAmAc = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Fe (mg/kg)': (value, entry, system) => {
        entry.fe = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Mn (mg/kg)': (value, entry, system) => {
        entry.mn = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Cu (mg/kg)': (value, entry, system) => {
        entry.cu = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Zn (mg/kg)': (value, entry, system) => {
        entry.zn = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'C (mg/kg)': (value, entry, system) => {
        entry.c = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Nh4N (mg/kg)': (value, entry, system) => {
        entry.nh4N = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'No3 (mg/kg)': (value, entry, system) => {
        entry.no3 = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Cl (mg/kg)': (value, entry, system) => {
        entry.cl = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    }
}



const SoilSummary: React.FC<{
    data: GroundData[];
    setData: (data: GroundData[]) => void

    assignedLandId?: number
    lands?: NameId[]
    replaced?: boolean
}> = (props) => {
    const context = useContext(AppContext)
    const system = context.initial.system

    function convertToGroundData(data: GroundData[]): GroundData[] {
        return data.map(l => ({...l, landId: props.assignedLandId ?? 0}))
    }

    function convertFromCsv(data: GroundDataCsv[]): GroundData[] {
        function getLandId(block: string) {
            let hasMatch = props.lands?.find(l => l.name === block);
            return hasMatch?.id ?? 0
        }

        return data.map(l => ({...l, landId: getLandId(l._block)}))
    }

    const [data, _setData] = useState<GroundData[]>([]);
    
    useEffect(() => {
        _setData(convertToGroundData(props.data))
    }, [props.data]);
    

    function setData (g: GroundData[]) {
        _setData(g)
        props.setData(g)
    }

    const [show, setShow] = useState(false)
    const [editData, setEditData] = useState<GroundData>(emptySoil(-1, props.assignedLandId ?? 0))
    const [showUpload, setShowUpload] = useState(false)

    function toggleShowOnPdf (state: boolean, index: number): void {
        setData(arrayUpdatePartial(data, index, { showOnPdf: state }))
    }

    function onRemove (i: number): void {
        setData(arrayRemoveIndex(data, i))
    }

    function onEdit (row: GroundData) {
        setEditData(row)
        setShow(true)
    }

    function addRow () {
        setShow(true)
        setEditData(emptySoil(min(data, d => d.id, 0) - 1, props.assignedLandId ?? 0))
    }

    const unit = unitCustom(system, customUnits.mgKg_OzLb)

    function saveChanges (edit: GroundData) {
        setShow(false)

        const index = data.findIndex(d => d.id === edit.id)

        if (index === -1) {
            // we need to insert.
            setData(arrayPush(data, edit))
        } else {
            // need to update
            const update = arrayUpdate(data, index, edit)
            setData(update)
        }
    }

    function csvUpload (file: File) {
        const reader = new FileReader()
        reader.onload = e => {
            const text: string = e.target?.result as string
            const minId = min(data, d => d.id, 0) - 1
            const entries = parseCSVFile<GroundDataCsv>(text, index => emptySoil(minId - index, props.assignedLandId ?? 0), context.initial.system, CSVGroundData)
            setData(data.concat(convertFromCsv(entries)))
            setShowUpload(false)
        }
        reader.readAsText(file)
    }

    return <div className="my-2">

        <Dialog
            show={show}
            setShow={setShow}
            title={editData.id < 0 ? context.word('add_groundData') : context.word('edit_groundData')}
            body={<EditSoil data={editData} onDiscard={() => setShow(false)} onSave={d => saveChanges(d)}/>}
        />

        <Dialog title={context.word('upload_csv')} body={
            <div className="p-4">
                <div>{context.word('download_csv_sample')}, {context.word('add_data_and_upload_again')}.</div>
                <a className="underline" href="/GroundSample.csv" download="GroundSample.csv">GroundSample.csv</a>
                <div className="my-2">
                    <FileUploader onChange={file => csvUpload(file)} fileTypes = ".csv"/>
                </div>
            </div>} show={showUpload} setShow={setShowUpload}
        />

        {data.length > 0
            ? <>
                <div className="text-xl text-primary">{context.word('soil_summary')}</div>
                <DataTable
                    headerClass="px-2 py-2 text-left text-xs font-semibold bg-gray-200"
                    dataClass="whitespace-nowrap px-1 py-2 text-xs text-gray-600"
                    keyExtractor={r => r.id}
                    // rowClick={(row, index) => showPopup(row)}

                    definitions={filterTableColumns<GroundData>([
                        {
                            header: <div className="flex items-center">
                                <CheckBox value={data.every(r => r.showOnPdf)} onChange={s => {
                                    setData(data.map(d => ({
                                        ...d,
                                        showOnPdf: s
                                    })))
                                }} />
                                <div className="pr-2">{context.word('show_on_pdf')}</div>
                            </div>,
                            row: (r, index) => <CheckBox value={r.showOnPdf} onChange={state => toggleShowOnPdf(state, index)}/>
                        },
                        !props.replaced && props.lands ?
                            {
                                header: <div className="flex items-center">
                                    <div className="pr-2">{context.word('land')}</div>
                                    <SelectNumberNullable options={props.lands!} textFunc={l => l.name ?? ""} valueFunc={l=> l.id} value={null} onChange={l => {
                                        if (l) {
                                            setData(data.map(d => ({
                                                ...d,
                                                landId: l
                                            })))
                                        }
                                    }} />
                                </div>,
                                row: (r, index) => <SelectNumber options={props.lands!} textFunc={l => l.name ?? ""} valueFunc={l=> l.id} value={r.landId} onChange={l => setData(arrayUpdatePartial(data, index, {landId: l}))}/>
                            } : null,
                        {
                            header: context.word('year'),
                            row: (r) => r.year
                        }, // editIndex === index ? <input className='input' defaultValue={r.year}/> :
                        {
                            header: context.word('sample'),
                            row: r => r.sample
                        },
                        {
                            header: 'pH',
                            row: r => <FormatValue value={getValueNullable(system, r.ph)}/>
                        },
                        {
                            header: context.word('pbray_type'),
                            row: r => context.word(r.pbrayType.toString().toLowerCase())
                        },
                        {
                            header: context.word('pbray'),
                            row: r => <FormatValue value={r.pbray}/>
                        },
                        {
                            header: `K ${unit}`,
                            row: r => <FormatValue value={getValueNullable(system, r.k)}/>
                        },
                        {
                            header: `Na ${unit}`,
                            row: r => <FormatValue value={getValueNullable(system, r.na)}/>
                        },
                        {
                            header: `Ca ${unit}`,
                            row: r => <FormatValue value={getValueNullable(system, r.ca)}/>
                        },
                        {
                            header: `Mg ${unit}`,
                            row: r => <FormatValue value={getValueNullable(system, r.mg)}/>
                        },

                        {
                            header: `${context.word('out_h+')} (cmol(+)/kg)`,
                            row: r => <FormatValue value={r.uit}/>
                        },
                        {
                            header: 'Ca (%)',
                            row: r => <FormatValue value={r.caPercent}/>
                        },
                        {
                            header: 'Mg (%)',
                            row: r => <FormatValue value={r.mgPercent}/>
                        },
                        {
                            header: 'K (%)',
                            row: r => <FormatValue value={r.kPercent}/>
                        },
                        {
                            header: 'Na (%)',
                            row: r => <FormatValue value={r.ma}/>
                        },

                        {
                            header: `${context.word('acid_saturated')} (%)`,
                            row: r => <FormatValue value={r.suur}/>
                        },
                        {
                            header: 'Ca:Mg (1.5-4.5)',
                            row: r => <FormatValue value={r.caToMg}/>
                        },
                        {
                            header: '(Ca+Mg)/K (10.0-20.0)',
                            row: r => <FormatValue value={r.caPlusMg}/>
                        },
                        {
                            header: 'Mg:K (3.0-4.0)',
                            row: r => <FormatValue value={r.mgToK}/>
                        },
                        {
                            header: `${context.word('s-value')} (cmol(+)/kg)`,
                            row: r => <FormatValue value={r.sWaarde}/>
                        },
                        {
                            header: 'Na:K',
                            row: r => <FormatValue value={r.naToK}/>
                        },
                        {
                            header: 'T (cmol(+)/kg)',
                            row: r => <FormatValue value={r.t}/>
                        },
                        {
                            header: <>{context.word('density')} (g/cm3)</>,
                            row: r => <FormatValue value={r.digtheid}/>
                        },

                        {
                            header: <>S AmAc ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.sAmAc)}/>
                        },
                        {
                            header: <>Fe (HCl {unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.fe)}/>
                        },
                        {
                            header: <>Mn ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.mn)}/>
                        },
                        {
                            header: <>Cu ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.cu)}/>
                        },
                        {
                            header: <>Zn ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.zn)}/>
                        },
                        {
                            header: <>C ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.c)}/>
                        },
                        {
                            header: <>Nh4-N ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.nh4N)}/>
                        },
                        {
                            header: <>No3 ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.no3)}/>
                        },
                        {
                            header: <>Cl ({unit})</>,
                            row: r => <FormatValue value={getValueNullable(system, r.cl)}/>
                        },
                        {
                            header: <>{context.word('actions')}</>,
                            row: (r, index) => <div>
                                <span className="underline cursor-pointer text-primary" onClick={() => onRemove(index)}>{context.word('remove')}</span>
                                <span className="px-2">|</span>
                                <span className="underline cursor-pointer text-primary" onClick={() => onEdit(r)}>{context.word('edit')}</span>
                            </div>
                        }
                    ])}
                    data={data}
                />
            </>
            : <div>{context.word('no_ground_data')}</div>
        }

        <span title={context.word('add_ground_data')} className="ml-1">
            <PlusCircleIcon className="w-6 h-6 inline-block cursor-pointer" onClick={() => addRow()}/>
        </span>
        <span title={context.word('upload_ground_data')}>
            <ArrowUpOnSquareIcon className="w-6 h-6 inline-block cursor-pointer " onClick={() => setShowUpload(true)}/>
        </span>
    </div>
}

export default React.memo(SoilSummary)
