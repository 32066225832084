import React, { useContext, useState } from 'react'
import appContext from '../../appContext'
import CheckBox from '../../components/CheckBox'
import Number from '../../components/Number'
import GroundData from '../../controllers/GroundData'
import { onInputHandler } from '../../wrapper'
import { CustomUnit, customUnits } from './CustomUnits'
import { NullableMeasureValueField } from './MeasureValueField'
import PBrayType from '../../controllers/PBrayType'
import NumberNullable from '../../components/NumberNullable'
import { KeyOfType } from '../../components/Fields'
import NullableMeasureValue from '../../controllers/NullableMeasureValue'
import { useValidation } from '../../validation'

const EditSoil: React.FC<{
    data: GroundData;
    onSave: (data: GroundData) => void;
    onDiscard: () => void;
}> = (props) => {
    const context = useContext(appContext)
    const [data, setData] = useState<GroundData>(props.data)

    function nullableMeasure (label: string, key: KeyOfType<GroundData, NullableMeasureValue>, unit: CustomUnit = customUnits.mgKg_OzLb) {
        return <td>
            <div>{label}</div>
            <NullableMeasureValueField unit={unit} value={data[key]} update={v => setData({ ...data, [key]: v })} />
        </td>
    }

    function nullableNumber (label: string, key: KeyOfType<GroundData, number | null>) {
        return <td>
            <div>{label}</div>
            <NumberNullable value={data[key]} change={v => setData({ ...data, [key]: v })} />
        </td>
    }

    const validation = useValidation({
        sampleRequired: () => data.sample !== null && data.sample.length > 0
    })

    function safeSoilData (data: GroundData) {
        if (!validation.validate()) {
            return
        }
        return props.onSave(data)
    }

    return <div>

        <table className="w-full">
            <tbody>
                <tr>
                    <td className="text-center">
                        <div className='mb-1 whitespace-nowrap'>{context.word('show_on_pdf')} </div>
                        <CheckBox value={data.showOnPdf ?? false} onChange={state => setData({ ...data, showOnPdf: state })} />
                    </td>
                    <td>
                        <div>{context.word('sample')}</div>
                        <div className="text-red-400">{!validation.rules.sampleRequired && context.word('sample_required')}</div>
                        <input className='input' type="text" value={data.sample ?? '' } onInput={onInputHandler(v => setData({ ...data, sample: v }))} />
                    </td>

                    <td>
                        <div>{context.word('jaar')}</div>
                        <Number value={data.year} change={v => setData({ ...data, year: v })}/>
                    </td>
                    <td>
                        <div>{context.word('pbray')}</div>
                        <select className="input" value={data.pbrayType}
                            onChange={e => setData({ ...data, pbrayType: e.target.value as PBrayType })}>
                            <option value={PBrayType.Bray1}>{context.word('bray1')}</option>
                            <option value={PBrayType.Bray2}>{context.word('bray2')}</option>
                            <option value={PBrayType.Olsen}>{context.word('olsen')}</option>
                            <option value={PBrayType.Colwell}>{context.word('colwell')}</option>
                            <option value={PBrayType.Ambic1}>{context.word('ambic1')}</option>
                        </select>
                    </td>

                    {nullableNumber(context.word('pbray_value'), 'pbray')}
                </tr>

                <tr>
                    {nullableMeasure(context.word('ph'), 'ph')}
                    {nullableMeasure(context.word('k'), 'k')}
                    {nullableMeasure(context.word('ma'), 'na')}
                    {nullableMeasure(context.word('ca'), 'ca')}
                    {nullableMeasure(context.word('mg'), 'mg')}
                    {nullableMeasure(context.word('fe'), 'fe')}
                    {nullableMeasure(context.word('mn'), 'mn')}
                </tr>
                <tr>
                    {nullableMeasure(context.word('cu'), 'cu')}
                    {nullableMeasure(context.word('zn'), 'zn')}
                    {nullableMeasure(context.word('c'), 'c')}
                    {nullableMeasure(context.word('nh4n'), 'nh4N')}
                    {nullableMeasure(context.word('no3'), 'no3')}
                    {nullableMeasure(context.word('cl'), 'cl')}
                    {nullableMeasure(context.word('samac'), 'sAmAc')}
                </tr>

                <tr>
                    {nullableNumber(context.word('uit'), 'uit')}
                    {nullableNumber(context.word('caPercent'), 'caPercent')}
                    {nullableNumber(context.word('mgPercent'), 'mgPercent')}
                    {nullableNumber(context.word('kPercent'), 'kPercent')}
                    {nullableNumber(context.word('ma'), 'ma')}
                    {nullableNumber(context.word('suur'), 'suur')}
                    {nullableNumber(context.word('caToMg'), 'caToMg')}
                </tr>
                <tr>
                    {nullableNumber(context.word('caPlusMg'), 'caPlusMg')}
                    {nullableNumber(context.word('mgToK'), 'mgToK')}
                    {nullableNumber(context.word('sWaarde'), 'sWaarde')}
                    {nullableNumber(context.word('naToK'), 'naToK')}
                    {nullableNumber(context.word('t'), 't')}
                    {nullableNumber(context.word('digtheid'), 'digtheid')}
                </tr>

            </tbody>
        </table>

        <div className="flex justify-end items-end">
            <button className='m-2 p-2 text-white rounded-xl bg-red-500' onClick={() => props.onDiscard()}>{context.word('discard')}</button>
            <button className='m-2 p-2 text-white rounded-xl bg-primary' onClick={() => safeSoilData(data)}>{context.word('save')}</button>
        </div>
    </div>
}

export default EditSoil
