import React from 'react'

const Icons = {
    irrigation: <img className="text-red-800 stoke-current px-1 w-6 h-6 inline-block" src="/icons/irrigation.svg" alt="irrigation"/>,
    trees: <img className="text-red-800 stoke-current px-1 w-6 h-6 inline-block" src="/icons/trees.svg" alt="trees"/>,
    size: <img className="text-red-800 stoke-current px-1 w-6 h-6 inline-block" src="/icons/size.svg" alt="size"/>,
    aim: <img className="text-red-800 stoke-current px-1 w-6 h-6 inline-block" src="/icons/aim.svg" alt="aim"/>,
    previous: <img className="text-red-800 stoke-current px-1 w-6 h-6 inline-block" src="/icons/previous.svg" alt="previous"/>,
    plant: <img className="px-1 w-6 h-6 inline-block" src="/icons/plant.svg" alt="plant"/>,
    age: <img className="px-1 w-6 h-6 inline-block" src="/icons/age.svg" alt="age"/>,
    water: <img className="px-1 w-6 h-6 inline-block text-red-500" src="/icons/water.svg" alt="age"/>
}

export default Icons
