import React from 'react'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import ProductObject from '../../controllers/ProductObject'
import MeasureValue from '../../controllers/MeasureValue'
import { classNames } from '../../wrapper'
import { ConvertData, ConvertSize } from './units'
import JsonPlayField from '../../controllers/JsonPlayField'
import ElementTotal from '../../controllers/ElementTotal'
import NullableMeasureValue from '../../controllers/NullableMeasureValue'
import { roundTo } from './ViewBlock'

export function makeProductObject<T> (factory: (key: keyof ProductObject<T>) => T): ProductObject<T> {
    return {
        ca: factory('ca'),
        mn: factory('mn'),
        b: factory('b'),
        cu: factory('cu'),
        fe: factory('fe'),
        k: factory('k'),
        k10: factory('k10'),
        mg: factory('mg'),
        n: factory('n'),
        p: factory('p'),
        p205: factory('p205'),
        s: factory('s'),
        zn: factory('zn')
    }
}

export function formatMeasureValue (measurement: MeasurementUnit, val: MeasureValue): string {
    const v = getValue(measurement, val)
    if (v === 0) { return '' }
    return v.toFixed(2)
}

export function buildConvertData (sizes: ConvertSize, sg: number): ConvertData {
    return { ...sizes, sg }
}

export function measureValueNullable (metric: number | null, imperial: number | null): NullableMeasureValue {
    return { metric, imperial }
}

export function measureValue (metric: number, imperial?: number): MeasureValue {
    return { metric, imperial: imperial ?? metric }
}

export function addMeasureValue (a: MeasureValue, b: MeasureValue): MeasureValue {
    return measureValue(a.metric + b.metric, a.imperial + b.imperial)
}

export function mulMeasureValue (a: MeasureValue, b: MeasureValue): MeasureValue {
    return measureValue(a.metric * b.metric, a.imperial * b.imperial)
}

export function divMeasureValue (a: MeasureValue, b: MeasureValue): MeasureValue {
    return measureValue(b.metric === 0 ? 0 : a.metric / b.metric, b.imperial === 0 ? 0 : a.imperial / b.imperial)
}

export function measureValueNull (): NullableMeasureValue {
    return {
        metric: null,
        imperial: null
    }
}

export function parseNullableNumber (value: string): number | null {
    if (value.trim() === '') {
        return null
    }
    const val = parseFloat(value)
    if (isNaN(val) || !isFinite(val)) {
        return null
    }
    return val
}

export function emptyPlayField (): JsonPlayField {
    return {
        columns: [],
        cells: [],
        rows: [],
        elementTotals: makeProductObject<ElementTotal>(() => ({ land: measureValue(0, 0), standard: measureValue(0, 0) })),
    }
}

export const FormatValue: React.FC<{ value: number | null, emptyColor?: string , decimalPlaces?: number}> = ({ value, emptyColor ,decimalPlaces=2}) => {
    return <span className={classNames(value === 0 ? (emptyColor ?? 'text-white') : 'text-black')}>{value?.toFixed(decimalPlaces)}</span>
}

export function getValue (system: MeasurementUnit, value: MeasureValue): number {
    return system === MeasurementUnit.METRIC
        ? value.metric
        : value.imperial
}

export function getValueNullable (system: MeasurementUnit, value: NullableMeasureValue): number | null {
    return system === MeasurementUnit.METRIC
        ? value.metric
        : value.imperial
}

const ElementValues: React.FC<{
    system: MeasurementUnit,
    values: ProductObject<MeasureValue>,
    emptyColor: string
}> = ({ system, values, emptyColor }) => {
    // console.log('ElementValues render');
    return (
        <>
            <td className="border-t border-b border-r"><FormatValue emptyColor={emptyColor} value={getValue(system, values.n)} decimalPlaces={1}/></td>
            {system === MeasurementUnit.METRIC
                ? <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.p)} decimalPlaces={1}/></td>
                : <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.p205)} decimalPlaces={1}/></td>
            }
            {system === MeasurementUnit.METRIC
                ? <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.k)} decimalPlaces={1}/></td>
                : <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.k10)} decimalPlaces={1}/></td>}
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.ca)} decimalPlaces={1}/></td>
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.mg)} decimalPlaces={1}/></td>
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.s)} decimalPlaces={1}/></td>
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.fe)} decimalPlaces={1}/></td>
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.zn)} decimalPlaces={1}/></td>
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.b)} decimalPlaces={1}/></td>
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.mn)} decimalPlaces={1}/></td>
            <td className="border"><FormatValue emptyColor={emptyColor} value={getValue(system, values.cu)} decimalPlaces={1}/></td>
        </>
    )
}

export default React.memo(ElementValues)
