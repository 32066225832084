import React, { useContext, useState } from 'react'
import Dialog from '../../components/Dialog'
import AppContext from '../../appContext'

interface WarningInternalState<T> {
    show: boolean;
    title: string;
    data: T;
}

interface WarningState<T> {
    show: (title: string, data: T) => void;
    hide: () => void;
    state: WarningInternalState<T>;
}

export function useWarningState<T> (empty: T): WarningState<T> {
    const [state, setState] = useState<WarningInternalState<T>>({
        show: false,
        title: '',
        data: empty
    })

    function show (title: string, data: T) {
        setState({
            show: true,
            title,
            data
        })
    }

    function hide () {
        setState({
            show: false,
            title: '',
            data: empty
        })
    }

    return {
        show,
        hide,
        state
    }
}

interface WarningProps<T> {
    state: WarningState<T>;
    onYes: (data: T) => void;
    onNo?: (data: T) => void;
}

const Warning = <T, >(props: WarningProps<T>) => {
    const state = props.state.state
    const context = useContext(AppContext)

    return <Dialog title={state.title} show={state.show} body={<div className="p-2 text-xl">
        <div className="m-2">
            {state.title}
        </div>
        <div className="flex m-2 text-white justify-center">
            <button className="bg-primary p-2 rounded m-1 px-4" onClick={() => {
                props.onYes(state.data)
                props.state.hide()
            }}>
                {context.word('yes')}
            </button>
            <button className="bg-error-500 p-2 rounded m-1 px-4" onClick={() => {
                props.onNo?.(state.data)
                props.state.hide()
            }}>
                {context.word('no')}
            </button>
        </div>
    </div>}/>
}

export default Warning
