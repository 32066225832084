/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */

enum PBrayType {
    Bray1 = "Bray1",
    Bray2 = "Bray2",
    Olsen = "Olsen",
    Colwell = "Colwell",
    Ambic1 = "Ambic1"
}

export default PBrayType;
