import { PlayFieldState } from './playFieldState'
import PlayFieldLand from '../../controllers/PlayFieldLand'
import MeasureValue from '../../controllers/MeasureValue'
import ProductObject from '../../controllers/ProductObject'
import { makeProductObject } from './Helpers'
import {isPricingColumn, isProductColumn} from "./Functions";

function compareValue (a: MeasureValue, b: MeasureValue): boolean {
    return a.imperial === b.imperial && a.metric === b.metric
}

function compareProductObject<T> (a: ProductObject<T>, b: ProductObject<T>, comparer: (a: T, b: T) => boolean): boolean {
    const allKeys: (keyof ProductObject<T>)[] = [
        'ca',
        'mn',
        'b',
        'cu',
        'fe',
        'k',
        'k10',
        'mg',
        'n',
        'p',
        'p205',
        's',
        'zn']

    for (const key of allKeys) {
        if (!comparer(a[key], b[key])) {
            return false
        }
    }
    return true
}

export function comparePlayField ({ calculations: calcs }: PlayFieldState, land : PlayFieldLand): boolean {
    const data = land.data;
    
    if (calcs.pricingColumns.length !== data.columns.length) 
        return false;
    if (calcs.standardTotals.length !== data.columns.length) 
        return false;
    if (calcs.landTotals.length !== data.columns.length) 
        return false;
    if (calcs.extractions.length !== data.rows.length)
        return false;
    
    // first compare standard and land totals
    for (let i = 0; i < data.columns.length; i++) {
        // todo: need to check type before access
        const calcCol = calcs.pricingColumns[i]!
        const landCol = data.columns[i]!;
        
        if (calcCol.type === 'column' && isProductColumn(landCol)) {
            if (!compareValue(calcCol.data.standard, landCol.standard)) 
                return false;
            if (!compareValue(calcCol.data.land, landCol.land))
                return false;
        } else if (calcCol.type === "pricing" && isPricingColumn(landCol)) {
            if (calcCol.data.color != landCol.color || calcCol.data.rows.length != landCol.rows.length) 
                return false;
            
            for (let j = 0; j < landCol.rows.length; j++) {
                if (!compareValue(calcCol.data.rows[j]!, landCol.rows[j]!)) 
                    return false;
            }
            
        } else {
            // different types definitely not equal
            return false;
        }
    }

    // extractions
    for (let i = 0; i < data.rows.length; i++) {
        if (!compareProductObject(calcs.extractions[i]!, data.rows[i]!.elements, compareValue)) {
            return false
        }
    }

    if (!compareProductObject(calcs.elementStdTotals, makeProductObject(key => data.elementTotals[key].standard), compareValue)) { return false }
    if (!compareProductObject(calcs.elementLandTotals, makeProductObject(key => data.elementTotals[key].land), compareValue)) { return false }

    return true
}
