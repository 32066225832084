import { Feature } from 'ol'
import { GeoJSON } from "ol/format"
import { Geometry } from "ol/geom"
import { getArea } from "ol/sphere"
import { AppContextData } from '../../appContext'
import { elementAt } from '../../array'
import { AutoCompleteNullable } from '../../components/AutoComplete'
import CheckBox from '../../components/CheckBox'
import Input from '../../components/Input'
import Number from '../../components/Number'
import { TableColumn } from '../../components/PagedSearchTable'
import SelectString from '../../components/SelectString'
import CropResponse from '../../controllers/CropResponse'
import CropType from '../../controllers/CropType'
import CultivarAllResponse from '../../controllers/CultivarAllResponse'
import LandController from "../../controllers/LandController"
import LandData from '../../controllers/LandData'
import MeasurementUnit from "../../controllers/MeasurementUnit"
import Originate from "../../controllers/Originate"
import ProductUnit from '../../controllers/ProductUnit'
import convert, { makeValue, Unit } from './converter'
import { customUnits, measureValueCustomUnit, unitCustom } from './CustomUnits'
import { getValue, measureValue } from './Helpers'
import { MeasureValueField } from './MeasureValueField'
import { filled, ProgramLandSetupEx } from './SetupLandHelpers'
import { conversions } from './units'
import { roundTo } from './ViewBlock'
interface RowValidation {
    haValidation: boolean;
    cultivarRequired: boolean;
    cropRequired: boolean;
}
 
export const AimUnitsOptionsMetric: ProductUnit[] = [
    ProductUnit.TonHa, 
    ProductUnit.BinHa, 
    ProductUnit.BushHa
];

export const AimUnitsOptionsImperial: ProductUnit[] = [
    ProductUnit.STonAc, 
    ProductUnit.BinAc, 
    ProductUnit.BushAc
];


function calcArea(coordinates: number[][]) {
    const geojsonObject = {
        'type': 'FeatureCollection',
        'crs': {
            'type': 'name',
            'properties': {
                'name': 'EPSG:3857'
            }
        },
        'features': [{
            'type': 'Feature',
            'geometry': {
                'type': 'Polygon',
                'coordinates': [coordinates]
            }
        }]
    };
    const geo = new GeoJSON({
        featureProjection: "EPSG:3857",
        dataProjection: "EPSG:4326"
    })

    const feature = geo.readFeatures(geojsonObject)
    const first = elementAt(feature, 0, new Feature());
    const geometry: Geometry = first.getGeometry() ?? new Geometry();
    const area = getArea(geometry)
    return (area / 10000).toFixed(1)
}

export const columnFactory = {
    select (app: AppContextData, allSelected: boolean, setAll: (checked: boolean) => void, setLand: (index: number, partial: Partial<ProgramLandSetupEx>) => void, lands: ProgramLandSetupEx[]) {
        function checkChanged(checked: boolean, index: number) {
            const land = lands[index]
            
            if (land === undefined) return
            
            if (land.originate === Originate.Local) {
                setLand(index, { selected: checked })
                return
            }
            
            // Get boundary and calculate HA from MFW.
            LandController.boundary(land.id).then((resp) => {
                let ha = roundTo(parseFloat(calcArea(resp.map(e => [e.y, e.x]))), 1) || null
                if (resp.length == 0 || ha == null) {
                    return 
                }
                setLand(index, { selected: checked, data: {...land.data, size: measureValueCustomUnit(ha, customUnits.ha_ac, MeasurementUnit.METRIC)}})
            })
        }
        const selectColumn: TableColumn<ProgramLandSetupEx> = {
            header: <div className="text-center">
                <div>{app.word('select')}</div>
                <CheckBox value={allSelected} onChange={checked => {
                    setAll(checked)
                }}/>
            </div>,
            row: (r, index) => <CheckBox value={r.selected}
                onChange={checked => checkChanged(checked, index)}/>
        }
        return selectColumn
    },

    name (app: AppContextData) {
        const nameColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.wordUnit('land_name', 'ranch_name'),
            row: (r) => r.data.name
        }
        return nameColumn
    },
    crop (app: AppContextData, crops: CropResponse[], isPerennial: boolean, multipleCrops: number | null, changeSelectedCrops: (cropValue: (number | null)) => void, rowValidation: RowValidation[], setLand: (index: number, partial: Partial<ProgramLandSetupEx>) => void) {
        const cropColumn: TableColumn<ProgramLandSetupEx> = {
            header: <div className='flex flex-col justify-center'>
                <div className='min-w-[8rem]'>{app.word('crop')}</div>
                <AutoCompleteNullable
                    options={crops.filter(c => c.cropType === (isPerennial ? CropType.Perennial : CropType.Annual))}
                    textFunc={t => t.name}
                    valueFunc={t => t.id}
                    value={multipleCrops}
                    onChange={value => changeSelectedCrops(value)
                    }/>
            </div>,
            row: (r, index) =>
                <AutoCompleteNullable
                    options={crops.filter(c => c.cropType === (isPerennial ? CropType.Perennial : CropType.Annual))}
                    textFunc={t => t.name}
                    valueFunc={t => t.id}
                    error={!rowValidation[index]!.cropRequired}
                    value={isPerennial ? r.cropIdPerennial : r.cropIdAnnual}
                    onChange={value => {
                        if (isPerennial) { setLand(index, { cropIdPerennial: value }) } else { setLand(index, { cropIdAnnual: value }) }
                    }}/>
                }
                return cropColumn
            },
            cultivar (app: AppContextData, cultivars: CultivarAllResponse[], isPerennial: boolean, multipleCrops: number | null, multipleCultivars: number | null, changeSelectedCultivars: (cultivarValue: (number | null)) => void, rowValidation: RowValidation[], setLand: (index: number, partial: Partial<ProgramLandSetupEx>) => void) {
                const cultivarColumn: TableColumn<ProgramLandSetupEx> = {
                    header: <div className='flex flex-col justify-center'>
                <div className='min-w-[8rem]'>{app.word('cultivar')}</div>
                <AutoCompleteNullable
                    options={cultivars.filter(c => c.cropId === multipleCrops)}
                    textFunc={t => t.name}
                    valueFunc={t => t.id}
                    value={multipleCultivars}
                    onChange={value => changeSelectedCultivars(value)
                    }/>
            </div>,
            row: (r, index) => <AutoCompleteNullable
            options={cultivars.filter(c => c.cropId === (isPerennial ? r.cropIdPerennial : r.cropIdAnnual))}
            textFunc={t => t.name} valueFunc={t => t.id}
            value={isPerennial ? r.cultivarIdPerennial : r.cultivarIdAnnual}
            error={!rowValidation[index]!.cultivarRequired}
            onChange={value => {
                if (isPerennial) { setLand(index, { cultivarIdPerennial: value }) } else { setLand(index, { cultivarIdAnnual: value }) }
            }}/>
        }
        return cultivarColumn
    },
    ha (app: AppContextData, rowValidation: RowValidation[], setLandData: (index: number, partial: Partial<LandData>) => void) {
        const haColumn: TableColumn<ProgramLandSetupEx> = {
            header: <div className='min-w-[4rem]'>
                {unitCustom(app.initial.system, customUnits.ha_ac)}
            </div>,
            row: (r, index) => <MeasureValueField error={!rowValidation[index]!.haValidation} value={measureValue(roundTo(r.data.size.metric, 2), roundTo(r.data.size.imperial, 2))}
            unit={customUnits.ha_ac} update={v => {
                setLandData(index, {
                    size: v,
                    plantsperSize: Math.floor(r.data.totalTrees / getValue(app.initial.system, v))
                })
            }}/>
        }
        return haColumn
    },
    irrigation (app: AppContextData, setLandData: (index: number, partial: Partial<LandData>) => void) {
        const irrigationColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('irrigation'),
            row: (r, index) => <Input value={r.data.irrigation ?? ''}
            change={v => setLandData(index, { irrigation: v === '' ? null : v })}/>
        }
        return irrigationColumn
    },
    rootStock (app: AppContextData, setLandData: (index: number, partial: Partial<LandData>) => void) {
        const rootstockColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('rootstock'),
            row: (r, index) => <Input value={r.data.rootStock ?? ''}
            change={v => setLandData(index, { rootStock: v === '' ? null : v })}/>
        }
        return rootstockColumn
    },
    treeSpacing (app: AppContextData, rowValidation: RowValidation[], setLandData: (index: number, partial: Partial<LandData>) => void) {
        const treeSpaceColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('tree_spacing') + ' ' + '(' + unitCustom(app.initial.system, customUnits.mm_Feet) + ')',
            row: (r, index) => <MeasureValueField
            value={measureValue(roundTo(r.data.treeSpacing.metric, 2), roundTo(r.data.treeSpacing.imperial,2))}
            unit={customUnits.mm_Feet}
            update={value => {
                // convert size and spacing from ha to m^2
                const size = convert(makeValue(r.data.size.metric, Unit.Hectare), Unit.Meter2)
                // convert rowSpacing from mm to m
                const rowSpacing = convert(makeValue(r.data.rowSpacing.metric, Unit.Millimeter), Unit.Meter)
                // convert treeSpacing from mm to m
                const treeSpacing = convert(makeValue(value.metric, Unit.Millimeter), Unit.Meter)
                if (size === null || rowSpacing === null || treeSpacing === null) { return }
                const totalTrees = size / (rowSpacing * treeSpacing)
                const treesPerSize = totalTrees / getValue(app.initial.system, r.data.size)
                
                setLandData(index, {
                    treeSpacing: value,
                    totalTrees: Math.floor(totalTrees),
                    plantsperSize: Math.floor(treesPerSize)
                })
            }}/>
        }
        return treeSpaceColumn
    },
    rowSpacing (app: AppContextData, rowValidation: RowValidation[], setLandData: (index: number, partial: Partial<LandData>) => void) {
        const rowSpaceColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('row_spacing') + ' ' + '(' + unitCustom(app.initial.system, customUnits.mm_Feet) + ')',
            row: (r, index) => <MeasureValueField
            value={measureValue(roundTo(r.data.rowSpacing.metric, 2), roundTo(r.data.rowSpacing.imperial, 2))}
            unit={customUnits.mm_Feet}
            update={v => {
                // convert size and spacing from ha to m^2
                const size = convert(makeValue(r.data.size.metric, Unit.Hectare), Unit.Meter2)
                // convert rowSpacing from mm to m
                const rowSpacing = convert(makeValue(v.metric, Unit.Millimeter), Unit.Meter)
                // convert treeSpacing from mm to m
                const treeSpacing = convert(makeValue(r.data.treeSpacing.metric, Unit.Millimeter), Unit.Meter)
                if (size === null || rowSpacing === null || treeSpacing === null) { return }
                const totalTrees = size / (rowSpacing * treeSpacing)
                const treesPerSize = totalTrees / getValue(app.initial.system, r.data.size)
                setLandData(index, {
                    rowSpacing: v,
                    totalTrees: Math.floor(totalTrees),
                    plantsperSize: Math.floor(treesPerSize)
                })
            }}
            />
        }
        return rowSpaceColumn
    },
    stickWidth (app: AppContextData, rowValidation: RowValidation[], setLandData: (index: number, partial: Partial<LandData>) => void) {
        const stickWidthColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('stick_width') + ' ' + '(' + unitCustom(app.initial.system, customUnits.mm_Feet) + ')',
            row: (r, index) => <MeasureValueField value={measureValue(roundTo(r.data.stickWidth.metric, 2), roundTo(r.data.stickWidth.imperial, 2))}
            unit={customUnits.mm_Feet}
            update={v => setLandData(index, { stickWidth: v })}/>
        }
        return stickWidthColumn
    },
    rowWidth (app: AppContextData, rowValidation: RowValidation[], setLandData: (index: number, partial: Partial<LandData>) => void) {
        const rowWidthColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('row_width') + ' ' + '(' + unitCustom(app.initial.system, customUnits.mm_Feet) + ')',
            row: (r, index) => <MeasureValueField value={measureValue(roundTo(r.data.rowWidth.metric, 2), roundTo(r.data.rowWidth.imperial, 2))}
            unit={customUnits.mm_Feet}
            update={v => setLandData(index, { rowWidth: v })}/>
        }
        return rowWidthColumn
    },
    treesPerHa (app: AppContextData, setLandData: (index: number, partial: Partial<LandData>) => void) {
        const treesPerHaColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('trees_per') + ' ' + '(' + unitCustom(app.initial.system, customUnits.ha_ac) + ')',
            row: (r, index) => <Number value={r.data.plantsperSize} change={v => {
                setLandData(index, { plantsperSize: v })
            }}/>
        }
        return treesPerHaColumn
    },
    plantsPerMm (app: AppContextData, setLandData: (index: number, partial: Partial<LandData>) => void) {
        const plantsPerMmColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('plants_per') + ' ' + '(' + unitCustom(app.initial.system, customUnits.ha_ac) + ')',
            row: (r, index) => <Number value={r.data.plantsperSize}
            change={v => setLandData(index, { plantsperSize: v })}/>
        }
        return plantsPerMmColumn
    },
    aimProduction (app: AppContextData, rowValidation: RowValidation[], setLandData: (index: number, partial: Partial<LandData>) => void, unit: ProductUnit, setUnit: (value: ProductUnit) => void) {
        const aimProductionColumn: TableColumn<ProgramLandSetupEx> = {
            header: <div>
                <div>{app.word('aim_production')}</div>
                <SelectString 
                    options={app.initial.system === MeasurementUnit.METRIC ? AimUnitsOptionsMetric : AimUnitsOptionsImperial}
                    textFunc={t => conversions[t].display} 
                    valueFunc={t => t} 
                    value={unit} 
                    onChange={c => setUnit(c as ProductUnit)}/>
            </div>,
            row: (r, index) => 
            <MeasureValueField
                value={(measureValue(roundTo(r.data.estimateProduction.metric, 2), roundTo(r.data.estimateProduction.imperial, 2)))} 
                unit={unit}
                update={v => setLandData(index, { estimateProduction: v })}/>
        }
        return aimProductionColumn
    },
    previousProduction (app: AppContextData, rowValidation: RowValidation[], setLandData: (index: number, partial: Partial<LandData>) => void, preUnit: ProductUnit, setPreUnit: (value: ProductUnit) => void) {
        const previousProductionColumn: TableColumn<ProgramLandSetupEx> = {
            header: <div>
                <div> {app.word('previous_production')}</div>
                <SelectString 
                options={app.initial.system === MeasurementUnit.METRIC ? AimUnitsOptionsMetric : AimUnitsOptionsImperial} 
                textFunc={t => conversions[t].display} 
                valueFunc={t => t} 
                value={preUnit} 
                onChange={v => setPreUnit(v as ProductUnit)}
                />
            </div>,
            row: (r, index) => 
            <MeasureValueField
                value={measureValue(roundTo(r.data.totalProduction.metric, 2), roundTo(r.data.totalProduction.imperial, 2))} 
                unit={preUnit}
                update={v => setLandData(index, { totalProduction: v })}/>
        }
        return previousProductionColumn
    },
    totalTrees (app: AppContextData, setLandData: (index: number, partial: Partial<LandData>) => void) {
        const totalTreesColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('total_trees'),
            row: (r, index) => <Number value={r.data.totalTrees} change={v => setLandData(index, {
                totalTrees: v,
                plantsperSize: v / getValue(app.initial.system, r.data.size)
            })}/>
        }
        return totalTreesColumn
    },
    yearPlanted (app: AppContextData, setLandData: (index: number, partial: Partial<LandData>) => void) {
        const yearPlantedColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('year_planted'),
            row: (r, index) => <Number
                value={r.data.yearPlanted}
                change={v => {
                    const currentYear = new Date().getFullYear()
                    const treeAge = currentYear - v
                    setLandData(index, {
                        yearPlanted: v,
                        treeAge
                    })
                }}
            />
        }
        return yearPlantedColumn
    },
    treeAge (app: AppContextData, setLandData: (index: number, partial: Partial<LandData>) => void) {
        const treeAgeColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('tree_age'),
            row: (r, index) => <Number value={r.data.treeAge} change={v => setLandData(index, { treeAge: v })}/>
        }
        return treeAgeColumn
    },
    leaf (app: AppContextData, showLeafDataPopup: (row: ProgramLandSetupEx) => void) {
        const leafDataColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('leaf_data'),
            row: (r) => <div className="bg-primary btn-sm"
                onClick={() => showLeafDataPopup(r)}>{r.leaf.length}</div>
        }
        return leafDataColumn
    },
    soil (app: AppContextData, showSoilDataPopup: (row: ProgramLandSetupEx) => void) {
        const soilDataColumn: TableColumn<ProgramLandSetupEx> = {
            header: app.word('soil_chemical_data'),
            row: (r) => <div className="bg-primary btn-sm"
                onClick={() => showSoilDataPopup(r)}>{r.ground.length}</div>
        }
        return soilDataColumn
    }
}

export function columnValidation (lands: ProgramLandSetupEx[], isPerennial: boolean): RowValidation[] {
    return lands.map(land => ({
        // ha should be bigger then 0
        haValidation: !land.selected || filled(land.data.size),
        // all lands required a valida crop and cultivar
        cultivarRequired: !land.selected || (isPerennial ? land.cultivarIdPerennial : land.cultivarIdAnnual) !== null,
        cropRequired: !land.selected || (isPerennial ? land.cropIdPerennial : land.cropIdAnnual) !== null,

    }))
}
