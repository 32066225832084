export function mapMany<T, Q> (arr: T[], map: (data: T) => Q[]): Q[] {
    return arr.reduce<Q[]>((prev, curr) => [...prev, ...map(curr)], [])
}

export function distinct<T> (arr: T[]): T[] {
    return arr.filter((v, i) => !arr.includes(v, i + 1))
}

export function elementAt<T>(arr: T[], index: number, fallback: T): T {
    return arr[index] ?? fallback;
}

export function arrayToRec<T, K extends string | number | symbol, V>(arr: T[], keySelector: (item:T) => K, valueSelector: (item: T) => V): Record<K, V> {
    return arr.reduce((acc, item) => {
        acc[keySelector(item)] = valueSelector(item)
        return acc
    }, {} as Record<K, V>)
}

export function insertAt<T>(arr: T[], element: T, index: number): T[] {
    if (index < 0 || index > arr.length) {
        return arr;
    }
    return [...arr.slice(0, index), element, ...arr.slice(index)];
}

export function fill<T>(length: number, value: T): T[] {
    if (length <= 0) 
        return [];
    
    return new Array(length).fill(value);
}

export interface Aggregate<T> {
    key: T;
    items: T[];
    fromIndex: number;
    toIndex: number;
}

export function sequentialGroupBy<Tin>(arr: Tin[], compare: (prev: Tin, next: Tin) => boolean): Aggregate<Tin>[] { // [element: Tin, index: number][][]
    return arr.reduce<Aggregate<Tin>[]>((prev, cur, index, arr) => {
        if (prev.length === 0) {
            return [{key: cur, items: [cur], fromIndex: index, toIndex: index}];
        }
        const last = prev[prev.length - 1]!;
        if (compare(last.key, cur)) {
            last.items.push(cur);
            last.toIndex = index;
            return prev;
        }
        prev.push({key: cur, items: [cur], fromIndex: index, toIndex: index});
        return prev;
    }, []);
}