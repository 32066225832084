import MeasurementUnit from '../../controllers/MeasurementUnit'
import ProductUnit from '../../controllers/ProductUnit'
import { asRealNumber } from '../../controllers/helper'

export enum UnitForm { Liquid, Solid, Both }

// data needed to convert units
export interface ConvertSize {
    trees: number;
    // size in ha (metric) and ac (imperial)
    size: {
        ha: number,
        ac: number
    };
    water: {
        lHa: number,
        galAc: number
    }
}

export interface ConvertData extends ConvertSize {
    // the source product sg. To convert from liquid to weight
    sg: number;
}

interface ConvertableUnit {
    // if's its a metric or imperial
    measure: MeasurementUnit;
    // when switching from metric to imperial or vice versa
    opposite: ProductUnit;
    // how this unit is displayed
    display: string;
    // for lookup purposes.
    form: UnitForm;
    // Convert the unit to a kg/Ha base value.
    // kPerHa is the common root to convert from one unit to another
    kgPerHa: (value: number, data: ConvertData) => number;
}

export function defaultUnit (system: MeasurementUnit, solid: boolean): ProductUnit {
    if (system === MeasurementUnit.METRIC) { 
        return solid ? ProductUnit.KgHa : ProductUnit.LHa 
    }

    return solid ? ProductUnit.LbAc : ProductUnit.GallonAc
}

export interface UnitValue {
    readonly unit: ProductUnit;
    readonly value: number;
}

// convert a unit value from imperial to metric or vice versa.
export function flipSystem (source: UnitValue, convertData: ConvertData): UnitValue {
    return convertKgPerHa(source, conversions[source.unit].opposite, convertData)
}

export function oppositeUnit (unit: ProductUnit): ProductUnit {
    return conversions[unit].opposite
}

export function convertKgPerHa (source: UnitValue, target: ProductUnit, convertData: ConvertData): UnitValue {
    const a = conversions[source.unit].kgPerHa(source.value, convertData)
    const b = conversions[target].kgPerHa(1, convertData)

    return {
        unit: target,
        value: asRealNumber(a / b)
    }
}

export function convertLiterPerHa (source: UnitValue, target: ProductUnit, convertData: ConvertData): UnitValue {
    const a = conversions[source.unit].kgPerHa(source.value, convertData) / convertData.sg
    const b = conversions[target].kgPerHa(1, convertData) / convertData.sg

    return {
        unit: target,
        value: asRealNumber(a / b)
    }
}

export function summaryUnit (system: MeasurementUnit, form: UnitForm) {
    if (system === MeasurementUnit.METRIC) { return form === UnitForm.Liquid ? ProductUnit.Liter : ProductUnit.Kg }

    return form === UnitForm.Liquid ? ProductUnit.Gallon : ProductUnit.Lb
}

export function unitDisplay (system: MeasurementUnit, metric: ProductUnit, imperial?: ProductUnit): string {
    if (system === MeasurementUnit.METRIC) { return conversions[metric].display }

    if (imperial === undefined) { return conversions[conversions[metric].opposite].display }

    return conversions[imperial].display
}

export interface UnitFormLookup {
    unit: ProductUnit;
    display: string;
}

function _getUnitOptions (form: UnitForm, system: MeasurementUnit): UnitFormLookup[] {
    return Object.keys(conversions)
        .map(k => ({ unit: k as ProductUnit, convert: conversions[k as ProductUnit] }))
        .filter(({ convert }) => (form === UnitForm.Both || convert.form === form) && convert.measure === system)
        .map(({ unit, convert }) => ({
            unit,
            display: convert.display
        }))
}

 
const lbG = 453.59237;
const GallonToLbs = 8.345404452;
const sTonToKg = 907.18474;
const HaToAc = 2.47105

const GallonToKg =  (GallonToLbs * lbG) / 1000; // 3.78541178399
// 0.8922 lbAc = 1 kgHa
const lbAcToKgHa =  (lbG / 1000) * HaToAc; //1.1208494259


// should be configurable
const KgPerBin = 400;
const KgPerBushel = 27.5;

export const conversions: Record<ProductUnit, ConvertableUnit> = {
    [ProductUnit.TonHa]: {
        measure: MeasurementUnit.METRIC,
        opposite: ProductUnit.STonAc,
        display: 'ton/Ha',
        form: UnitForm.Solid,
        kgPerHa: value => value * 1000
    },
    [ProductUnit.STonAc]: {
        measure: MeasurementUnit.IMPERIAL,
        opposite: ProductUnit.TonHa,
        display: 'ston/ac',
        form: UnitForm.Solid,

        // o 2000 lb (exactly 907.18474 kg)

        kgPerHa: value => lbAcToKgHa * value * 2000
    },
    [ProductUnit.KgHa]: {
        measure: MeasurementUnit.METRIC,
        opposite: ProductUnit.LbAc,
        display: 'kg/Ha',
        form: UnitForm.Solid,
        kgPerHa: value => value
    },
    [ProductUnit.LbAc]: {
        measure: MeasurementUnit.IMPERIAL,
        opposite: ProductUnit.KgHa,
        display: 'lb/Ac',
        form: UnitForm.Solid,
        kgPerHa: value => lbAcToKgHa * value
    },
    [ProductUnit.GHa]: {
        measure: MeasurementUnit.METRIC,
        opposite: ProductUnit.OzAc,
        display: 'g/Ha',
        form: UnitForm.Solid,
        kgPerHa: value => value / 1000
    },
    [ProductUnit.OzAc]: {
        measure: MeasurementUnit.IMPERIAL,
        opposite: ProductUnit.GHa,
        display: 'oz/ac',
        form: UnitForm.Solid,
        kgPerHa: value => lbAcToKgHa * value * 0.0625
    },
    [ProductUnit.KgTree]: {
        display: 'kg/tree',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Solid,
        opposite: ProductUnit.LbTree,
        kgPerHa: (value, { trees, size }) => (value * trees) / size.ha
    },
    [ProductUnit.LbTree]: {
        display: 'lb/Tree',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Solid,
        opposite: ProductUnit.KgTree,
        kgPerHa: (value, { trees, size }) => lbAcToKgHa * ((value * trees) / size.ac)
    },
    [ProductUnit.GTree]: {
        display: 'g/Tree',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Solid,
        opposite: ProductUnit.OzTree,
        kgPerHa: (value, data) => value * data.trees / 1000 / data.size.ha
    },
    [ProductUnit.OzTree]: {
        display: 'oz/Tree',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Solid,
        opposite: ProductUnit.GTree,
        kgPerHa: (value, data) => lbAcToKgHa * ((value * data.trees / 16) / data.size.ac)
    },
    [ProductUnit.LHa]: {
        display: 'l/Ha',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.GallonAc,
        kgPerHa: (value, { sg }) => value * sg
    },
    [ProductUnit.GallonAc]: {
        display: 'gallon/ac',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.LHa,
        kgPerHa: (value, { sg }) =>gallonToKgHa(value, sg)
    },
    [ProductUnit.MlHa]: {
        display: 'ml/Ha',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.PintAc,
        kgPerHa: (value, { sg }) => value / 1000 * sg
    },
    [ProductUnit.PintAc]: {
        display: 'pint/ac',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.MlHa,
        kgPerHa: (value, { sg }) => gallonToKgHa(value * 0.125, sg) 
    },
    [ProductUnit.M3Ha]: {
        display: 'm3/Ha',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.Ft3Ac,
        kgPerHa: (value, { sg }) => value * sg * 1000
    },
    [ProductUnit.Ft3Ac]: {
        display: 'ft3/ac',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.M3Ha,
        kgPerHa: (value, { sg }) => gallonToKgHa(value * 7.480519, sg)
    },
    [ProductUnit.LTree]: {
        display: 'l/Tree',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.GallonTree,
        kgPerHa: (value, { sg, trees, size }) => (value * trees * sg) / size.ha
    },
    [ProductUnit.GallonTree]: {
        display: 'gallon/Tree',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.LTree,
        kgPerHa: (value, { sg, trees, size }) => lbAcToKgHa * ((value * trees * GallonToLbs * sg )/ size.ac) 
    },
    [ProductUnit.MlTree]: {
        display: 'ml/Tree',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.PintTree,
        kgPerHa: (value, { trees, sg, size }) => (value / 1000 * trees * sg) / size.ha
    },
    [ProductUnit.PintTree]: {
        display: 'pint/Tree',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.MlTree,
        kgPerHa: (value, { trees, sg, size }) => lbAcToKgHa * ((value  *  1.043176  * trees *sg)/ size.ac)
    },
    [ProductUnit.QuartAc]: {
        display: 'quart/ac',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.MlHa,
        kgPerHa: (value, { sg }) => gallonToKgHa(value / 4 , sg)
    },
    [ProductUnit.Ml100Litre]: {
        display: 'ml/100Litre',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.Pint100Gallon,
        kgPerHa: (value, { sg, water }) => {
            return value / 1000 * sg * (water.lHa / 100)
        }
    },
    [ProductUnit.Pint100Gallon]: {
        display: 'pint/100Gal',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.Ml100Litre,
        kgPerHa: (value, { sg, water }) => lbAcToKgHa * (value * 1.04318 * sg * water.galAc / 100)
    },
    [ProductUnit.G100Litre]: {
        display: 'g/100Litre',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Solid,
        opposite: ProductUnit.Oz100Gallon,
        kgPerHa: (value, { water }) => value / 1000 * (water.lHa / 100)
    },
    [ProductUnit.Oz100Gallon]: {
        display: 'oz/100Gal',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Solid,
        opposite: ProductUnit.G100Litre,
        kgPerHa: (value, { water }) => lbAcToKgHa * (value / 16 * water.galAc / 100)
    },
    [ProductUnit.L100Litre]: {
        display: 'l/100Litre',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.Floz100Gallons,
        kgPerHa: (value, { sg, water }) => value * sg * (water.lHa / 100)
    },
    [ProductUnit.Floz100Gallons]: {
        display: 'fl.oz/100Gal',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.L100Litre,
        kgPerHa: (value, { sg, water }) => {
            // 1oz = 0.0652 lb
            const lb = (value * sg) * 0.0652062; 
            const lbAc = lb * (water.galAc / 100);
            return lbAcToKgHa * lbAc;
        }
    },
    [ProductUnit.FlozAc]: {
        display: 'fl.oz/ac',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.MlHa,
        kgPerHa: (value, { sg }) => lbAcToKgHa * (value * 0.0652062 * sg)
    },
    [ProductUnit.Gallon]: {
        display: 'gallon',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Liquid,
        opposite: ProductUnit.Liter,
        kgPerHa: (value, { size, sg }) => (value * sg * 3.78541) / size.ha
    },
    [ProductUnit.Kg]: {
        display: 'Kg',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Solid,
        opposite: ProductUnit.Lb,
        kgPerHa: (value, { size }) => value / size.ha
    },
    [ProductUnit.Lb]: {
        display: 'Lb',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Solid,
        opposite: ProductUnit.Kg,
        kgPerHa: (value, { size }) => (0.453592 * value) / size.ha
    },
    [ProductUnit.Liter]: {
        display: 'Liter',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Liquid,
        opposite: ProductUnit.Gallon,
        kgPerHa: (value, { sg, size }) => (value * sg) / size.ha
    },
    [ProductUnit.BinHa]: {
        display: 'Bin/Ha',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Solid,
        opposite: ProductUnit.BinAc,
        kgPerHa: (value) => value * KgPerBin
    },
    [ProductUnit.BinAc]: {
        display: 'Bin/Ac',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Solid,
        opposite: ProductUnit.BinHa,
        kgPerHa: (value) => value * 0.404686 * KgPerBin
    },
    [ProductUnit.BushHa]: {
        display: 'Bu/Ha',
        measure: MeasurementUnit.METRIC,
        form: UnitForm.Solid,
        opposite: ProductUnit.BushAc,
        kgPerHa: (value) => value * KgPerBushel
    },
    [ProductUnit.BushAc]: {
        display: 'Bu/Ac',
        measure: MeasurementUnit.IMPERIAL,
        form: UnitForm.Solid,
        opposite: ProductUnit.BushHa,
        kgPerHa: (value) => value * 0.404686 * KgPerBushel
    }
}

// calculate all options once off
export const UnitOptions: Record<MeasurementUnit, Record<UnitForm, UnitFormLookup[]>> = {
    IMPERIAL: {
        [UnitForm.Both]: _getUnitOptions(UnitForm.Both, MeasurementUnit.IMPERIAL),
        [UnitForm.Solid]: _getUnitOptions(UnitForm.Solid, MeasurementUnit.IMPERIAL),
        [UnitForm.Liquid]: _getUnitOptions(UnitForm.Liquid, MeasurementUnit.IMPERIAL)
    },
    METRIC: {
        [UnitForm.Both]: _getUnitOptions(UnitForm.Both, MeasurementUnit.METRIC),
        [UnitForm.Solid]: _getUnitOptions(UnitForm.Solid, MeasurementUnit.METRIC),
        [UnitForm.Liquid]: _getUnitOptions(UnitForm.Liquid, MeasurementUnit.METRIC)
    }
}
function gallonToKgHa(value: number, sg: number): number {
    return lbAcToKgHa * (value * sg * GallonToLbs);
}

