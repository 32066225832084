/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import AddressRequest from "./AddressRequest";
import AddressList from "./AddressList";
import IdRequest from "./IdRequest";
import PaginationResponse from "./PaginationResponse";
import Address from "./Address";
import PaginationRequestSearch from "./PaginationRequestSearch";

const urls = {
    index(): string {
        return "/address/index";
    }
}

export default {
    urls,
    upsert(request: AddressRequest): Promise<boolean> {
        return contentOnly(Axios.post("/address/upsert", request, defaultConfig));
    },
    index(): Promise<AddressList[]> {
        return contentOnly(Axios.get("/address/index", defaultConfig));
    },
    delete(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/address/delete", request, defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<Address>> {
        return contentOnly(Axios.post("/address/paged", request, defaultConfig));
    }
}
