import { PlusCircleIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/solid'
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react'
import AppContext from '../../appContext'
import { DataTable } from '../../components/DataTable'
import Dialog from '../../components/Dialog'
import FileUploader from '../../components/FileUploader'
import { TableColumn } from '../../components/PagedSearchTable'
import Switch from '../../components/Switch'
import CropController from '../../controllers/CropController'
import CropResponse from '../../controllers/CropResponse'
import CropType from '../../controllers/CropType'
import CultivarAllResponse from '../../controllers/CultivarAllResponse'
import CultivarController from '../../controllers/CultivarController'
import GroundData from '../../controllers/GroundData'
import LandData from '../../controllers/LandData'
import LeafData from '../../controllers/LeafData'
import MeasureValue from '../../controllers/MeasureValue'
import ProductUnit from '../../controllers/ProductUnit'
import ProgramLandSetup from '../../controllers/ProgramLandSetup'
import { arrayPush, arrayUpdate, arrayUpdatePartial } from '../../immutableState'
import { useValidation } from '../../validation'
import { min, onEnter, onInputHandler, useAjaxData } from '../../wrapper'
import { parseCSVFile } from './CSVParse'
import { measureValueProductUnit } from './CustomUnits'
import { addMeasureValue, measureValue } from './Helpers'
import { columnFactory, columnValidation } from './SetupLandFieldsColumns'
import { CSVAnnualData, CSVPerennialData, emptyLand, ProgramLandSetupEx } from './SetupLandHelpers'
import { oppositeUnit } from './units'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import SoilSummaryDialog from "./SoilSummaryDialog";
import LeafSummaryDialog from "./LeafSumaryDialog";
import NameId from "./NameId";


function buildUnit(system: MeasurementUnit, unitType: MeasurementUnit, unit: ProductUnit): ProductUnit {
    return system == unitType ? unit : oppositeUnit(unit);
}

function convertToNameId(lands: ProgramLandSetupEx[]): NameId[] {
    return lands.map(l => ({
        id: l.id,
        name: l.data.name ?? ''
    }))
}

const SetupLandFields: React.FC<{
    lands: ProgramLandSetup[];
    cropType: CropType;
    next: (cropType: CropType, lands: ProgramLandSetup[]) => void;
}> = (props) => {
    const app = useContext(AppContext)
    const system= app.initial.system;

    const [cropType, setCropType] = useState(props.cropType)
    useEffect(() => setCropType(props.cropType), [props.cropType])

    const isPerennial = cropType === CropType.Perennial

    const [showLeafData, setShowLeafData] = useState(false)
    const [showSoilData, setShowSoilData] = useState(false)
    const [showLand, setShowLand] = useState(false)
    const [selectedBlockLeaf, setSelectedBlockLeaf] = useState<ProgramLandSetupEx | null>(null)
    const [selectedBlockSoil, setSelectedBlockSoil] = useState<ProgramLandSetupEx | null>(null)
    const [lands, setLands] = useState<ProgramLandSetupEx[]>([])
    const [multipleCrops, setMultipleCrops] = useState<number | null>(null)
    const [multipleCultivars, setMultipleCultivars] = useState<number | null>(null)

    useEffect(() => {
        setLands(props.lands.map(l => ({
            cropIdAnnual: l.cropId,
            cropIdPerennial: l.cropId,
            cultivarIdAnnual: l.data.cultivarId,
            cultivarIdPerennial: l.data.cultivarId,
            ...l,
            selected: false
        })))
    }, [props.lands])

    function setLand(index: number, partial: Partial<ProgramLandSetupEx>) {
        setLands(arrayUpdatePartial(lands, index, partial))
    }

    function setLandData(index: number, partial: Partial<LandData>) {
        setLands(arrayUpdate(lands, index, old => ({
            ...old,
            data: { ...old.data, ...partial }
        })))
    }

    const [landName, setLandName] = useState('')
    const [data] = useState<ProgramLandSetup[]>(props.lands)
    const [crops, setCrops] = useState<CropResponse[]>([])
    const [cultivars, setCultivars] = useState<CultivarAllResponse[]>([])
    const [showAnnualUpload, setShowAnnualUpload] = useState(false)
    const [showPerennialUpload, setShowPerennialUpload] = useState(false)

    const [aimUnit, setAimUnit] = useState<ProductUnit>(app.initial.system == MeasurementUnit.METRIC ? ProductUnit.TonHa : ProductUnit.STonAc);
    const [prevUnit, setPreUnit] = useState<ProductUnit>(app.initial.system == MeasurementUnit.METRIC ? ProductUnit.TonHa : ProductUnit.STonAc);

    useAjaxData(() => CropController.index(), setCrops)
    useAjaxData(() => CultivarController.index(), setCultivars)

    const selected = useMemo(() => lands.filter(l => l.selected), [lands])
    const allSelected = selected.length === lands.length
    const rowValidation = useMemo(() => columnValidation(lands, isPerennial), [lands, isPerennial])

    const validation = useValidation({
        // at least one land should be selected
        atLeastOneLand: selected.length > 0,

        // combine all validations into one
        fieldFilled: rowValidation.every(l => l.haValidation && l.cultivarRequired && l.cropRequired)
    })

    function setAll(checked: boolean) {
        setLands(prev => prev.map(l => ({
            ...l,
            selected: checked
        })))
    }

    function csvUpload(file: File) {
        const reader = new FileReader()
        reader.onload = e => {
            const text: string = e.target?.result as string
            const minId = min(data, d => d.id, 0) - 1
            const entries = parseCSVFile<ProgramLandSetupEx>(text, index => emptyLand(minId - index, landName, measureValue(0, 0)), app.initial.system, isPerennial ? CSVPerennialData : CSVAnnualData)
            setLands(lands.concat(entries))
            isPerennial ? setShowPerennialUpload(false) : setShowAnnualUpload(false)
        }
        reader.readAsText(file)
    }

    function addNewLand() {
        setLands(prev => arrayPush(prev, emptyLand(min(prev, l => l.id, 0) - 1, landName, measureValue(0, 0))))
        setLandName('')
        setShowLand(false)
    }

    function showLeafDataPopup(row: ProgramLandSetupEx) {
        setSelectedBlockLeaf(row)
        setShowLeafData(true)
    }

    function showSoilDataPopup(row: ProgramLandSetupEx) {
        setSelectedBlockSoil(row)
        setShowSoilData(true)
    }

    function reseedIds<T extends { id: number }>(arr: T[]): T[] {
        return arr.map((el, index) => {
            if (el.id < 0)
                return {
                    ...el,
                    id: -index - 1
                };

            return el;
        })
    }


    function setLeaf(data: LeafData[]) {
        // global add for selected lands. Only addition
        if (selectedBlockLeaf == null) {
            const replace = selectedBlockLeaf != null
            setLands(lands.map<ProgramLandSetupEx>(land => {
                let found = data.filter(d => d.landId === land.id);

                if (found.length > 0) {
                    return {
                        ...land,
                        leaf: replace ? found : reseedIds(land.leaf.concat(found as LeafData[])),
                    }
                }
                return land;
            }));
            return;
        }

        // for selected land only. Rewrite
        const index = lands.indexOf(selectedBlockLeaf)
        if (index === -1) { return }
        setLand(index, { leaf: data })
        return lands;
    }

    function setGround(data: GroundData[]) {
        if (selectedBlockSoil === null) {
            const replace = selectedBlockLeaf != null
            setLands(lands.map<ProgramLandSetupEx>(land => {
                let found = data.filter(d => d.landId === land.id);

                if (found.length > 0) {
                    return {
                        ...land,
                        ground: replace ? found : reseedIds(land.ground.concat(found as GroundData[]))
                    }
                }
                return land;
            }));
            return;
        }
        const index = lands.indexOf(selectedBlockSoil)
        if (index === -1) { return }
        setLand(index, { ground: data })
        return lands
    }

    function changeSelectedCrops(cropValue: number | null) {
        if (cropValue === null) {
            return
        }
        setMultipleCrops(cropValue)

        setLands(lands.map(l => {
            if (l.selected) {
                return {
                    ...l,
                    cropIdAnnual: isPerennial ? l.cropIdAnnual : cropValue,
                    cropIdPerennial: isPerennial ? cropValue : l.cropIdPerennial
                }
            }
            return l
        }))
    }

    function changeSelectedCultivars(cultivarValue: number | null) {
        if (cultivarValue === null) {
            return
        }
        setMultipleCultivars(cultivarValue)

        setLands(lands.map(l => {
            if (l.selected) {
                if (isPerennial) { l.cultivarIdPerennial = cultivarValue } else { l.cultivarIdAnnual = cultivarValue }
            }
            return l
        }))
    }

    const columns = useMemo<TableColumn<ProgramLandSetupEx>[]>(() => {
        const selectColumn = columnFactory.select(app, allSelected, setAll, setLand, lands)
        const nameColumn = columnFactory.name(app)
        const cropColumn = columnFactory.crop(app, crops, isPerennial, multipleCrops, changeSelectedCrops, rowValidation, setLand)
        const cultivarColumn = columnFactory.cultivar(app, cultivars, isPerennial, multipleCrops, multipleCultivars, changeSelectedCultivars, rowValidation, setLand)
        const haColumn = columnFactory.ha(app, rowValidation, setLandData)
        const irrigationColumn = columnFactory.irrigation(app, setLandData)
        const rootstockColumn = columnFactory.rootStock(app, setLandData)
        const treeSpaceColumn = columnFactory.treeSpacing(app, rowValidation, setLandData)
        const rowSpaceColumn = columnFactory.rowSpacing(app, rowValidation, setLandData)
        const stickWidthColumn = columnFactory.stickWidth(app, rowValidation, setLandData)
        const rowWidthColumn = columnFactory.rowWidth(app, rowValidation, setLandData)
        const treesPerHaColumn = columnFactory.treesPerHa(app, setLandData)
        const plantsPerMmColumn = columnFactory.plantsPerMm(app, setLandData)

        const aimProductionColumn = columnFactory.aimProduction(app, rowValidation, setLandData, aimUnit, unit => {
            setAimUnit(unit)
            lands.forEach(land => {
                land.data.estimateProduction = measureValueProductUnit(land.data.estimateProduction, unit, oppositeUnit(unit), app.initial.system)
            })
            setLands([...lands])
        })

        const previousProductionColumn = columnFactory.previousProduction(app, rowValidation, setLandData, prevUnit, unit => {
            setPreUnit(unit)
            lands.forEach(land => {
                 land.data.totalProduction = measureValueProductUnit(land.data.totalProduction, unit, oppositeUnit(unit), app.initial.system);
            })
            setLands([...lands])
        })
        const totalTreesColumn = columnFactory.totalTrees(app, setLandData)
        const yearPlantedColumn = columnFactory.yearPlanted(app, setLandData)
        const treeAgeColumn = columnFactory.treeAge(app, setLandData)
        const leafDataColumn = columnFactory.leaf(app, showLeafDataPopup)
        const soilDataColumn = columnFactory.soil(app, showSoilDataPopup)

        return [
            selectColumn, nameColumn, cropColumn, cultivarColumn, haColumn, irrigationColumn,
            ...isPerennial
                ? [rootstockColumn, rowSpaceColumn, treeSpaceColumn, treesPerHaColumn, totalTreesColumn, yearPlantedColumn, treeAgeColumn]
                : [rowWidthColumn, stickWidthColumn, plantsPerMmColumn],
            aimProductionColumn, previousProductionColumn, leafDataColumn, soilDataColumn
        ]
    }, [isPerennial, crops, cultivars, lands, aimUnit, prevUnit])

    function groupLands() {
        const groupName = selected.map(l => l.data.name).join(',')
        // sum total ha into new
        const total: MeasureValue = selected.reduce((prev, cur) => addMeasureValue(prev, cur.data.size), measureValue(0, 0))
        setLands(prev => arrayPush(prev, emptyLand(min(prev, l => l.id, 0) - 1, groupName, total)))
    }

    function next() {
        if (!validation.validate()) { return }

        
        
        // copy set annual and perennial types
        const next = selected.map<ProgramLandSetup>(s => {

            const ret: ProgramLandSetup = {
                ...s,
                cropId: isPerennial ? s.cropIdPerennial : s.cropIdAnnual,
                data: {
                    ...s.data,
                    
                    estimateProductionUnitMetric: buildUnit(system, MeasurementUnit.METRIC, aimUnit),
                    estimateProductionUnitImperial: buildUnit(system, MeasurementUnit.IMPERIAL, aimUnit),
                    
                    totalProductionUnitMetric: buildUnit(system, MeasurementUnit.METRIC, prevUnit),
                    totalProductionUnitImperial: buildUnit(system, MeasurementUnit.IMPERIAL, prevUnit),
                    
                    cultivarId: isPerennial ? s.cultivarIdPerennial : s.cultivarIdAnnual
                }
            }

            return ret;
        })

        props.next(isPerennial ? CropType.Perennial : CropType.Annual, next)
    }

    return <div className="p-2">

        <Dialog title={app.word('soil_chemical_data')}
            show={showSoilData}
            setShow={setShowSoilData}
            body={lands.length > 0 ? <div>

                <SoilSummaryDialog
                    data={selectedBlockSoil?.ground ?? []}
                    onSave={data => {
                        setGround(data);
                        setShowSoilData(false);
                    }}
                    onDiscard={() => setShowSoilData(false)}
                    assignedLandId={selectedBlockSoil?.id ?? lands[0]!.id}
                    lands={convertToNameId(lands)}
                    replaced={selectedBlockSoil != null} />
            </div> : null}
        />

        <Dialog title={app.word('upload_csv')} body={
            <div className="p-4">
                <div>{app.word('download_csv_sample')}, {app.word('add_data_and_upload_again')}.</div>
                <a className="underline" href="/LandsPerennial.csv" download="LandsPerennial.csv">LandsPerennial.csv</a>
                <div className="my-2">
                    <FileUploader onChange={file => csvUpload(file)} fileTypes = ".csv"/>
                </div>
            </div>} show={showPerennialUpload} setShow={setShowPerennialUpload}
        />

        <Dialog title={app.word('upload_csv')} body={
            <div className="p-4">
                <div>{app.word('download_csv_sample')}, {app.word('add_data_and_upload_again')}.</div>
                <a className="underline" href="/LandsAnnual.csv" download="LandsAnnual.csv">LandsAnnual.csv</a>
                <div className="my-2">
                    <FileUploader onChange={file => csvUpload(file)} fileTypes = ".csv"/>
                </div>
            </div>} show={showAnnualUpload} setShow={setShowAnnualUpload}
        />
        <Dialog title={app.wordUnit('local_land', 'local_ranch')} show={showLand} setShow={setShowLand} body={<div>
            
            <div className="flex m-2">
                
                <div className="whitespace-no-wrap">{app.wordUnit('land_name', 'ranch_name')}:</div>
                <input type="text" className="input" value={landName} onInput={onInputHandler(v => setLandName(v))}
                    onKeyDown={onEnter(() => addNewLand())} />
            </div>

            {landName.length > 31 && <span className="p-2 text-xs text-error-500">Note: If {app.wordUnit('land_name', 'ranch_name')} is more than 31 characters the excel will not render properly! </span>}   
            
            <div className="border-gray-100 border-t mt-2 text-right">
                <div className="btn bg-red-500 m-2" onClick={() => setShowLand(false)}>{app.word('discard')}</div>
                <div className="btn bg-primary m-2" onClick={() => addNewLand()}>{app.word('add')}</div>
            </div>
        </div>}
        />

        <Dialog title={app.word('leaf_data')}
            show={showLeafData}
            setShow={setShowLeafData}
            body={lands.length > 0 ? <div>

                <LeafSummaryDialog
                    assignedLandId={selectedBlockLeaf?.id ?? lands[0]!.id}
                    data={selectedBlockLeaf?.leaf ?? []}
                    lands={convertToNameId(lands)}
                    replaced={selectedBlockLeaf != null}
                    onSave={data => {
                        setLeaf(data);
                        setShowLeafData(false);
                    }}
                    onDiscard={() => setShowLeafData(false)}
                />

            </div> : null}
        />

        <div className="text-xl underline tracking-wider">3. {app.word('fields_and_blocks')}</div>

        <div className="flex my-1 items-center">
            <label className='flex items-center'>
                <span className='text-xl'>{app.word('annual')}</span>
                <div className='mx-3 my-2'>
                    <Switch checked={isPerennial} offColor='bg-primary' setChecked={v => setCropType(v ? CropType.Perennial : CropType.Annual)} />
                </div>
                <span className='text-xl'>{app.word('perennial')}</span>
            </label>
            <div className='btn bg-primary ml-8' onClick={() => {
                setSelectedBlockLeaf(null);
                setShowLeafData(true);
            }}>
                {app.word('upload_leaf_data')}
            </div>
            <div className='btn bg-primary' onClick={() => {
                setSelectedBlockSoil(null);
                setShowSoilData(true);
            }}>
                {app.word('upload_soil_data')}
            </div>

            {selected.length > 1
                ? <div className="mx-2 btn bg-primary"
                    onClick={() => groupLands()}>{app.word('group_block')} </div>
                : null}
        </div>

        <div>
            <div
                className="inline-block">{app.word('you_have_selected') + ' ' + selected.length + ' ' + app.wordUnit('lands', 'ranches')}</div>
            <DataTable
                headerClass="bg-gray-200 font-normal text-center"
                dataClass="text-center p-1"
                keyExtractor={r => r.id}
                definitions={columns}
                data={lands}/>

            <div className='flex m-2'>
                <span title={app.word('add_new_block')} className='mr-2'>
                    <PlusCircleIcon className="w-6 h-6 cursor-pointer" onClick={() => {
                        setShowLand(true)
                    }}/>
                </span>
                <span title={app.word('upload_new_blocks')}>
                    <ArrowUpOnSquareIcon className="w-6 h-6 inline-block cursor-pointer"
                        onClick={() => isPerennial ? setShowPerennialUpload(true) : setShowAnnualUpload(true)}/>
                </span>
            </div>

        </div>

        <div
            className="text-error-500">{!validation.rules.atLeastOneLand ? app.wordUnit('at_least_one_land', 'at_least_one_ranch') : ''}</div>
        <div
            className="text-error-500">{!validation.rules.fieldFilled ? app.wordUnit('land_filled_error', 'ranch_filled_error') : ''}</div>

        {validation.dirtyPassed
            ? <div className="text-right my-2">
                <div className="btn bg-primary" onClick={() => next()}>{app.word('next')}</div>
            </div>
            : null}
    </div>
}

export default SetupLandFields

