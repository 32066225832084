import CheckBox from './CheckBox'
import React, { useContext } from 'react'
import { arrayRemoveItem } from '../immutableState'
import AppContext from '../appContext'

function toggleMulti<T> (arr: T[], value: T, state: boolean): T[] {
    if (state) {
        // add value to arr
        return arr.concat(value)
    }
    // remove value from arr
    return arrayRemoveItem(arr, value)
}

function toggleAll<T> (arr: T[], entries: T[]): T[] {
    if (arr.length > 0) {
        return []
    }
    return entries
}

const MultiCheckboxString: React.FC<{
    options: Record<string, string>;
    value: string[];
    setValue: (value: string[]) => void;
}> = (props) => {
    const entries = Object.entries(props.options)
    const context = useContext(AppContext)

    return <div className='flex flex-col'>
        <button className="bg-primary btn" onClick={() => props.setValue(toggleAll(props.value, Object.keys(props.options)))}>{context.word('toggle_all')}</button>
        {
            entries.map(([value, text]) => {
                const state = props.value.includes(value)
                return <CheckBox key={value} renderLabel={true} value={state} onChange={e => props.setValue(toggleMulti(props.value, value, e))}>
                    <span>{context.word(text as string)}</span>
                </CheckBox>
            })
        }
    </div>
}

const MultiCheckbox: React.FC<{
    options: Record<number, string>;
    value: number[];
    setValue: (value: number[]) => void;
}> = (props) => {
    // Object.values always returns string. Convert to number
    const allVales = Object.keys(props.options).map(o => parseInt(o))
    const context = useContext(AppContext)
    const entries = Object.entries(props.options)

    return <div className='flex flex-col'>
        <button className="bg-primary btn max-w-[10rem]" onClick={() => props.setValue(toggleAll(props.value, allVales))}>{context.word('toggle_all')}</button>
        {
            entries.map(([value, text]) => {
                const v = parseInt(value)
                const state = props.value.includes(v)
                return <CheckBox key={v} renderLabel={true} value={state} onChange={e => props.setValue(toggleMulti(props.value, v, e))}>
                    <span>{text as string}</span>
                </CheckBox>
            })
        }
    </div>
}

export { MultiCheckbox, MultiCheckboxString }
