import { AxiosRequestConfig, AxiosResponse } from 'axios'

export function isLocalhost() {
    return window.location.host.includes('localhost');
}

export function inRange(index: number, arr: any[]): boolean {
    return index >= 0 && index < arr.length;
}

function queryEncode(key: string, value: any) {
    return encodeURIComponent(key) + '=' + encodeURIComponent(value);
}

function keyValue(key: string, value: any): string {
    if (Array.isArray(value)) {
        return value.map(v => queryEncode(key, v)).join("&")
    }
    return queryEncode(key, value);
}

export function queryUrl(url: string, params: { [param: string]: any }): string {
    return url + "?" + Object.keys(params).map((key) => keyValue(key, params[key])).join('&');
}

export function toFormData(obj: any): FormData {
    const formData = new FormData()
    for (const k in obj) {
        const v = obj[k];
        if (Array.isArray(v)) {
            for (const val of v) {
                formData.append(k, val)
            }
            continue
        }
        if (v !== undefined)
            formData.append(k,  v)
    }
    return formData;
}

export function isRealNumber(number: number): boolean {
    return Number.isNaN(number) || !Number.isFinite(number);
}

export function asRealNumber(number: number): number {
    return isRealNumber(number) ? 0 : number;
}

export function parseIntSafe(value: string): number {
    return asRealNumber(parseInt(value))
}

// Date format in ISO 8601 format
const reISO = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i

function parseDate(key: any, value: any): any {
    if (typeof value === 'string') {
        let a = reISO.exec(value);
        if (a)
            return new Date(value);
    }
    return value;
}

function transformDates(data: any): any {
    if (data == '' || data === null || data === undefined)
        return data

    try {
        // data may be invalid or not even be JSON
        return JSON.parse(data, parseDate);
    } catch {
        return data
    }
}

function transformRequest(data: any): any {
    return JSON.stringify(data, (key, value) => {
        // Infinity and NaN serialize as null
        // Now serialize as 0
        if (typeof value === 'number' && (isNaN(value) || !isFinite(value)))
            return 0;
        
        return value;
    });
}

export const defaultFormConfig: AxiosRequestConfig = {
    transformResponse: transformDates,
    headers: {'Content-Type': 'multipart/form-data'}
};

// .NET controllers should always return some data. We can never expect a response without data
// Sometimes when the  client's internet is slow or unreliable, response headers are returned without the payload
// if the payload is not filled reject the promise and handle accordingly 
export function contentOnly<T>(promise: Promise<AxiosResponse<T>>): Promise<T> {
    return promise.then(resp => {
        
        if (resp?.data == undefined)
            throw new Error(`Http endpoint returned no data.`);
        
        return resp.data;
    });
}

export const defaultConfig: AxiosRequestConfig = {
    transformResponse: transformDates,
    transformRequest: transformRequest,
    headers: {'Content-Type': 'application/json'}
};
