/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import Company from "./Company";
import PaginationResponse from "./PaginationResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import CompanyRequest from "./CompanyRequest";

const urls = {
    index(): string {
        return "/company/index";
    }
}

export default {
    urls,
    index(): Promise<Company[]> {
        return contentOnly(Axios.get("/company/index", defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<Company>> {
        return contentOnly(Axios.post("/company/paged", request, defaultConfig));
    },
    upsert(request: CompanyRequest): Promise<boolean> {
        return contentOnly(Axios.post("/company/upsert", request, defaultConfig));
    }
}
