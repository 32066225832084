import React, {useContext, useRef, useState} from "react";
import LeafData from "../../controllers/LeafData";
import AppContext from "../../appContext";
import LeafSummary from "./LeafSummary";
import NameId from "./NameId";

const LeafSummaryDialog: React.FC<{
    data: LeafData[];
    assignedLandId?: number;
    lands?: NameId[]
    replaced?: boolean;

    onSave: (data: LeafData[]) => void;
    onDiscard: () => void;
}> = (props) => {

    const [data, setData] = useState<LeafData[]>([]);
    const changed = useRef<boolean>(false);
    const app = useContext(AppContext)

    return <>
        <LeafSummary
            assignedLandId={props.assignedLandId}
            lands={props.lands}
            replaced={props.replaced}
            data={props.data}
            setData={d => {
                setData(d)
                changed.current = true;
            }}
        />

        <div className="btn bg-red-500 m-1" onClick={() => props.onDiscard()}>{app.word('discard')}</div>
        <div className="btn bg-primary m-1" onClick={() => {
            changed.current ? props.onSave(data) : props.onDiscard()
        }} >{app.word('save')}</div>
    </>
}

export default LeafSummaryDialog;