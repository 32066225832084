/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import CropResponse from "./CropResponse";
import CropCultivar from "./CropCultivar";
import PaginationResponse from "./PaginationResponse";
import CropPageResponse from "./CropPageResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import CropDeleteRequest from "./CropDeleteRequest";
import CropRequest from "./CropRequest";

const urls = {
    index(): string {
        return "/crop/index";
    },
    withCultivars(): string {
        return "/crop/withcultivars";
    }
}

export default {
    urls,
    index(): Promise<CropResponse[]> {
        return contentOnly(Axios.get("/crop/index", defaultConfig));
    },
    withCultivars(): Promise<CropCultivar[]> {
        return contentOnly(Axios.get("/crop/withcultivars", defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<CropPageResponse>> {
        return contentOnly(Axios.post("/crop/paged", request, defaultConfig));
    },
    delete(request: CropDeleteRequest): Promise<boolean> {
        return contentOnly(Axios.post("/crop/delete", request, defaultConfig));
    },
    upsert(request: CropRequest): Promise<boolean> {
        return contentOnly(Axios.post("/crop/upsert", toFormData(request), defaultFormConfig));
    }
}
