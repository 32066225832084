import React, { useContext, useEffect, useState } from 'react'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import LeafData from '../../controllers/LeafData'
import { customUnits, unitCustom } from './CustomUnits'
import CheckBox from '../../components/CheckBox'
import { FormatValue, getValueNullable, measureValueNull, parseNullableNumber } from './Helpers'
import { DataTable } from '../../components/DataTable'
import { PlusCircleIcon, ArrowUpOnSquareIcon } from '@heroicons/react/24/solid'
import { min } from '../../wrapper'
import { arrayPush, arrayRemoveIndex, arrayUpdate, arrayUpdatePartial } from '../../immutableState'
import Dialog from '../../components/Dialog'
import EditLeaf from './EditLeaf'
import FileUploader from '../../components/FileUploader'
import { parseMeasureValueNullable } from './MeasureValueField'
import appContext from '../../appContext'
import { CSVPopulate, parseCSVFile } from './CSVParse'
import { TableColumn } from '../../components/PagedSearchTable'
import SelectNumber from '../../components/SelectNumber'
import { parseIntSafe } from '../../controllers/helper'
import SelectNumberNullable from '../../components/SelectNumberNullable'
import LeafSummaryDialog from "./LeafSumaryDialog";
import NameId from "./NameId";

function emptyLeaf (id: number, assignedLandId: number): LeafDataCsv {
    return {
        _block: '',
        b: measureValueNull(),
        ca: null,
        cl: measureValueNull(),
        cu: measureValueNull(),
        fe: measureValueNull(),
        id,
        k: null,
        landId: assignedLandId,
        measurementUnit: MeasurementUnit.METRIC,
        mg: null,
        mn: measureValueNull(),
        mo: measureValueNull(),
        n: null,
        na: measureValueNull(),
        ni: measureValueNull(),
        p: null,
        s: null,
        sample: null,
        showOnPdf: false,
        year: new Date().getFullYear(),
        zn: measureValueNull()
    }
}

interface LeafDataCsv extends LeafData {
    _block: string;
}

function filterTableColumns<T>(columns: (TableColumn<T> | null)[]): TableColumn<T>[] {

    return columns.filter(c => c != null) as TableColumn<T>[];
}

const yearPopulate: CSVPopulate<LeafData> = (value, entry) => {
    entry.year = parseIntSafe(value);
};


const CSVLeafData: Record<string, CSVPopulate<LeafDataCsv>> = {
    // Used to map the name
    Block: (value, entry) => {
        entry._block = value;
    },
    Year: yearPopulate,
    Jaar: yearPopulate,
    'Sample#': (value, entry) => {
        entry.sample = value
    },
    'N (%)': (value, entry) => {
        entry.n = parseNullableNumber(value)
    },
    'P (%)': (value, entry) => {
        entry.p = parseNullableNumber(value)
    },
    'K (%)': (value, entry) => {
        entry.k = parseNullableNumber(value)
    },
    'Ca (%)': (value, entry) => {
        entry.ca = parseNullableNumber(value)
    },
    'Mg (%)': (value, entry) => {
        entry.mg = parseNullableNumber(value)
    },
    'S (%)': (value, entry) => {
        entry.s = parseNullableNumber(value)
    },
    'Na (mg/kg)': (value, entry, system) => {
        entry.na = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Fe (mg/kg)': (value, entry, system) => {
        entry.fe = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Mn (mg/kg)': (value, entry, system) => {
        entry.mn = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Zn (mg/kg)': (value, entry, system) => {
        entry.zn = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Cu (mg/kg)': (value, entry, system) => {
        entry.cu = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'B (mg/kg)': (value, entry, system) => {
        entry.b = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Mo (mg/kg)': (value, entry, system) => {
        entry.mo = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Ni (mg/kg)': (value, entry, system) => {
        entry.ni = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    },
    'Cl (mg/kg)': (value, entry, system) => {
        entry.cl = parseMeasureValueNullable(value, customUnits.mgKg_OzLb, system)
    }
}



const LeafSummary: React.FC<{
    data: LeafData[];
    setData: (data: LeafData[]) => void;

    assignedLandId?: number;
    lands?: NameId[]
    replaced?: boolean
}> = (props) => {
    const context = useContext(appContext)
    const system = context.initial.system;

    function convertToLeafData(data: LeafData[]): LeafData[] {
        return data.map(l => ({...l, landId: props.assignedLandId ?? 0}))
    }

    function convertFromCsv(data: LeafDataCsv[]): LeafData[] {
        function getLandId(block: string) {
            let hasMatch = props.lands?.find(l => l.name === block);
            return hasMatch?.id ?? 0
        }
        
        return data.map(l => ({...l, landId: getLandId(l._block)}))
    }


    const [data, _setData] = useState<LeafData[]>(convertToLeafData(props.data));

    useEffect(() => {
        _setData(convertToLeafData(props.data))
    }, [props.data]);
    
    function setData (g: LeafData[]) {
        _setData(g)
        props.setData(g)
    }

    const [show, setShow] = useState(false)
    const unit = unitCustom(system, customUnits.mgKg_OzLb)
    const [editData, setEditData] = useState<LeafData>(emptyLeaf(-1, props.assignedLandId ?? 0))
    const [showUpload, setShowUpload] = useState(false)

    function onRemove (i: number): void {
        setData(arrayRemoveIndex(data, i))
    }

    function onEdit (row: LeafData) {
        setEditData(row)
        setShow(true)
    }

    function addRow () {
        setShow(true)
        setEditData(emptyLeaf(min(data, d => d.id, 0) - 1, props.assignedLandId ?? 0))
    }

    function saveChanges (edit: LeafData) {
        setShow(false)
        const index = data.findIndex(d => d.id === edit.id)

        if (index === -1) {
            // we need to insert.
            setData(arrayPush(data, edit))
        } else {
            // need to update
            const update = arrayUpdate(data, index, edit)
            setData(update)
        }
    }

    function toggleShowOnPdf (state: boolean, index: number): void {
        setData(arrayUpdatePartial(data, index, { showOnPdf: state }))
    }

    function csvUpload (file: File) {
        const reader = new FileReader()
        reader.onload = e => {
            const text: string = e.target?.result as string
            const minId = min(data, d => d.id, 0) - 1
            const entries = parseCSVFile<LeafDataCsv>(text, index => emptyLeaf(minId - index, props.assignedLandId ?? 0), context.initial.system, CSVLeafData)
            setData(data.concat(convertFromCsv(entries)))
            //console.log(context.initial.system)
            setShowUpload(false)
        }
        reader.readAsText(file)
    }


    return <div className="my-2">

        <Dialog
            show={show}
            setShow={setShow}
            title={editData.id <  0 ? context.word('add_leafData') :  context.word('edit_leafData')}
            body={<EditLeaf data={editData} onDiscard={() => setShow(false)} onSave={d => saveChanges(d)}/>}
        />

        <Dialog title={context.word('upload_csv')} body={
            <div className="p-4">
                <div>{context.word('download_csv_sample')}, {context.word('add_data_and_upload_again')}.</div>
                <a className="underline" href="/LeafSample.csv" download="LeafSample.csv">LeafSample.csv</a>
                <div className="my-2">
                    <FileUploader onChange={file => csvUpload(file)} fileTypes = ".csv"/>
                </div>
            </div>} show={showUpload} setShow={setShowUpload}
        />

        {data.length > 0
            ? <>
                <div className="text-xl text-primary">{context.word('leaf_summary')}</div>
                <div className='overflow-y-auto max-h-60'>
                    <DataTable
                        headerClass="px-2 py-2 text-left text-xs font-semibold bg-gray-200"
                        dataClass="whitespace-nowrap px-1 py-2 text-xs text-gray-600"
                        keyExtractor={r => r.id}
                        definitions={filterTableColumns<LeafData>([
                            {
                                header: <div className="flex items-center">
                                    <CheckBox value={data.every(r => r.showOnPdf)} onChange={s => {
                                        setData(data.map(d => ({
                                            ...d,
                                            showOnPdf: s
                                        })))
                                    }} />
                                    <div className="pr-2">{context.word('show_on_pdf')}</div>
                                </div>,

                                row: (r, index) => <CheckBox value={r.showOnPdf} onChange={state => toggleShowOnPdf(state, index)}/>
                            },
                            !props.replaced && props.lands ?
                            {
                                header: <div className="flex items-center">
                                    <div className="pr-2">{context.word('land')}</div>
                                    <SelectNumberNullable options={props.lands!} textFunc={l => l.name ?? ""} valueFunc={l=> l.id} value={null} onChange={l => {
                                        if (l) {
                                            setData(data.map(d => ({
                                                ...d,
                                                landId: l
                                            })))
                                        }
                                    }} />
                                </div>,

                                row: (r, index) => <SelectNumber options={props.lands!} textFunc={l => l.name ?? ""} valueFunc={l=> l.id} value={r.landId} onChange={l => setData(arrayUpdatePartial(data, index, {landId: l}))}/>
                            } : null,
                            {
                                header: <>{context.word('year')}</>,
                                row: r => r.year
                            },
                            {
                                header: <>{context.word('sample')} #</>,
                                row: r => r.sample
                            },
                            {
                                header: <>{context.word('n')} (%)</>,
                                row: r => <FormatValue value={r.n}/>
                            },
                            {
                                header: <>{context.word('p')} (%)</>,
                                row: r => <FormatValue value={r.p}/>
                            },
                            {
                                header: <>{context.word('k')} (%)</>,
                                row: r => <FormatValue value={r.k}/>
                            },
                            {
                                header: <>{context.word('ca')} (%)</>,
                                row: r => <FormatValue value={r.ca}/>
                            },
                            {
                                header: <>{context.word('mg')} (%)</>,
                                row: r => <FormatValue value={r.mg}/>
                            },
                            {
                                header: <>{context.word('s')} (%)</>,
                                row: r => <FormatValue value={r.s}/>
                            },
                            {
                                header: <>{context.word('na')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.na)}/>
                            },
                            {
                                header: <>{context.word('fe')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.fe)}/>
                            },
                            {
                                header: <>{context.word('mn')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.mn)}/>
                            },
                            {
                                header: <>{context.word('zn')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.zn)}/>
                            },
                            {
                                header: <>{context.word('cu')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.cu)}/>
                            },
                            {
                                header: <>{context.word('b')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.b)}/>
                            },
                            {
                                header: <>{context.word('mo')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.mo)}/>
                            },
                            {
                                header: <>{context.word('ni')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.ni)}/>
                            },
                            {
                                header: <>{context.word('cl')} {unit}</>,
                                row: r => <FormatValue value={getValueNullable(system, r.cl)}/>
                            },
                            {
                                header: <>{context.word('actions')}</>,
                                row: (r, index) => <div>
                                    <span className="underline cursor-pointer text-primary" onClick={() => onRemove(index)}>{context.word('remove')}</span>
                                    <span className="px-2">|</span>
                                    <span className="underline cursor-pointer text-primary" onClick={() => onEdit(r)}>  {context.word('edit')}</span>
                                </div>
                            }
                        ])}
                        data={data}
                    />
                </div>
            </>
            : <div>{context.word('no_leaf_data')}</div>}

        <span title={context.word('add_leaf_data')} className='ml-1'>
            <PlusCircleIcon className="w-6 h-6 inline-block cursor-pointer" onClick={() => addRow()}/>
        </span>
        <span title={context.word('upload_leaf_data')}>
            <ArrowUpOnSquareIcon className="w-6 h-6 inline-block cursor-pointer" onClick={() => setShowUpload(true)}/>
        </span>
    </div>
}

export default React.memo(LeafSummary)
