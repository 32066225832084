import React, { Dispatch, InputHTMLAttributes } from 'react'

function leadingZero (num: number): string {
    const f = num.toString()
    if (f.length === 0) { return '00' }

    if (f.length === 1) {
        return '0' + f
    }
    return f
}

export enum DatePickerType {
    Date,
    DataTimeLocal
}

function parseDate (type: DatePickerType, str: string): Date {
    switch (type) {
    case DatePickerType.Date:
        // yyyy-MM-dd to DateTime
        return new Date(
            parseInt(str.substr(0, 4)),
            parseInt(str.substr(5, 2)) - 1,
            parseInt(str.substr(8, 2))
        )

    case DatePickerType.DataTimeLocal:
        // convert format "2022-06-04T14:13" to dateTime
        return new Date(
            parseInt(str.substr(0, 4)),
            parseInt(str.substr(5, 2)) - 1,
            parseInt(str.substr(8, 2)),
            parseInt(str.substr(11, 2)),
            parseInt(str.substr(14, 2))
        ) as any
    }
}

function displayDate (type: DatePickerType, d: Date | null): string {
    if (d === null) { return '' }

    if (typeof d === 'string') {
        d = new Date(d)
    }

    switch (type) {
    case DatePickerType.Date:
        // date format yyyy-MM-dd
        return `${d.getFullYear()}-${leadingZero(d.getMonth() + 1)}-${leadingZero(d.getDate())}`
    case DatePickerType.DataTimeLocal:
        // html5 datetime-local format should be "YYYY-MM-DDThh:mm"
        return `${d.getFullYear()}-${leadingZero(d.getMonth() + 1)}-${leadingZero(d.getDate())}T${leadingZero(d.getHours())}:${leadingZero(d.getMinutes())}`
    }
}

function inputType (type: DatePickerType) {
    return type === DatePickerType.Date ? 'date' : 'datetime-local'
}

interface DatePickerProps<T> {
    value: T;
    setValue: Dispatch<T>;
    type: DatePickerType;
    children?: (attrs: InputHTMLAttributes<any>) => React.ReactNode;
}

const DatePicker: React.FC<DatePickerProps<Date>> = (props) => {
    function onInput (e: React.FormEvent<HTMLInputElement>) {
        const str = (e.target as HTMLInputElement).value
        const d = parseDate(props.type, str) as any
        // test for an invalid date
        if (d instanceof Date && !!d.getDate()) {
            props.setValue(d)
        }
    }

    if (props.children) {
        return <>
            {props.children({
                value: displayDate(props.type, props.value),
                onInput: e => onInput(e),
                type: inputType(props.type)
            })}
        </>
    }

    return <input type={inputType(props.type)} required className="input border border-gray-400 w-full outline-none p-1 bg-transparent"
        value={displayDate(props.type, props.value)} onInput={e => onInput(e)}/>
}

const DatePickerNullable: React.FC<DatePickerProps<Date | null>> = (props) => {
    function onInput (e: React.FormEvent<HTMLInputElement>) {
        const str = (e.target as HTMLInputElement).value
        if (str.trim() === '') {
            props.setValue(null)
            return
        }

        const d = parseDate(props.type, str) as any
        // test for an invalid date
        if (d instanceof Date && !!d.getDate()) {
            props.setValue(d)
        }
    }

    if (props.children) {
        return <>
            {props.children({
                value: '',
                // displayDate(props.type, props.value),
                onInput: e => onInput(e),
                type: inputType(props.type)
            })}
        </>
    }

    return <input type={inputType(props.type)} required className="input w-full outline-none p-1 bg-transparent"
        value={displayDate(props.type, props.value)} onInput={e => onInput(e)}/>
}

export { DatePicker, DatePickerNullable }
