import React from 'react'
import { classNames } from '../wrapper'

const Switch: React.FC<{
    checked: boolean;
    setChecked: (state: boolean) => void;
    offColor?: string
}> = (props) => {
    return <button type="button"
        className={classNames(props.checked ? 'bg-primary' : props.offColor ? props.offColor : 'bg-gray-200 ', 'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500')}
        role="switch" aria-checked="false" onClick={() => props.setChecked(!props.checked)}>
        <span className="sr-only">Use setting</span>
        <span aria-hidden="true"
            className={classNames(props.checked ? 'translate-x-5' : 'translate-x-0', 'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200')}></span>
    </button>
}

export default Switch
