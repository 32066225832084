/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import ProductLookUpResponse from "./ProductLookUpResponse";
import PaginationResponse from "./PaginationResponse";
import ProductResponse from "./ProductResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import InventarisResponse from "./InventarisResponse";
import PhpTokenRequest from "./PhpTokenRequest";
import ProductRequest from "./ProductRequest";
import IdActiveRequest from "./IdActiveRequest";

const urls = {
    index(): string {
        return "/product/index";
    }
}

export default {
    urls,
    index(): Promise<ProductLookUpResponse[]> {
        return contentOnly(Axios.get("/product/index", defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<ProductResponse>> {
        return contentOnly(Axios.post("/product/paged", request, defaultConfig));
    },
    inventaris(request: PhpTokenRequest): Promise<InventarisResponse[]> {
        return contentOnly(Axios.post("/product/inventaris", request, defaultConfig));
    },
    upsert(request: ProductRequest): Promise<boolean> {
        return contentOnly(Axios.post("/product/upsert", toFormData(request), defaultFormConfig));
    },
    toggleActive(request: IdActiveRequest): Promise<boolean> {
        return contentOnly(Axios.post("/product/toggleactive", request, defaultConfig));
    }
}
