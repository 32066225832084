import React, {useContext, useMemo} from "react";
import AppContext, {AppContextData} from "../../appContext";
import ProductObject from "../../controllers/ProductObject";
import MeasureValue from "../../controllers/MeasureValue";
import MeasurementUnit from "../../controllers/MeasurementUnit";
import {getMeasureValue} from "./Functions";
import {customUnits, unitCustom} from "./CustomUnits";

const TableHeaderRow: React.FC<{ label: string }> = ({ label }) => (
    <td className="border border-gray-400 px-1 bg-gray-200 text-sm">{label}</td>
);


const renderTableHeader = (app: AppContextData) => {
    const isMetric = app.initial.system == MeasurementUnit.METRIC

    return <tr className="bg-gray-200 font-semibold">
        <TableHeaderRow label={`${app.word("phenologies")} ${unitCustom(app.initial.system, customUnits.kgha_lbAc)}`}/>
        {[
            "n",
            isMetric ? "p" : "p205",
            isMetric ? "k" : "k10",
            "ca", "mg"
        ].map((element) => (
            <React.Fragment key={element}>
                <TableHeaderRow label={app.word(element)}/>
                <TableHeaderRow label="%"/>
            </React.Fragment>
        ))}
    </tr>
}

function buildTotals(extractions: ProductObject<MeasureValue>[], measure: MeasurementUnit): number[] {
    const totalNVal = calcValues(extractions, s => s.n, measure, null)
    const totalPVal = calcValues(extractions, s => ({
        metric: s.p.metric,
        imperial: s.p205.imperial
    }), measure, null)
    const totalKVal = calcValues(extractions, s => ({
        metric: s.k.metric,
        imperial: s.k10.imperial
    }), measure, null)
    const totalCaVal = calcValues(extractions, s => s.ca, measure, null)
    const totalMgVal = calcValues(extractions, s => s.mg, measure, null)

    return [totalNVal, totalPVal, totalKVal, totalCaVal, totalMgVal]
}

function calcValues(rows: ProductObject<MeasureValue>[], func: (s: ProductObject<MeasureValue>) => MeasureValue, measure: MeasurementUnit, round: number | null) {
    const value = rows.reduce((p, c, i) => getMeasureValue(func(c), measure) + p, 0)
    if (round != null) return roundTo(value, round)
    return value
}

function roundTo(value: number, decimalPlaces: number) {
    return Math.round(value * (10 ** decimalPlaces)) / (10 ** decimalPlaces)
}

function calcPercentages(val: number, total: number | undefined) {
    if (total == undefined || total == 0) return 0;
    return Math.round(100 * val / total)
}

interface phenologyResult {
    value: number,
    percentage: number
}

function calcPhenologyValues(rows: ProductObject<MeasureValue>[], measure: MeasurementUnit, func: (s: ProductObject<MeasureValue>) => MeasureValue, total: number | undefined): phenologyResult {
    const value = calcValues(rows, func, measure, null)
    const percentage = calcPercentages(value, total)
    return { value, percentage }
}

const PhenologyBlock: React.FC<{ extractions: ProductObject<MeasureValue>[] }> = (
    props
) => {
    const app = useContext(AppContext);
    const isMetric = app.initial.system == MeasurementUnit.METRIC

    const calculated = useMemo(() => {
        const [totalNVal, totalPVal, totalKVal, totalCaVal, totalMgVal] = buildTotals(props.extractions, app.initial.system)

        const renderTableRow = (name: string, rows: ProductObject<MeasureValue>[]) => {
            const nValues = calcPhenologyValues(rows, app.initial.system, s => s.n, totalNVal)
            const pValues = calcPhenologyValues(rows, app.initial.system, s => ({
                metric: s.p.metric,
                imperial: s.p205.imperial
            }), totalPVal)
            const kValues = calcPhenologyValues(rows, app.initial.system, s => ({
                metric: s.k.metric,
                imperial: s.k10.imperial
            }), totalKVal)
            const caValues = calcPhenologyValues(rows, app.initial.system, s => s.ca, totalCaVal)
            const mgValues = calcPhenologyValues(rows, app.initial.system, s => s.mg, totalMgVal)


            return <tr key={name} className="font-semibold text-xs">
                <td className="bg-gray-200 border border-gray-400 px-1">{name}</td>
                {
                    [nValues.value, nValues.percentage, pValues.value, pValues.percentage, kValues.value, kValues.percentage, caValues.value, caValues.percentage, mgValues.value, mgValues.percentage]
                        .map(s => s == 0 ? null : roundTo(s, 0)) // don't print out 0's
                        .map((s, index) => (
                            <td key={index} className="border border-gray-400">{s}</td>
                        ))
                }
            </tr>
        }

        let naOes = renderTableRow(app.word("na_oes"), props.extractions.slice(0,2))
        let botBlom = renderTableRow(app.word("bot_blom"), props.extractions.slice(2,5))
        let setDeurslaan = renderTableRow(app.word("set_deurslaan"), props.extractions.slice(5))

        return {
            totalNVal,
            totalPVal,
            totalKVal,
            totalCaVal,
            totalMgVal,
            naOes,
            botBlom,
            setDeurslaan
        }
    }, [props.extractions])

    function roundDoubles(s: number | string | undefined) {
        if (typeof s === "number") {
            return roundTo(s, 0)
        }
        return s
    }

    return (
        <table className="w-full">
            <tbody>
            <tr>
                <td
                    rowSpan={6}
                    className="bg-gray-200 px-3 font-semibold text-sm border border-gray-400"
                >
                    {app.word("total")} (N,{isMetric ? "P" : "P205"},{isMetric ? "K" : "K10"},Mg,Ca)
                </td>
            </tr>
            {renderTableHeader(app)}
            {calculated.naOes}
            {calculated.botBlom}
            {calculated.setDeurslaan}

            <tr className="font-semibold">
                <TableHeaderRow label={`${app.word("total")} (${unitCustom(app.initial.system, customUnits.kgha_lbAc)})`} />
                {
                    [calculated.totalNVal, "", calculated.totalPVal, "", calculated.totalKVal, "", calculated.totalCaVal, "", calculated.totalMgVal, ""]
                        .map(s => roundDoubles(s))
                        .map((s, index) => (
                            <td key={index} className="border border-gray-400 text-sm">{s}</td>
                        ))
                }
            </tr>
            </tbody>
        </table>
    );
};
export default React.memo(PhenologyBlock);
