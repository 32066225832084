import React, { useContext, useRef, useState } from 'react'
import AppContext from '../appContext'
import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import DistributorController from '../controllers/DistributorController'
import DistributorPagedResponse from '../controllers/DistributorPagedResponse'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import Input from '../components/Input'
import { buildSetter } from '../immutableState'
import { useValidation } from '../validation'
import ImageUploader from '../components/ImageUploader'
import { showSuccessOrFailed } from '../Snacks'

export function distImage (logoId: number | null) {
    if (logoId) { return `/logo/${logoId}` }
    return '/images/noimage.png'
}

export function distImageString (logoId: number | null) {
    if (logoId) { return `/logo/${logoId}` }
    return '/images/noimage.png'
}

interface DistributorForm {
    id: number;
    name: string;
    logoId: number | null;
    logoUpload: File | null;
}

function empty (): DistributorForm {
    return {
        name: '',
        id: 0,
        logoId: null,
        logoUpload: null
    }
}

const Distributors: React.FC = () => {
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<DistributorForm>(empty())
    const setData = buildSetter(upsertData, setUpsertData)
    const pagedTableRef = useRef<PagedTableFunctions<DistributorPagedResponse>>()
    const app = useContext(AppContext)
    const [dialogPopup, setDialogPopup] = useState<React.ReactNode>(null)

    function showPopup (preset: DistributorForm) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertCallback (data: DistributorForm) {
        if (!validation.validate()) { return }

        const call = DistributorController.upsert({
            name: data.name,
            id: data.id,
            logo: data.logoUpload
        })

        const message = upsertData.id === null
            ? `${app.word('successfully_inserted')} ${data.name}`
            : `${app.word('successfully_updated')} ${data.name}`

        showSuccessOrFailed(app, call, message)
            .then(() => {
                pagedTableRef.current?.refresh()
                setShow(false)
            })
    }

    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0,
        imageRequired: () => (upsertData.logoId !== null || upsertData.logoUpload !== null)
    })

    return (
        <>
            {dialogPopup ? <Dialog title={app.word('select_approver')} body={dialogPopup} show={true} setShow={() => setDialogPopup(null)}/> : null}
            <Dialog
                mounted={focusFirstInput}
                title={upsertData.id === 0 ? app.word('new_distributor') : app.word('edit_distributor')}
                show={show}
                setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            app.word('name'),
                            <Input value={upsertData.name} change={v => setData({ name: v })} />,
                            validation.rules.nameRequired,
                            app.word('name_required')
                        )}
                        {EditRow(
                            app.word('image'),
                            <ImageUploader imageUrl={distImage(upsertData.logoId)} setImage={v => setData({ logoUpload: v })}/>,
                            validation.rules.imageRequired,
                            app.word('logo_required')
                        )}
                    </EditTable>
                }
            />

            <div className="btn bg-primary m-2" onClick={() => showPopup(empty())}>{app.word('add_distributor')}</div>

            <PagedSearchTable<DistributorPagedResponse>
                componentRef={pagedTableRef}
                call={DistributorController.paged}
                columns={[
                    {
                        header: app.word('name'),
                        row: item => <div>
                            <div>{item.name}</div>
                            <img alt="logo" style={{ maxHeight: '100px' }} src={distImage(item.logoId)}/>
                        </div>
                    },
                    {
                        header: app.word('action'),
                        row: item => <span className="cursor-pointer underline font-medium" onClick={() => showPopup({
                            name: item.name ?? '',
                            id: item.id,
                            logoUpload: null,
                            logoId: item.logoId
                        })}>{app.word('edit')}</span>
                    }
                ]}
                keyExtractor={i => i.id}/>
        </>)
}

export default Distributors
