import { classNames } from '../wrapper'
import React, { useRef, useState, useContext } from 'react'
import AppContext from '../appContext'

let fileId = 0

const FileUploader: React.FC<{
    onChange: (file: File ) => void,
    fileTypes ?: string
}> = (props) => {
    const id = useRef('imageUploader' + fileId++)
    const input = useRef<HTMLInputElement>(null)
    const [highlight, setHighlight] = useState(false)
    const app = useContext(AppContext)

    function onDragEnter (event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(true)
        event.preventDefault()
        event.stopPropagation()
    }

    function onDragLeave (event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(false)
        event.preventDefault()
        event.stopPropagation()
    }

    function onDragOver (event: React.DragEvent<HTMLLabelElement>) {
        setHighlight(true)
        event.preventDefault()
        event.stopPropagation()
    }

    function handleFiles (files: FileList | null) {
        if (files === null || files.length === 0) { return }

        props.onChange(files[0]!)
    }

    function onDrop (event: React.DragEvent<HTMLLabelElement>) {
        handleFiles(event.dataTransfer.files)
        setHighlight(false)
        event.preventDefault()
        event.stopPropagation()
    }

    function inputChanged () {
        handleFiles(input.current!.files)
    }

    return (
        <label
            onDragEnter={e => onDragEnter(e)}
            onDragLeave={e => onDragLeave(e)}
            onDragOver={e => onDragOver(e)}
            onDrop={e => onDrop(e)}
            htmlFor={id.current}
            className={classNames('inline-block border-dashed border relative rounded cursor-pointer w-full', highlight ? 'border-primary-600' : '')}
        >
            <input ref={input} id={id.current} type="file" className="hidden" accept={props.fileTypes} onChange={() => inputChanged()}/>
            
            <div className="text-center">
                <div className="line p-3 text-gray-600 text-sm">{app.word('drop_or_select_file_here')}</div>
                <div className="btn bg-primary-500 my-2">{app.word('browse')}</div>
            </div>
        </label>
    )
}

export default FileUploader
