import React, {PropsWithChildren, useContext} from 'react'
import AppContext from '../appContext'

export interface FieldEntry {
    label: string | React.ReactNode,
    value: React.ReactNode
}

export type KeyOfType<T, V> = keyof {
    [P in keyof T as T[P] extends V ? P : never]: any
};

// focus to the first input or select element inside el
export function focusFirstInput (el: HTMLDivElement) {
    const first = el.querySelector('input,select');
    (first as (HTMLInputElement | HTMLSelectElement))?.focus()
}

export function EditRow (label: string, node: React.ReactNode, rule: boolean = false, errorMessage: string = '') {
    return <tr>
        <td className="align-top">
            <div className="py-3 pr-16 text-gray-800">
                {label}
            </div>
        </td>
        <td>
            {!rule ? <div className="text-error-600">{errorMessage}</div> : null}
            {node}
        </td>
    </tr>
}

// wrapper for common crud structure
export const EditTable: React.FC<PropsWithChildren<{
    discard: () => void;
    save: () => void;
    saveWord: string;
}>> = (props) => {
    const context = useContext(AppContext)
    return <div onKeyUp={event => event.key === 'Enter' ? props.save() : null}>
        <table className="m-4">
            <tbody>
                {props.children}
            </tbody>
        </table>
        <div className="text-right p-2 border-t sticky bottom-0 bg-white">
            <div className="btn bg-red-500" onClick={() => props.discard()}>{context.word('discard')}</div>
            <div className="btn bg-primary-500"
                onClick={() => props.save()} >{ context.word(props.saveWord)}</div>
        </div>
    </div>
}

export const Fields: React.FC<{ fields: FieldEntry[] }> = ({ fields }) => {
    const app = useContext(AppContext)
    return (<table className="m-4">
        <tbody>
            {fields.map((f, i) => (
                <tr key={i}>
                    <td className="align-top">
                        <div className="py-3 pr-16 text-gray-800">
                            {typeof (f.label) === 'string' ? app.word(f.label) : f.label}
                        </div>
                    </td>
                    <td>{f.value}</td>
                </tr>
            ))}
        </tbody>
    </table>)
}
