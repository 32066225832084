import React, { useContext, useState } from 'react'
import appContext from '../../appContext'
import CheckBox from '../../components/CheckBox'
import Number from '../../components/Number'
import { onInputHandler } from '../../wrapper'
import { CustomUnit, customUnits } from './CustomUnits'
import { NullableMeasureValueField } from './MeasureValueField'
import NumberNullable from '../../components/NumberNullable'
import { KeyOfType } from '../../components/Fields'
import NullableMeasureValue from '../../controllers/NullableMeasureValue'
import LeafData from '../../controllers/LeafData'
import { useValidation } from '../../validation'
const EditLeaf: React.FC<{
    data: LeafData;
    onSave: (data: LeafData) => void;
    onDiscard: () => void;
}> = (props) => {
    const context = useContext(appContext)
    const [data, setData] = useState<LeafData>(props.data)

    function nullableMeasure (label: string, key: KeyOfType<LeafData, NullableMeasureValue>, unit: CustomUnit = customUnits.mgKg_OzLb) {
        return <td>
            <div>{label}</div>
            <NullableMeasureValueField unit={unit} value={data[key]} update={v => setData({
                ...data,
                [key]: v
            })}/>
        </td>
    }

    function nullableNumber (label: string, key: KeyOfType<LeafData, number | null>) {
        return <td>
            <div>{label}</div>
            <NumberNullable value={data[key]} change={v => setData({
                ...data,
                [key]: v
            })}/>
        </td>
    }

    const validation = useValidation({
        sampleRequired: () => data.sample !== null && data.sample.length > 0
    })

    function saveLeafData (data: LeafData) {
        if (!validation.validate()) {
            return
        }
        return props.onSave(data)
    }

    return <div>

        <table className="w-full">
            <tbody>
                <tr>
                    <td className="text-center">
                        <div className="mb-1 whitespace-nowrap">{context.word('show_on_pdf')}:</div>
                        <CheckBox value={data.showOnPdf ?? false} onChange={state => setData({
                            ...data,
                            showOnPdf: state
                        })}/>
                    </td>
                    <td>
                        <div>{context.word('sample')} :</div>
                        <div className="text-red-400">{!validation.rules.sampleRequired && context.word('sample_required')}</div>
                        <input className="input" type="text" value={data.sample ?? ''} onInput={onInputHandler(v => setData({
                            ...data,
                            sample: v
                        }))}/>
                    </td>

                    <td>
                        <div>{context.word('year')}</div>
                        <Number value={data.year} change={v => setData({
                            ...data,
                            year: v
                        })}/>
                    </td>
                </tr>

                <tr>
                    {nullableMeasure(context.word('na'), 'na')}
                    {nullableMeasure(context.word('fe'), 'fe')}
                    {nullableMeasure(context.word('mn'), 'mn')}
                    {nullableMeasure(context.word('zn'), 'zn')}
                    {nullableMeasure(context.word('cu'), 'cu')}
                    {nullableMeasure(context.word('b'), 'b')}
                    {nullableMeasure(context.word('mo'), 'mo')}
                    {nullableMeasure(context.word('ni'), 'ni')}
                    {nullableMeasure(context.word('cl'), 'cl')}
                </tr>
                <tr>
                    {nullableNumber(context.word('n'), 'n')}
                    {nullableNumber(context.word('p'), 'p')}
                    {nullableNumber(context.word('k'), 'k')}
                    {nullableNumber(context.word('ca'), 'ca')}
                    {nullableNumber(context.word('mg'), 'mg')}
                    {nullableNumber(context.word('s'), 's')}
                </tr>

            </tbody>
        </table>

        <div className="flex justify-end items-end">
            <button className="m-2 p-2 text-white rounded-xl bg-red-500" onClick={() => props.onDiscard()}>{context.word('discard')}</button>
            <button className="m-2 p-2 text-white rounded-xl bg-primary" onClick={() => saveLeafData(data)}>{context.word('save')}</button>
        </div>
    </div>
}

export default EditLeaf
