import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import RoleController from '../controllers/RoleController'
import RoleDisplay from '../controllers/RoleDisplay'
import PermissionEnum from '../controllers/PermissionEnum'
import AppContext from '../appContext'
import RolesRequest from '../controllers/RolesRequest'
import Input from '../components/Input'
import { buildSetter } from '../immutableState'
import { useValidation } from '../validation'
import { MultiCheckboxString } from '../components/MultiCheckBoxString'
import { showSuccessOrFailed } from '../Snacks'

const emptyRole: RolesRequest = {
    id: null,
    name: '',
    permissions: []
}

const permissionOptions: Record<PermissionEnum, string> = {
    ManagementCurrenciesPermission: "currencies",
    ManageErrors: 'errors',
    ManageProgramsRemovePermission: 'remove_programs',
    ManagementAddress: 'address',
    ManagementAgentPermission: 'agent',
    ManagementApprovalsPermission: 'approval',
    ManagementCategoryPermission: 'category',
    ManagementClientsPermission: 'clients',
    ManagementCompanyPermission: 'company',
    ManagementCropPermission: 'crop',
    ManagementCultivarPermission: 'cultivar',
    ManagementDistributorPermission: 'distributors',
    ManagementFarmsPermission: 'farms',
    ManagementLanguagePermission: 'language',
    ManagementProductsPermission: 'products',
    ManagementProgramsPermission: 'programs',
    ManagementRegions: 'regions',
    ManagementRolesPermissions: 'roles',
    ManagementSyncPermission: 'sync',
    ManagementTemplatesPermission: 'templates',
    ManagementUsersPermission: 'users'
}

const Roles: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<RoleDisplay>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<RolesRequest>(emptyRole)
    const context = useContext(AppContext)

    function showPopup (preset: RolesRequest) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertCallback (role: RolesRequest) {
        if (!validation.validate()) { return }

        const call = RoleController.upsert(role)
        showSuccessOrFailed(context, call).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0
    })

    const setData = buildSetter(upsertData, setUpsertData)

    return (
        <>
            <Dialog mounted={focusFirstInput} title={context.word('role')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('name'),
                            <Input value={upsertData.name} change={v => setData({ name: v })} />,
                            validation.rules.nameRequired,
                            context.word('name_required')
                        )}
                        {EditRow(
                            context.word('list_of_permissions'),
                            <MultiCheckboxString options={permissionOptions} value={upsertData.permissions} setValue={v => setData({ permissions: v as PermissionEnum[] })}/>
                        )}
                    </EditTable>
                }/>

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyRole)}>{context.word('add_role')}</div>
            <PagedSearchTable<RoleDisplay>
                componentRef={pagedTableRef}
                call={RoleController.paged}
                columns={[
                    { header: context.word('name'), row: item => item.name },
                    { header: context.word('users_assigned'), row: item => item.count },
                    { header: context.word('permissions'), row: item => item.permissions.length },
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="underline cursor-pointer" onClick={() => showPopup(item)}>{context.word('edit')}</span>
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Roles
