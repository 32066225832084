import PagedSearchTable from '../components/PagedSearchTable'
import React, { useContext } from 'react'
import FarmPagedResponse from '../controllers/FarmPagedResponse'
import FarmController from '../controllers/FarmController'
import AppContext from '../appContext'

const Farms: React.FC = () => {
    const app = useContext(AppContext)
    return (<PagedSearchTable<FarmPagedResponse>
        call={FarmController.paged}
        columns={[
            {
                header: app.word('id'),
                row: (item) => item.id
            },
            {
                header: app.word('client_name'),
                row: (item) => item.clientName
            },
            {
                header: app.word('name'),
                row: (item) => item.name
            },
            {
                header: app.word('myfarmweb_id'),
                row: (item) => item.mfwId
            },
            {
                header: app.word('actions'),
                row: () => (<div></div>)
            }

        ]}
        keyExtractor={i => i.id}/>)
}

export default Farms
