import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useRef, useState, useContext } from 'react'
import Dialog from '../components/Dialog'
import { focusFirstInput } from '../components/Fields'
import ErrorController from '../controllers/ErrorController'
import Error from '../controllers/Error'
import AppContext from '../appContext'
import Axios from 'axios'
import { defaultConfig, isLocalhost } from '../controllers/helper'

const ShowError: React.FC<{ data: Error }> = (props) => {
    const app = useContext(AppContext)
    return (
        <div className="p-2">
            <div className="text-xl font-bold">{app.word('url')}</div>
            <div>{props.data.url}</div>
            <div className="text-xl font-bold">{app.word('user')}</div>
            <div>{props.data.user}</div>
            <div className="text-xl font-bold">{app.word('headers')}</div>
            <div>{props.data.headers}</div>
            <div className="text-xl font-bold">{app.word('payload')}</div>
            <div>{props.data.payload}</div>
            <div className="text-xl font-bold">{app.word('message')}</div>
            <div>{props.data.message}</div>
            <div className="text-xl font-bold">{app.word('source')}</div>
            <div>{props.data.source}</div>
            <div className="text-xl font-bold">{app.word('stack_trace')}</div>
            <div>{props.data.stackTrace}</div>
        </div>
    )
}

const Errors: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<Error>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<Error>({
        id: 0,
        headers: '',
        message: '',
        payload: '',
        source: '',
        stackTrace: '',
        url: '',
        user: ''
    })

    function showError (item: Error) {
        setUpsertData(item)
        setShow(true)
    }

    const app = useContext(AppContext)

    function replay (item: Error) {
        // strip the origin
        const url = new URL(item.url ?? "");
        Axios.post(url.pathname, JSON.parse(item.payload ?? ""), defaultConfig);
    }

    return (
        <>
            <Dialog mounted={focusFirstInput} title={app.word('error_details')} show={show} setShow={setShow}
                body={<ShowError data={upsertData}/>}/>

            <PagedSearchTable<Error>
                componentRef={pagedTableRef}
                call={ErrorController.index}
                rowClick={(item) => showError(item)}
                columns={[
                    { header: app.word('id'), row: item => item.id },
                    { header: app.word('user'), row: item => item.user },
                    { header: app.word('url'), row: item => item.url },
                    // { header: app.word('url'), row: item => item. },
                    { header: app.word('error'), row: item => item.message?.substring(0, 100) + '...' },
                    {
                        header: app.word('action'),
                        row: item => <div>
                            <a href="#" className="underline" onClick={() => showError(item)}>{app.word('view')}</a>
                            {isLocalhost() ? <a href="#" className="underline" onClick={() => replay(item)}>{app.word('replay')}</a> : null}
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Errors
