import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, {useContext, useEffect, useRef, useState} from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import UserController from '../controllers/UserController'
import UserPaged from '../controllers/UserPaged'
import CheckBox from '../components/CheckBox'
import UpdateUserPropRequest from '../controllers/UpdateUserPropRequest'
import MeasurementUnit from '../controllers/MeasurementUnit'
import RoleController from '../controllers/RoleController'
import LanguageController from '../controllers/LanguageController'
import RegionController from '../controllers/RegionController'
import Tabs from '../components/Tabs'
import { dateFormat } from '../date'
import AppContext from '../appContext'
import { DatePickerNullable, DatePickerType } from '../components/DatePicker'
import { useAjaxData, useStateAjax } from '../wrapper'
import UserUpdate from '../controllers/UserUpdate'
import { AutoComplete } from '../components/AutoComplete'
import UserResponse from '../controllers/UserResponse'
import {useAsyncValidator, useValidation} from '../validation'
import Input from '../components/Input'
import { buildSetter } from '../immutableState'
import SelectNumberNullable from '../components/SelectNumberNullable'
import SelectNumber from '../components/SelectNumber'
import { showSuccessOrFailed } from '../Snacks'
import Success from '../components/Success'
import { SelectEnum } from '../components/SelectEnum'
import {MultiCheckbox} from "../components/MultiCheckBoxString";
import {arrayToRec} from "../array";


const emptyUser: UserUpdate = {
    active: false,
    email: '',
    id: 0,
    languageId: 0,
    measurement: MeasurementUnit.METRIC,
    myFarmWebUsername: '',
    name: '',
    note: '',
    phoneNumber: '',
    qualification: '',
    roleId: null,
    sarnab: false,
    sarnabExpiryDate: null,
    username: '',
    regions: []
}

const validateEmail = (email: string): boolean => {
    const result = email
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
        )
    return (result?.length ?? 0) > 0
}

const strikeOut = (user: UserPaged): string => {
    return user.archived ? 'text-red-500 italic line-through' : ''
}

const ArchivePopup: React.FC<{
    userId: number;
    close: () => void;
}> = (props) => {
    const context = useContext(AppContext)
    const [users, setUsers] = useState<UserResponse[]>([])
    const [assigner, setAssigner] = useState(0)

    useAjaxData(() => UserController.all(), setUsers)

    const validation = useValidation({
        assignerRequired: () => assigner > 0
    })

    function archiveAndReAssign () {
        if (!validation.validate()) { return }

        showSuccessOrFailed(context, UserController.archive({ id: props.userId, assignerId: assigner })).then(() => {
            props.close()
        })
    }

    return <div className="p-2">
        <p>{context.word('reassign_programs')}</p>
        <AutoComplete options={users} textFunc={t => t.name} valueFunc={t => t.id}
            onChange={ value => setAssigner(value)}
            value={assigner}/>
        <span className="text-red-500">{!validation.rules.assignerRequired && 'assigner_required'}</span>
        <div className="p-2">
            <button className="btn bg-red-500" onClick={() => props.close()}>{context.word('cancel')}</button>
            <button className="btn bg-primary-500" onClick={archiveAndReAssign}>{context.word('submit')}</button>
        </div>
    </div>
}

const UsersList: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<UserPaged>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<UserUpdate>(emptyUser)
    const context = useContext(AppContext)
    const [regions, setRegions] = useState<Record<number, string>>({})
    
    useEffect(() => {
        RegionController.index().then(r => setRegions(arrayToRec(r , i => i.id, i => i.name)))
    }, [])

    const uniqueUsername = useAsyncValidator<{ username: string, id: number }>(500, user => UserController.isUnique(user.username, user.id));
    
    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0,
        userNameRequired: () => upsertData.username.length > 0 && uniqueUsername.result,  
        emailRequired: () => upsertData.email.length > 0 && validateEmail(upsertData.email),
        sarnabExpiryDateRequired: () => !(upsertData.sarnabExpiryDate === undefined || upsertData.sarnabExpiryDate === null) || !upsertData.sarnab,
        languageRequired: () => upsertData.languageId !== 0,
        regionsRequired: () => upsertData.regions.length > 0
    })
    function sendPassword (id: number) {
        UserController.sendPassword({ id })
            .then(() => {
                context.showSnack(<Success title={context.word('password_sent')}/>)
            })
    }
    function showUpsert (data: UserUpdate) {
        uniqueUsername.hit({username: data.username, id: data.id})
        setUpsertData(data)
        setShow(true)
        validation.clear()
    }

    function upsert(data: UserUpdate) {
        if (!validation.validate()) { return }

        showSuccessOrFailed(context, UserController.upsert(data))
            .then(() => {
                setShow(false)
                pagedTableRef.current?.refresh()
            })
    }

    function toggleActive (item: UserPaged, update: Partial<UpdateUserPropRequest>) {
        // 1. change the actual data
        const reqObj: UpdateUserPropRequest = {
            id: item.id,
            active: update.active ?? item.active,
            isSuper: update.isSuper ?? item.isSuper,
            locked: update.locked ?? item.locked
        }

        UserController.updateProps(reqObj).then(() => {
            context.showSnack(<Success title={context.word('successfully_updated')}/>)
        })
        pagedTableRef.current?.updateData(prev => {
            const row = prev.find(p => p.id === item.id)
            if (row) {
                row.active = reqObj.active
                row.isSuper = reqObj.isSuper
                row.locked = reqObj.locked
            }
            return prev
        })
    }

    function toggleArchive (id: number, archived: boolean) {
        if (archived) {
            UserController.restore({ id }).then(() => {
                context.showSnack(<Success title={context.word('successfully_restored')}/>)
                pagedTableRef.current?.updateRow(r => r.id === id, r => {
                    r.archived = !archived
                })
            })
        } else {
            setDialogPopup(<ArchivePopup userId={id} close={() => {
                setDialogPopup(null)
                pagedTableRef.current?.refresh()
            }}/>)
        }
    }

    const [dialogPopup, setDialogPopup] = useState<React.ReactNode>(null)

    const [roles] = useStateAjax(() => RoleController.index())
    const [languages] = useStateAjax(() => LanguageController.index())

    // const roles = useMemo(() => mapToSelectOption(RoleController.index(), r => r.id, r => r.name), [])

    
    const setData = buildSetter(upsertData, setUpsertData)

    return (
        <>
            {dialogPopup ? <Dialog title={context.word('select_approver')} body={dialogPopup} show={true} setShow={() => setDialogPopup(null)}/> : null}

            <Dialog
                mounted={focusFirstInput}
                title={context.word('user')}
                show={show}
                setShow={setShow}
                body={

                    <EditTable save={() => upsert(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('name'),
                            <Input value={upsertData.name} change={v => setData({ name: v })} />,
                            validation.rules.nameRequired,
                            context.word('name_required')
                        )}

                        {EditRow(
                            context.word('username'),
                            <Input value={upsertData.username} change={v => {
                                setData({ username: v })
                                uniqueUsername.hit({username: v, id: upsertData.id})
                            }}/>,
                            validation.rules.userNameRequired,
                            context.word(uniqueUsername.isLoading ? 'loading' :  'unique_username_required')
                        )}

                        {EditRow(
                            context.word('active'),
                            <CheckBox value={upsertData.active} onChange={v => setData({ active: v })} />
                        )}

                        {EditRow(
                            context.word('email'),
                            <Input value={upsertData.email} change={v => setData({ email: v })} />,
                            validation.rules.emailRequired,
                            context.word('email_required')
                        )}

                        {EditRow(
                            context.word('myFarmWebUsername'),
                            <Input value={upsertData.myFarmWebUsername} change={v => setData({ myFarmWebUsername: v })} />
                        )}

                        {EditRow(
                            context.word('phoneNumber'),
                            <Input value={upsertData.phoneNumber} change={v => setData({ phoneNumber: v })} />
                        )}

                        {EditRow(
                            context.word('qualification'),
                            <Input value={upsertData.qualification} change={v => setData({ qualification: v })} />
                        )}

                        {EditRow(
                            context.word('sarnab'),
                            <CheckBox value={upsertData.sarnab} onChange={v => setData({ sarnab: v })} />
                        )}

                        {upsertData.sarnab
                            ? EditRow(
                                context.word('SACNASPExpiryDate'),
                                <DatePickerNullable value={upsertData.sarnabExpiryDate} setValue={v => setData({ sarnabExpiryDate: v })} type={DatePickerType.Date} />,
                                validation.rules.sarnabExpiryDateRequired,
                                context.word('sarnabExpiryDate_required')
                            )
                            : null}

                        {EditRow(
                            context.word('role'),
                            <SelectNumberNullable value={upsertData.roleId} onChange={v => setData({ roleId: v })} options={roles} textFunc={r => r.name} valueFunc={r => r.id}/>
                        )}

                        {EditRow(
                            context.word('language'),
                            <SelectNumber value={upsertData.languageId} onChange={v => setData({ languageId: v })} options={languages} textFunc={r => r.name} valueFunc={r => r.id}/>,
                            validation.rules.languageRequired,
                            context.word('language_required')
                        )}

                        {EditRow(
                            context.word('measurement'),
                            <SelectEnum value={upsertData.measurement} onChange={v => setData({ measurement: v as MeasurementUnit })} options={MeasurementUnit}/>
                        )}

                        {EditRow(
                            context.word('regions'),
                            <MultiCheckbox options={regions} value={upsertData.regions}
                                           setValue={v => setData({ regions: v })}/>,
                            validation.rules.regionsRequired,
                            context.word('region_required')
                        )}

                    </EditTable>

                }
            />

            <div className="btn bg-primary m-2" onClick={() => showUpsert(emptyUser)}>{context.word('add_user')}</div>
            <PagedSearchTable<UserPaged>
                componentRef={pagedTableRef}
                call={UserController.paged}
                columns={[
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="underline cursor-pointer" onClick={() => showUpsert(item)}>{context.word('edit')}</span>
                            <span>&nbsp;&nbsp;</span>
                            <span className="underline cursor-pointer"
                                onClick={() => toggleArchive(item.id, item.archived)}> {item.archived ? context.word('restore') : context.word('archive')} </span>
                            <span>&nbsp;&nbsp;</span>
                            <span onClick={() => sendPassword(item.id)} className="underline cursor-pointer">{context.word('send_password')}</span>
                        </div>
                    },
                    { header: context.word('username'), row: item => <div className={strikeOut(item)}>{item.username}</div> },
                    { header: context.word('name'), row: item => <div className={strikeOut(item)}>{item.name}</div> },
                    { header: context.word('email'), row: item => <div className={strikeOut(item)}>{item.email}</div> },
                    { header: context.word('role'), row: item => <div className={strikeOut(item)}>{item.role}</div> },
                    {
                        header: context.word('active'),
                        row: item => <CheckBox value={item.active}
                            onChange={value => toggleActive(item, { active: value })}/>
                    },
                    {
                        header: context.word('admin'),
                        row: item => <CheckBox value={item.isSuper}
                            onChange={value => toggleActive(item, { isSuper: value })}/>
                    },
                    {
                        header: context.word('locked'),
                        row: item => <CheckBox value={item.locked}
                            onChange={value => toggleActive(item, { locked: value })}/>
                    },
                    { header: context.word('language'), row: item => item.language }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

const LoginHistory: React.FC = () => {
    const app = useContext(AppContext)
    return <PagedSearchTable call={UserController.loginHistory} keyExtractor={l => l.id} columns={[
        {
            header: app.word('id'),
            row: (item) => item.id
        },
        {
            header: app.word('username'),
            row: (item) => item.username
        },
        {
            header: app.word('message'),
            row: (item) => item.message
        },
        {
            header: app.word('date'),
            row: (item) => dateFormat(item.loggedIn, '%d %M %Y %H:%i:%s')
        }
    ]}/>
}

const ConnectedUsers: React.FC = () => {
    const app = useContext(AppContext)
    return <PagedSearchTable call={UserController.activeUsers} keyExtractor={l => l.id} columns={[
        {
            header: app.word('id'),
            row: (item) => item.id
        },
        {
            header: app.word('name'),
            row: (item) => item.name
        },
        {
            header: app.word('version'),
            row: (item) => item.version
        },
        {
            header: app.word('email'),
            row: (item) => item.email
        }
    ]}/>
}

const Users: React.FC = () => {
    const app = useContext(AppContext)
    return <Tabs tabs={[
        {
            header: () => app.word('users'),
            key: 'users',
            content: <UsersList/>
        },
        {
            header: () => app.word('login_history'),
            key: 'login',
            content: <LoginHistory/>
        },
        {
            header: () => app.word('connected_users'),
            key: 'connected',
            content: <ConnectedUsers/>
        }
    ]}/>
}

export default Users
