import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../appContext'
import CropCultivar from '../controllers/CropCultivar'
import CultivarDisplay from '../controllers/CultivarDisplay'
import UserResponse from '../controllers/UserResponse'
import CropController from '../controllers/CropController'
import UserController from '../controllers/UserController'
import { AutoCompleteNullable } from '../components/AutoComplete'
import CheckBox from '../components/CheckBox'
import InputNullable from '../components/InputNullable'
import { DatePickerNullable, DatePickerType } from '../components/DatePicker'
import ProgramFilter from '../controllers/ProgramFilter'
import { XMarkIcon } from '@heroicons/react/24/outline'
function programFilter (): ProgramFilter {
    return {
        cropId: null,
        cultivarId: null,
        createdByUserId: null,
        dateModified: null,
        approved: true,
        dateCreated: null,
        farmName: null,
        programName: null,
    }
}


const ProgramSearchFilter: React.FC<{
    onSearch: (filter: ProgramFilter) => void;
    onClose: () => void;
}> = (props) => {
    const context = useContext(AppContext)

    const [crops, setCrops] = useState<CropCultivar[]>([])
    const [cultivar, setCultivars] = useState<CultivarDisplay[]>([])
    const [users, setUsers] = useState<UserResponse[]>([])

    const [filter, setFilter] = useState<ProgramFilter>(programFilter())

    useEffect(() => {

        Promise.all([CropController.withCultivars(), UserController.all()])
            .then(([crops, users]) => {
                setCrops(crops)
                setUsers(users)
            })

    }, [])

    return <div className="text-white p-4 shadow-md  absolute z-50 bg-white mt-1">
        
        <div className="text-right">
            <XMarkIcon className="cursor-pointer inline-block w-6 h-6 text-gray-400" onClick={() => props.onClose()}/>
        </div>
        
        <table className="w-full text-gray-600">
            <tr>
                <td className="pl-4 pr-8">Crop</td>
                <td className="py-1">
                    <AutoCompleteNullable placeHolderKey="any" options={crops} textFunc={c => c.name} valueFunc={c => c.id} value={filter.cropId} onChange={(cropId, crop) => {
                        setCultivars(crop?.cultivars ?? [])
                        setFilter({
                            ...filter,
                            cropId: cropId,
                            cultivarId: null
                        })
                    }}/>
                </td>
            </tr>
            <tr>
                <td className="pl-4 pr-8">Cultivar</td>
                <td className="py-1">
                    <AutoCompleteNullable placeHolderKey="any" options={cultivar} textFunc={c => c.name} valueFunc={c => c.id} value={filter.cultivarId} onChange={(cultivarId) => {
                        setFilter({
                            ...filter,
                            cultivarId: cultivarId
                        })
                    }}/>
                </td>
            </tr>
            <tr>
                <td className="pl-4 pr-8">Created by</td>
                <td className="py-1">
                    <AutoCompleteNullable placeHolderKey="any" options={users} textFunc={c => c.name} valueFunc={c => c.id} value={filter.createdByUserId} onChange={userId => {
                        setFilter({
                            ...filter,
                            createdByUserId: userId
                        })
                    }}/>
                </td>
            </tr>
            <tr>
                <td className="pl-4 pr-8">Program name</td>
                <td className="py-1">
                    <InputNullable value={filter.programName} change={value => setFilter({
                        ...filter,
                        programName: value
                    })} placeHolder="Enter a terms that matches part of the program name"/>
                </td>
            </tr>
            <tr>
                <td className="pl-4 pr-8">Farm name</td>
                <td className="py-1">
                    <InputNullable value={filter.farmName} change={value => setFilter({
                        ...filter,
                        farmName: value
                    })} placeHolder="Enter a terms that matches part of the farm name"/>
                </td>
            </tr>
            <tr>
                <td className="pl-4 pr-8">Date created</td>
                <td className="py-1">
                    <DatePickerNullable value={filter.dateCreated} setValue={value => setFilter({
                        ...filter,
                        dateCreated: value
                    })} type={DatePickerType.Date}/>
                </td>
            </tr>
            <tr>
                <td className="pl-4 pr-8">Date modified</td>
                <td className="py-1">
                    <DatePickerNullable value={filter.dateModified} setValue={value => setFilter({
                        ...filter,
                        dateModified: value
                    })} type={DatePickerType.Date}/>
                </td>
            </tr>
            <tr>
                <td className="px-4">Approved</td>
                <td>
                    <CheckBox value={filter.approved} onChange={v => setFilter({
                        ...filter,
                        approved: v
                    })}/>
                </td>
            </tr>
        </table>

        <div className="text-right mt-2">
            <div className="inline-block px-4 cursor-pointer text-gray-600" onClick={() => {
                setFilter(programFilter())
                setCultivars([])
            }}>{context.word('reset')}</div>
            <div className="btn bg-primary-500" onClick={() => props.onSearch(filter)}>{context.word('search')}</div>
        </div>

    </div>
}

export default ProgramSearchFilter