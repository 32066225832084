import React, { useContext } from 'react'
import appContext from '../../appContext'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import MeasureValue from '../../controllers/MeasureValue'
import NullableMeasureValue from '../../controllers/NullableMeasureValue'
import { CustomUnit, measureValueCustomUnit, measureValueProductUnit } from './CustomUnits'
import { getValue, getValueNullable, measureValue } from './Helpers'
import Number from '../../components/Number'
import NumberNullable from '../../components/NumberNullable'
import { getMeasureValue } from './Functions'
import ProductUnit from '../../controllers/ProductUnit'
import { conversions, ConvertData, oppositeUnit } from './units'

export function parseMeasureValueNullable(value: string, unit: CustomUnit, system: MeasurementUnit): NullableMeasureValue {
    if (value.trim() === '') { return { imperial: null, metric: null } }

    const val = parseFloat(value)
    if (isNaN(val) || !isFinite(val)) { return { imperial: null, metric: null } }

    return measureValueCustomUnit(val, unit, system)
}


const MeasureValueField: React.FC<{
    value: MeasureValue;
    unit: CustomUnit | ProductUnit;
    update: (value: MeasureValue) => void;
    error?: boolean;
}> = (props) => {
    const app = useContext(appContext)

    function setValue(num: number) {

        if (typeof props.unit == 'string') {
            props.update(measureValueProductUnit(num, props.unit, oppositeUnit(props.unit), app.initial.system));
        } else
            props.update(measureValueCustomUnit(num, props.unit, app.initial.system))
    }

    return <Number error={props.error} value={getValue(app.initial.system, props.value)} change={n => setValue(n)} />
}

const NullableMeasureValueField: React.FC<{
    value: NullableMeasureValue;
    unit: CustomUnit;
    update: (value: NullableMeasureValue) => void;
    error?: boolean;
}> = (props) => {
    const app = useContext(appContext)

    function setValue(num: number | null) {
        if (num === null) {
            props.update({
                metric: null,
                imperial: null
            })
            return
        }
        props.update(measureValueCustomUnit(num, props.unit, app.initial.system))
    }

    return <NumberNullable error={props.error} value={getValueNullable(app.initial.system, props.value)} change={n => setValue(n)} />
}

export { MeasureValueField, NullableMeasureValueField }
