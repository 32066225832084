/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import RegionResponseByLanguage from "./RegionResponseByLanguage";
import PaginationResponse from "./PaginationResponse";
import RegionResponse from "./RegionResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import Region from "./Region";

const urls = {
    index(): string {
        return "/region/index";
    }
}

export default {
    urls,
    index(): Promise<RegionResponseByLanguage[]> {
        return contentOnly(Axios.get("/region/index", defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<RegionResponse>> {
        return contentOnly(Axios.post("/region/paged", request, defaultConfig));
    },
    add(request: Region): Promise<boolean> {
        return contentOnly(Axios.post("/region/add", request, defaultConfig));
    }
}
