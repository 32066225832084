import React, { useContext } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/outline'
import AppContext from '../appContext'

const Success: React.FC<{
    title?: string;
    text?: string;
    onClick?: () => void;
}> = (props) => {
    const context = useContext(AppContext)

    return (
        <div className="flex w-full items-center">
            <CheckCircleIcon className="h-12 w-12 text-primary m-2" aria-hidden="true"/>
            <div className="px-1 ">
                <p className="font-medium text-gray-900 m-3">{props.title ?? `${context.word('success')}`}</p>
                <p className="mt-1 text-gray-500 m-3">{props.text}</p>
            </div>
        </div>
    )
}

export default Success
