/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import SimpleCoordinate from "./SimpleCoordinate";

const urls = {
    boundary(id: number): string {
        return queryUrl("/land/boundary", {id});
    }
}

export default {
    urls,
    boundary(id: number): Promise<SimpleCoordinate[]> {
        return contentOnly(Axios.get(queryUrl("/land/boundary", {id}), defaultConfig));
    }
}
