import React, { Key, useState } from 'react'
import { classNames } from '../wrapper'
import { ChevronLeftIcon, ChevronRightIcon, Bars3Icon } from '@heroicons/react/24/outline'

export interface TabProps {
    header: (selected: boolean) => React.ReactNode;
    content: React.ReactNode;
    key: Key;

    // fires when this lab leaves focus
    onBlur?: () => void;
    // fires when this tab gets focus
    onFocus?: () => void;
    // any data assocaited with this tab to be used in custom events
    tag?: any;
}

export interface TabsProps {
    tabs: TabProps[];
    // fires when a tab leaves focus
    onBlur?: (index: number) => void;
    // fires when a tab is clicked
    onFocus?: (index: number) => void;

    renderTab?: (index: number, tab: TabProps, click: () => void, active: boolean) => React.ReactNode
}

const Tabs: React.FC<TabsProps> = (props) => {
    const [activeIndex, setActiveIndex] = useState(0)

    function tabClick (toIndex: number) {
        // first individual tab blur
        const tabBlur = props.tabs[activeIndex]
        if (tabBlur && tabBlur.onBlur) { tabBlur.onBlur() }

        // then individual tab focus
        const tabFocus = props.tabs[toIndex]
        if (tabFocus && tabFocus.onFocus) { tabFocus.onFocus() }

        // then fire events for tabs
        // fire the blur for the previous tab
        if (props.onBlur) { props.onBlur(activeIndex) }

        // and the focus for the current tab
        setActiveIndex(toIndex)
        if (props.onFocus) { props.onFocus(toIndex) }
    }

    return (
        <div>

            <div className="flex w-full max-w-full items-center bg-gray-300 relative">
                <ChevronLeftIcon className="h-5" onClick={() => setActiveIndex(prev => Math.max(prev - 1, 0))}/>
                <div className="bg-red-100 flex-1 overflow-scroll">
                    <ul className="bg-gray-300 rounded-t whitespace-nowrap w-full overflow-x-scroll" style={{ scrollBehavior: 'smooth' }}>

                        {props.tabs.map((tab, index) => props.renderTab
                            ? props.renderTab(index, tab, () => tabClick(index), activeIndex === index)
                            : (<li onClick={() => tabClick(index)} className="inline-block" key={tab.key}>
                                <div
                                    className={classNames('inline-block p-1 px-2 m-1 cursor-pointer text-sm', activeIndex === index ? 'bg-primary rounded-lg text-white' : 'text-black')}>{tab.header(false)}
                                </div>
                            </li>)
                        )}
                    </ul>
                </div>
                <div className="ml-auto pl-2">
                    <Bars3Icon className="h-5"/>
                    <ul className="absolute right-0 mt-4 bg-gray-200 z-2 p-2 hidden">
                        {props.tabs.map((tab) =>
                            <li key={tab.key} className="cursor-pointer block py-1">{tab.header(false)}</li>
                        )}
                    </ul>
                </div>
                <ChevronRightIcon className="h-5" onClick={() =>  setActiveIndex(prev => Math.min(prev + 1, props.tabs.length -1))}/>
            </div>

            <div>
                {activeIndex >= 0 && activeIndex < props.tabs.length && props.tabs[activeIndex] ? props.tabs[activeIndex]!.content : null}
            </div>
        </div>
    )
}

export default Tabs
