import MeasurementUnit from '../../controllers/MeasurementUnit'
import Papa from 'papaparse'

export type CSVPopulate<T> = (value: string, entry: T, system: MeasurementUnit) => void;

function convertKeys<T>(rec: Record<string, T>, keyConverter: (key: string) => string): Record<string, T> {
    return Object.entries(rec)
        .reduce<Record<string, T>>((acc, [k, v]) => ({...acc, [keyConverter(k)]: v}), {});
}

export function parseCSVFile<T> (text: string, factory: (index: number) => T, system: MeasurementUnit, csvRecords: Record<string, CSVPopulate<T>>): T[] {
    const results = Papa.parse<string[]>(text, {header: false});
    // // Example (header: false)
    // [
    //     ["Column 1", "Column 2"],
    //     ["foo", "bar"],
    //     ["abc", "def"]
    // ]
    
    if (results.data.length <= 1) { return [] }
    const headers = results.data[0]!;

    // convert all keys to lowercase and trimmed
    const headerLookup = convertKeys(csvRecords, k => k.toLowerCase().trim());
    
    return results.data.slice(1).map((row, index) => {
        const entry = factory(index)

        for (let column = 0; column < row.length; column++) {
            const header = headers[column]?.trim().toLowerCase()
            if (!header) { continue }
            
            const headerFunc = headerLookup[header]
            if (!headerFunc) { continue }

            headerFunc(row[column]!, entry, system)
        }
        return entry
    })
}
