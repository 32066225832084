import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import React, {useContext, useRef, useState} from 'react'
import PagedSearchTable, { PagedTableFunctions } from '../../components/PagedSearchTable'
import PaginationProductLookupRequest from '../../controllers/PaginationProductLookupRequest'
import ProductController from '../../controllers/ProductController'
import PaginationResponse from '../../controllers/PaginationResponse'
import CheckBox from '../../components/CheckBox'
import SelectNumberNullable from '../../components/SelectNumberNullable'
import { useAjaxData } from '../../wrapper'
import RegionController from '../../controllers/RegionController'
import RegionResponseByLanguage from '../../controllers/RegionResponseByLanguage'
import AppContext from '../../appContext'
import ProgramController from "../../controllers/ProgramController";

interface ProductLookupProps {
    productSelected: (product: ProductLookUpResponse, applyToAll: boolean) => void;
    call?: (request: PaginationProductLookupRequest) => PaginationResponse<ProductLookUpResponse>;
    showRegion?: boolean;
    selectedRegionId: number | null;
    setSelectedRegionId: (id: number | null) => void;
}

const ProductLookup: React.FC<ProductLookupProps> = (props) => {
    const context = useContext(AppContext)
    const [apply, setApply] = useState(false)
    const regionRef = useRef<number | null>(props.selectedRegionId)
    const tableRef = useRef<PagedTableFunctions<ProductLookUpResponse>>()

    const [regions, setRegions] = useState<RegionResponseByLanguage[]>([])
    useAjaxData(() => ProgramController.userRegionsByLanguage(), setRegions)

    return (
        <div className="p-2">
            <PagedSearchTable<ProductLookUpResponse, PaginationProductLookupRequest>
                instantSearch={true}
                topSlot={props?.showRegion ?? true
                    ? (
                        <div className="flex items-center border-b">
                            <div className="p-2 flex">
                                <CheckBox value={apply} onChange={v => setApply(v)} renderLabel={true}>
                                    Apply to all blocks?
                                </CheckBox>
                            </div>

                            <div className="ml-auto">
                                <span>Region: </span>
                                <div className="inline-block">
                                    <SelectNumberNullable 
                                        options={regions} 
                                        textFunc={o => o.name} 
                                        valueFunc={o => o.id} 
                                        value={props.selectedRegionId} 
                                        onChange={v => {
                                            props.setSelectedRegionId(v)
                                            regionRef.current = v
                                            tableRef.current?.refresh()
                                        }}/>
                                </div>
                            </div>

                        </div>

                    )
                    : null}
                call={props.call ?? ProgramController.productsLookupPaged}
                buildSearch={(base) => ({
                    ...base,
                    regionId: regionRef.current
                })}
                rowClick={product => props.productSelected(product, apply)}
                columns={[
                    {
                        header: context.word('supplier'),
                        row: (item) => item.supplier
                    },
                    {
                        header: context.word('name'),
                        row: (item) => item.nameKey !== null ? context.word(item.nameKey) : item.name
                    },
                    {
                        header: context.word('type'),
                        row: (item) => (item.solid && item.liquid) ? `${context.word('solid')} & ${context.word('liquid')}` : item.solid ? context.word('solid') : context.word('liquid')
                    },
                    {
                        header: context.word('region(s)'),
                        row: (item) => item.regionsKeys.map(rk => context.word(rk)).join(', ')
                    }
                ]}
                keyExtractor={i => i.id}
                componentRef={tableRef}
            />
        </div>)
}

export default ProductLookup
