import React, { Key, MutableRefObject, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react'
import PaginationRequestSearch from '../controllers/PaginationRequestSearch'
import PaginationResponse from '../controllers/PaginationResponse'
import Pagination from './Pagination'
import AppContext from '../appContext'
import { bind, classNames, onEnter, switcher } from '../wrapper'
import {ExclamationTriangleIcon, MagnifyingGlassIcon} from "@heroicons/react/24/outline";

export interface PagedTableFunctions<T> {
    refresh: () => void;
    updateData: (update: (prev: T[]) => T[]) => void;
    updateRow: (predicate: ((row: T) => boolean), setRow: (row: T) => void) => void;
    focus: () => void;
}

export interface TableColumn<TResponse> {
    header: React.ReactNode;
    row: (item: TResponse, index: number) => React.ReactNode;
    key?: Key;
}

interface PagedTableProps<TResponse, TRequest extends PaginationRequestSearch> {
    call: ((request: TRequest) => Promise<PaginationResponse<TResponse>>) | ((request: TRequest) => PaginationResponse<TResponse>);
    buildSearch?: (base: PaginationRequestSearch) => TRequest;
    topSlot?: React.ReactNode;
    inputSlot?: React.ReactNode;
    searchSlot?: React.ReactNode;
    componentRef?: MutableRefObject<PagedTableFunctions<TResponse> | undefined>;
    keyExtractor: (item: TResponse) => number;
    columns: TableColumn<TResponse>[],
    rowClick?: (item: TResponse) => void,
    
    // automatic search while typing with a debounce period
    instantSearch?: boolean;
}

interface PagingData {
    page: number;
    rowsPerPage: number;
    search: string;
}

enum TableStatus { loaded, loading, error}

const PagedSearchTable = <TResponse extends object, TRequest extends PaginationRequestSearch = PaginationRequestSearch>(
    {
        call,
        keyExtractor,
        columns,
        rowClick,
        buildSearch,
        topSlot,
        componentRef,
        instantSearch,
        inputSlot,
        searchSlot
    }: PagedTableProps<TResponse, TRequest>) => {
    const [data, setData] = useState<PaginationResponse<TResponse>>({
        items: [],
        total: 0
    })

    const [paging, setPaging] = useState<PagingData>({
        page: 0,
        rowsPerPage: 20,
        search: ''
    })

    const [search, setSearch] = useState('')
    const [tableState, setTableState] = useState<TableStatus>(TableStatus.loading)
    const [focusVal, setFocusVal] = useState(0)

    function pagingDate (page: number | null, rowsPerPage: number | null, search: string | null): PagingData {
        return {
            search: search ?? paging.search,
            rowsPerPage: rowsPerPage ?? paging.rowsPerPage,
            page: page ?? paging.page
        } as PagingData
    }
    function updatePage (set: PagingData) {
        setTableState(TableStatus.loading)
        setPaging(set)
        const request: PaginationRequestSearch = {
            sortBy: '',
            search: set.search,
            rowsPerPage: set.rowsPerPage,
            page: set.page,
            ascending: false
        }
        // provide for custom search request object that extends PaginationRequestSearch
        const result = call(buildSearch ? buildSearch(request) : request as TRequest)
        if (result instanceof Promise) {
            result.then(res => {
                setData(res)
                setTableState(TableStatus.loaded)
            }).catch(() => {
                setTableState(TableStatus.error)
            })
        } else {
            setData(result)
            setTableState(TableStatus.loaded)
        }
    }
    
    function doSearch(value: string) {
        updatePage(pagingDate(0, null, value))
    }

    useImperativeHandle(componentRef, () => ({
        refresh: () => doSearch(search),
        updateData: (update: (prev: TResponse[]) => TResponse[]) => {
            const newItems = update(data.items)
            setData({ ...data, items: newItems })
        },
        updateRow: (predicate: ((row: TResponse) => boolean), setRow: (row: TResponse) => void) => {
            const row = data.items.find(predicate)
            if (row) {
                setRow(row)
                setData({ ...data, items: data.items })
            }
        },
        focus
    }))

    const input = useRef<HTMLInputElement>(null)
    const app = useContext(AppContext)

    // Rerun the useEffect to focus on input
    function focus () {
        setFocusVal(Math.random())
    }
    useEffect(() => {
        // fire and focus on focus function.
        input.current?.focus()
    }, [focusVal])

    useEffect(() => {
        // useEffect is fired after initial render and setRef
        updatePage(paging)
    }, [])

    function handleRowClick (row: TResponse) {
        if (rowClick) { rowClick(row) }
    }
    
    function handleSearch(event: React.FormEvent<HTMLInputElement>) {
        const value = event.currentTarget.value
        setSearch(value)
        doSearch(value)
    }

    return (
        <div className="w-full">

            <div className="rounded-md border py-1 bg-white relative">
                {topSlot}
                {
                    switcher(tableState, c => c
                        .case(s => s === TableStatus.loaded || s === TableStatus.loading, () =>
                            <>
                                {tableState === TableStatus.loading
                                    ? <div
                                        className="absolute inset-0 bg-overlay-200 flex justify-center items-center">{app.word('loading')}...</div>
                                    : null}
                                <div className="flex w-full bg-white items-center">
                                    <div className="px-2 text-gray-400">
                                        <MagnifyingGlassIcon height={24}/>
                                    </div>
                                     <div className="w-full">
                                        <input ref={input} type="text" className="w-full outline-none"
                                            onKeyUp={onEnter(handleSearch)} onInput={event => instantSearch ? handleSearch(event) : setSearch(event.currentTarget.value)}
                                        />
                                    {inputSlot}
                                    </div>
                                    {searchSlot}
                                    <div className="btn bg-primary" onClick={() => doSearch(search)}>{app.word('search')}</div>
                                </div>

                                <div className="w-full overflow-x-scroll">
                                    <table className="min-w-full divide-y divide-gray-300 w-full">
                                        <thead className="bg-gray-50">
                                            <tr>
                                                {columns.map((c, i) => (
                                                    <th scope="col"
                                                        className="px-2 py-2 text-left text-sm font-semibold text-gray-900"
                                                        key={i}>{c.header}</th>))}
                                            </tr>
                                        </thead>
                                        <tbody className="bg-white">
                                            {data.items.map((row, index) => (
                                                <tr key={keyExtractor(row)}
                                                    className={classNames(index % 2 === 0 ? '' : 'bg-gray-50', 'group hover:bg-gray-200 cursor-pointer')}
                                                    onClick={() => handleRowClick(row)}>
                                                    {columns.map((c, i) => (
                                                        <td className="whitespace-nowrap px-1 py-2 text-sm text-gray-500"
                                                            key={i}>{c.row(row, index)}</td>))}
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>

                                </div>
                                <Pagination total={data.total} rowsPerPage={paging.rowsPerPage} page={paging.page}
                                    gotoPage={(page) => updatePage(pagingDate(page, null, null))}
                                    setRowsPerPage={rows => updatePage(pagingDate(0, rows, null))}/>
                            </>
                        )
                        .case(TableStatus.error, () => <div className="flex w-full m-4 justify-center items-center">
                            <div className="px-2"><ExclamationTriangleIcon className="w-10 h-10 text-red-400"/></div>
                            <div className="text-gray-600">{app.word('error_loading_table_data')}</div>
                        </div>)
                    )
                }

            </div>
        </div>
    )
}

export default PagedSearchTable
