import React, { createContext, ReactNode } from 'react'
import AppState from './controllers/AppState'
import MeasurementUnit from './controllers/MeasurementUnit'
import PermissionEnum from './controllers/PermissionEnum'

export interface DialogData {
    title: string,
    body: React.ReactNode,
    onClose?: () => void,
    mounted?: (el: HTMLDivElement) => void;
}

export interface AppContextData {
    readonly isLoading: boolean,
    // return callback to hide the loader again
    showLoader: () => () => void,
    readonly initial: Readonly<AppState>;

    word (key: string, ...params: number[]): string;

    wordUnit (metricKey: string, imperialKey: string) : string

    showSnack (node: ReactNode): () => void;

    hasPermission(permission: PermissionEnum): boolean;
}

// This is the default context required by react.
export default createContext<AppContextData>({
    hasPermission (permission: PermissionEnum): boolean {
        return false
    },
    isLoading: false,
    showLoader () {
        return () => {
        }
    },
    word (key: string): string {
        return key
    },

    wordUnit (metricKey: string, imperialKey: string): string {
        return ''
    },

    initial: {
        language: {},
        languageId: 0,
        version: '',
        loggedIn: false,
        system: MeasurementUnit.IMPERIAL,
        permissions: [],
        superUser: false,
        userDisplay: '',
        message: '',
        languages: [],
        regions: [],
        isDev: false,
        userId: 0
    },
    showSnack () {
        return () => {
        }
    }
})
