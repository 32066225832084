// words for months and weeks
export const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];

export const weeks = new Array(52).fill(0).map((_, i) => 'week_' + (i + 1));

function match(name: string, arrayToMatch: string[]): string {
    const cmp = name.toLowerCase().trim();
    const best = arrayToMatch.reduce((acc, m, i) => {
        const dist = levenshteinDistance(cmp, m);
        if (acc.index == -1 || (dist < acc.cost && dist < m.length)) {
            return {
                index: i,
                cost: dist
            }
        }
        return acc;
    }, {index: -1, cost: 0});

    if (best.index == -1)
        return '';

    return arrayToMatch[best.index]!;
}

export function matchMonth(name: string): string {
    return match(name, months);
}

export function matchWeek(name: string): string {
    return match(name, weeks);
}

function levenshteinDistance(str1: string, str2: string): number {
    const len1: number = str1.length;
    const len2: number = str2.length;

    // Create a 2D array to store distances between substrings
    const dp: number[][] = Array.from({length: len1 + 1}, () => Array(len2 + 1).fill(0));

    // Initialize the distance for empty substrings
    for (let i = 0; i <= len1; i++) dp[i]![0] = i;
    for (let j = 0; j <= len2; j++) dp[0]![j] = j;

    // Calculate Levenshtein distance
    for (let i = 1; i <= len1; i++) {
        for (let j = 1; j <= len2; j++) {
            const cost = str1[i - 1] === str2[j - 1] ? 0 : 1;
            dp[i]![j] = Math.min(
                dp[i - 1]![j]! + 1,     // Deletion
                dp[i]![j - 1]! + 1,     // Insertion
                dp[i - 1]![j - 1]! + cost // Substitution
            );
        }
    }

    // The final cell contains the Levenshtein distance
    return dp[len1]![len2]!;
}

