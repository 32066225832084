import React, { useContext, useState } from 'react'
import AuthController from './controllers/AuthController'
import './login.css'
import AppContext from './appContext'
import { bind, onEnter, wrapLoader } from './wrapper'
import { useSearchParams } from 'react-router-dom'
import AppState from './controllers/AppState'
import { useValidation } from './validation'

const Login: React.FC<{ setInitial: (state: AppState) => void }> = (props) => {
    const app = useContext(AppContext)

    // combine multiple state into one?
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [error, setError] = useState('')
    const [searchParams, _] = useSearchParams()

    const validation = useValidation({
        passwordRequired: () => password.length > 0,
        confirmPasswordRequired: () => confirmPassword === password
    })

    function Login () {
        const token = searchParams.get('token')

        if (!validation.validate()) { return }

        wrapLoader(app, AuthController.resetPassword({
            password,
            token: token ?? ''
        }), data => {
            if (data) {
                window.location.href = '/'
            } else {
                setError('token_expired')
            }
        })
    }

    return (
        <div className="h-screen back w-full flex justify-center items-center">
            <div
                className="mx-auto md:mx-8 p-8 bg-white border-t-4 border-primary rounded-lg text-center shadow-xl flex items-center flex-col md:flex-row">
                <div className="md:border-r md:pr-4 text-right">
                    <img className="w-40 md:w-full" src="logo.png" alt=""/>
                    <div className="text-xs text-gray-600">{app.word('agritechnovation_fertiliser')} <span
                        className="bg-primary-600 text-white rounded-md px-1">v{app.initial.version}</span></div>
                </div>
                <div className="pl-4 text-left" onKeyUp={onEnter(Login)}>
                    <div className="text-xs text-gray-800 p-1 text-left label-color">{app.word('password')}</div>
                    <input className="p-1 text-left bg-gray-200 border rounded"
                        type="password" {...bind(password, setPassword)}/>
                    <div className="text-red-400">{!validation.rules.passwordRequired && app.word('password_is_required')}</div>

                    <div className="text-xs text-gray-800 p-1 text-left label-color">{app.word('confirm_password')}</div>
                    <input className="p-1 text-left bg-gray-200 border rounded"
                        type="password" {...bind(confirmPassword, setConfirmPassword)}/>
                    <div className="text-red-400">{!validation.rules.confirmPasswordRequired && app.word('password_does_not_match')}</div>

                    <div className="text-center bg-primary my-2 rounded py-1 text-white cursor-pointer shadow-lg"
                        onClick={Login}>{app.word('reset_password')}
                    </div>
                    <div className="w-48 text-sm text-red-500">{error}</div>
                </div>

            </div>
        </div>
    )
}

export default Login
