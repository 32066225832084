/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import Currency from "./Currency";
import IdRequest from "./IdRequest";
import PaginationResponse from "./PaginationResponse";
import CurrencyPagedResponse from "./CurrencyPagedResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";

export default {
    index(): Promise<Currency[]> {
        return contentOnly(Axios.post("/currency/index", defaultConfig));
    },
    getCurrency(request: IdRequest): Promise<Currency> {
        return contentOnly(Axios.post("/currency/getcurrency", request, defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<CurrencyPagedResponse>> {
        return contentOnly(Axios.post("/currency/paged", request, defaultConfig));
    },
    upsert(request: CurrencyPagedResponse): Promise<boolean> {
        return contentOnly(Axios.post("/currency/upsert", request, defaultConfig));
    },
    remove(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/currency/remove", request, defaultConfig));
    }
}
