import React, { useContext, useState } from 'react'
import AppContext from '../../appContext'
import { useValidation } from '../../validation'
import { arrayPush, arrayRemoveItem } from '../../immutableState'
import { getValue, measureValue } from './Helpers'
import { bind, onEnter } from '../../wrapper'
import CheckBox from '../../components/CheckBox'
import { customUnits, unitCustom } from './CustomUnits'
import { PlayFieldCalcData } from './playFieldState'
import MeasureValue from '../../controllers/MeasureValue'
import { TankToAdd } from './fertigationCalcs'
import { MeasureValueField } from './MeasureValueField'
import Input from '../../components/Input'

const AddPumpHouse: React.FC<{
    lands: PlayFieldCalcData[];
    onSave: (name: string, blocks: number[], tanks: TankToAdd[]) => void;
}> = (props) => {
    const {
        word,
        initial: { system }
    } = useContext(AppContext)
    const app = useContext(AppContext)

    const [name, setName] = useState('')
    const [tankName, setTankName] = useState('')

    const [size, setSize] = useState<MeasureValue>(measureValue(0, 0))
    const [tanks, setTanks] = useState<TankToAdd[]>([])
    const [landSelected, setLandSelected] = useState<number[]>([])

    const validation = useValidation({
        nameRequired: name.length > 0,
        tankFilled: tanks.length > 0
    })

    function addTank () {
        setTanks(arrayPush(tanks, {
            name: tankName,
            size
        }))
    }

    function removeTank (tank: TankToAdd) {
        setTanks(arrayRemoveItem(tanks, tank))
    }

    function handleSelected (state: boolean, landId: number) {
        if (state) {
            setLandSelected(arrayPush(landSelected, landId))
        } else {
            setLandSelected(arrayRemoveItem(landSelected, landId))
        }
    }

    if (props.lands.length === 0) {
        return <div className="p-4">{app.word('no') + ' ' + app.wordUnit('lands', 'ranches') + ' ' + app.word('available')}</div>
    }

    function onSave (): void {
        if (validation.validate()) {
            props.onSave(name, landSelected, tanks)
        }
    }


    return <div className="max-w-lg p-2" onKeyUp={onEnter(() => onSave())}>

        <div>{app.word('name')}</div>
        <input type="text" className="input" {...bind(name, setName)}/>
        <div className="text-red-400">{!validation.rules.nameRequired && 'Name is required'}</div>
        <div className="label py-1">{app.word('blocks')} / {app.word('fields')}</div>
        <div className="flex flex-wrap">
            {props.lands.map(l => <label className="p-2 m-1 rounded-md w-28 bg-gray-100 cursor-pointer" key={l.landId}>
                <div className="text-center">
                    <CheckBox value={landSelected.includes(l.landId)} onChange={state => handleSelected(state, l.landId)}/>
                </div>
                <div className="text-center">
                    {l.viewBlock.blockName}
                </div>
            </label>)}
        </div>
        <div className="label py-1">{app.word('tanks')}</div>

        <div className="flex items-center mb-2">
            <Input value={tankName} change={setTankName}/>
            <div onKeyUp={e => e.key === "Enter" ? addTank() : null}>
                <MeasureValueField value={size} unit={customUnits.liter_gallon} update={setSize}/>
            </div>
            <div className="btn-sm bg-primary" onClick={() => addTank()}>+</div>
        </div>
        <div className="text-red-400 text-left">{!validation.rules.tankFilled && 'A least one tank is required. Did you forget to click the add button?'}</div>

        <div className="flex justify-around flex-wrap">
            {tanks.map((tank, index) =>
                <div key={index}>
                    <div>{tank.name}</div>
                    <div>{getValue(system, tank.size).toFixed(2)} {unitCustom(system, customUnits.liter_gallon)}</div>
                    <img className="w-24 h-24 block" src="/icons/tank.svg" alt="tank"/>
                    <div className="text-center text-red-500 cursor-pointer" onClick={() => removeTank(tank)}>x</div>
                </div>
            )}
        </div>

        <div className="btn bg-primary" onClick={() => onSave()}>{word('save')}</div>

    </div>
}

export default AddPumpHouse
