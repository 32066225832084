/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import PaginationResponse from "./PaginationResponse";
import FarmPagedResponse from "./FarmPagedResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";

export default {
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<FarmPagedResponse>> {
        return contentOnly(Axios.post("/farm/paged", request, defaultConfig));
    }
}
