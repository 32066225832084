// The trailing comma in  <ObjectType, > is added due to constrains of the  .tsx file extension.
// or it could be declared as function DataTable<TData>(props: PropsWithChildren<..>)
import React, { Key, PropsWithChildren } from 'react'
import { TableColumn } from './PagedSearchTable'
import { classNames } from '../wrapper'

const DataTable = <TData, > (props: PropsWithChildren<{
    definitions: (TableColumn<TData>)[];
    data: TData[];
    keyExtractor: (item: TData) => Key;
    headerClass?: string;

    dataClass?: string;
    rotates?: boolean;

    rowClick?: (item: TData, index: number) => void
}>) => {
    const classes = `${props.headerClass} rotates`

    return props.rotates
        ?  <div className="overflow-y-auto">
            <table className="w-full border">
                <thead>
                    <tr>
                        {props.definitions.map((d, i) =>
                            <th key={d.key ?? i} className={classes}>{d.header}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((row, rowIndex) =>
                        <tr className="hover:bg-gray-200" key={props.keyExtractor(row)}
                            onClick={() => props.rowClick?.(row, rowIndex)}>
                            {props.definitions.map((col, colIndex) => <td key={col.key ?? colIndex}
                                className={props.dataClass}>{col.row(row, rowIndex)}</td>)}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
        : <div className="overflow-y-auto">
            <table className="w-full border">
                <thead>
                    <tr>
                        {props.definitions.map((d, i) => <th key={d.key ?? i} className={props.headerClass}>{d.header}</th>)}
                    </tr>
                </thead>
                <tbody>
                    {props.data.map((row, rowIndex) =>
                        <tr className="hover:bg-gray-200" key={props.keyExtractor(row)}
                            onClick={() => props.rowClick?.(row, rowIndex)}>
                            {props.definitions.map((col, colIndex) => <td key={col.key ?? colIndex}
                                className={classNames(props.dataClass ? props.dataClass : "", "border-l-2 border-b-2 border-gray-100")}>{col.row(row, rowIndex)}</td>)}
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
}

export { DataTable }
