import PagedSearchTable, {TableColumn, PagedTableFunctions} from '../components/PagedSearchTable'
import React, {DependencyList, useContext, useEffect, useMemo, useRef, useState} from 'react'
import Dialog from '../components/Dialog'
import {EditRow, EditTable, focusFirstInput} from '../components/Fields'
import LanguageController from '../controllers/LanguageController'
import Language from '../controllers/Language'
import LangResult from '../controllers/LangResult'
import AppContext from '../appContext'
import TextEntries from '../controllers/TextEntries'
import {useAjaxData, useMemoAjax} from '../wrapper'
import LanguageKey from '../controllers/LanguageKey'
import CheckBox from '../components/CheckBox'
import Tabs from '../components/Tabs'
import {buildSetter} from '../immutableState'
import {useValidation} from '../validation'
import Input from '../components/Input'
import {showSuccessOrFailed} from '../Snacks'
import Success from '../components/Success'

const emptyLanguage: Language = {
    id: 0, name: '', whatsappLanguageCode: ''
}

const emptyKey: LanguageKey = {
    key: '', frontend: false, id: 0
}

const Languages: React.FC = () => {
    const pagedTableRef = useRef<PagedTableFunctions<Language>>()
    const [show, setShow] = useState(false)
    const context = useContext(AppContext)
    const [upsertData, setUpsertData] = useState<Language>({
        id: 0, name: '', whatsappLanguageCode: ''
    })
    const setData = buildSetter(upsertData, setUpsertData)

    function showPopup(preset: Language) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertNewLanguageCallback(data: Language) {
        if (!validation.validate()) {
            return
        }
        showSuccessOrFailed(context, LanguageController.upsertLanguage({
            name: data.name,
            id: data.id,
            whatsappLanguageCode: data.whatsappLanguageCode
        })).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0,
        whatsappLanguageCode: () => upsertData.whatsappLanguageCode.length > 0
    })

    return (
        <>
            <Dialog mounted={focusFirstInput}
                    title={upsertData.id === 0 ? context.word('new_language') : context.word('edit_language')}
                    show={show}
                    setShow={setShow}
                    body={
                        <EditTable save={() => upsertNewLanguageCallback(upsertData)} discard={() => setShow(false)}
                                   saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                            {EditRow(
                                context.word('name'),
                                <Input value={upsertData.name} change={v => setData({name: v})}/>,
                                validation.rules.nameRequired,
                                context.word('name_required')
                            )}
                            {EditRow(
                                context.word('whatsapp_language_code'),
                                <Input value={upsertData.whatsappLanguageCode}
                                       change={v => setData({whatsappLanguageCode: v})}/>,
                                validation.rules.whatsappLanguageCode,
                                context.word('whatsapp_language_code_required')
                            )}
                        </EditTable>
                    }/>

            <div className="btn bg-primary m-2"
                 onClick={() => showPopup(emptyLanguage)}>{context.word('add_language')}</div>
            <PagedSearchTable<Language> componentRef={pagedTableRef} call={LanguageController.languages}
                                        keyExtractor={item => item.id} columns={[
                {
                    header: context.word('name'),
                    row: item => item.name
                },
                {
                    header: context.word('whatsapp_language_code'),
                    row: item => item.whatsappLanguageCode
                },
                {
                    header: context.word('actions'),
                    row: item => <a href="#" className="btn-sm bg-primary"
                                    onClick={() => showPopup(item)}>{context.word('edit')}</a>
                }
            ]}/>
        </>
    )
}

const LanguageKeys: React.FC = () => {
    const [show, setShow] = useState(false)
    const [languages, setLanguages] = useState<Language[]>([])
    const pageRef = useRef<PagedTableFunctions<LangResult>>()
    const context = useContext(AppContext)
    const [insertData, setInsertData] = useState<LanguageKey>({
        frontend: false, id: 0, key: ''
    })
    const setData = buildSetter(insertData, setInsertData)

    useAjaxData(() => LanguageController.index(), setLanguages)

    function updateFronted(item: LangResult, val: boolean) {
        pageRef.current?.updateRow(r => r.id === item.id, row => {
            row.frontEnd = val
        })
        showSuccessOrFailed(context, LanguageController.changeDisplay({keyId: item.id, frontend: val}))
            .then(() => {
                context.showSnack(<Success title={context.word('updated_frontend')}/>)
            })
    }

    function updateKey(item: LangResult) {
        const texts = Object.keys(item.texts)
        showSuccessOrFailed(context, LanguageController.upsertKeyText({
            keyId: item.id,
            entries: texts.map<TextEntries>(t => ({
                langId: parseInt(t),
                text: item.texts[parseInt(t)]!
            }))
        })).then(() => {
            pageRef.current?.refresh()
        })
    }
    
    const isUnique = useMemoAjax(false, () => LanguageController.uniqueKey(insertData), [insertData]);
    
    function updateText(item: LangResult, langId: number, value: string) {
        pageRef.current?.updateRow(r => r.id === item.id, row => {
            row.texts[langId] = value
        })
    }

    function insertNewKey(data: LanguageKey) {
        if (!validation.validate()) {
            return
        }
        showSuccessOrFailed(context, LanguageController.addNewkey({...data, key: data.key.trim()}))
            .then(() => {
                setShow(false)
                pageRef.current?.refresh()
            })
    }

    function showPopup(emptyKey: LanguageKey) {
        setInsertData(emptyKey)
        setShow(true)
    }

    const validation = useValidation({
        keyRequired: () => insertData.key.length > 0 && isUnique
    })
    
    return <>
        <Dialog show={show} setShow={setShow}
                title={insertData.id === 0 ? context.word('new_key') : context.word('edit_key')}
                mounted={focusFirstInput} body={
            <EditTable save={() => insertNewKey(insertData)} discard={() => setShow(false)}
                       saveWord={insertData.id === 0 ? 'insert' : 'update'}>
                {EditRow(
                    context.word('key'),
                    <Input value={insertData.key} change={v => setData({key: v})}/>,
                    validation.rules.keyRequired,
                    context.word('unique_key_required')
                )}
            </EditTable>
        }/>

        <div className="btn bg-primary m-2" onClick={() => showPopup(emptyKey)}>{context.word('add_key')}</div>
        <PagedSearchTable<LangResult> componentRef={pageRef} call={LanguageController.keys}
                                      keyExtractor={item => item.id}
                                      columns={[
                                          {
                                              header: context.word('key'),
                                              row: item => item.key
                                          },
                                          ...languages.map(l => ({
                                              header: l.name,
                                              row: item => <input className="input" value={item.texts[l.id]!}
                                                                  onKeyDown={e => e.key === 'Enter' ? updateKey(item) : null}
                                                                  onInput={e => {
                                                                      updateText(item, l.id, (e.target as HTMLInputElement).value)
                                                                  }}/>
                                          }) as TableColumn<LangResult>),

                                          {
                                              header: context.word('actions'),
                                              row: item => <a href="#" className="btn-sm bg-primary"
                                                              onClick={() => updateKey(item)}>{context.word('update')}</a>
                                          }
                                      ]}/>
    </>
}

const LanguageTabs: React.FC = () => {
    const app = useContext(AppContext)
    return <Tabs tabs={[
        {
            header: () => app.word('language'),
            key: 'Language',
            content: <Languages/>
        },
        {
            header: () => app.word('keys'),
            key: 'Keys',
            content: <LanguageKeys/>
        }
    ]}/>
}

export default LanguageTabs
