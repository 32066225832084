import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import CategoryController from '../controllers/CategoryController'
import CategoryResponse from '../controllers/CategoryResponse'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import AppContext from '../appContext'
import Category from '../controllers/Category'
import LanguageController from '../controllers/LanguageController'
import { AutoComplete } from '../components/AutoComplete'
import { useAjaxData } from '../wrapper'
import KeysLookup from '../controllers/KeysLookup'
import { useValidation } from '../validation'
import { buildSetter } from '../immutableState'
import { showSuccessOrFailed } from '../Snacks'

const emptyCategory: Category = {
    id: 0, keyId: 0
}

const Categories: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<CategoryResponse>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<Category>(emptyCategory)
    const setData = buildSetter(upsertData, setUpsertData)

    function showPopup (preset: Category) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertCallback (data: Category) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, CategoryController.add(data)).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    const [langOptions, setLangOptions] = useState<KeysLookup[]>([])
    useAjaxData(() => LanguageController.keysLookup(), setLangOptions)

    const validation = useValidation({
        keyRequired: () => upsertData.keyId > 0
    })

    return (
        <>
            <Dialog mounted={focusFirstInput} title={context.word('new_category')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('key'),
                            <AutoComplete options={langOptions} textFunc={t => t.text} valueFunc={t => t.keyId} value={upsertData.keyId} onChange={v => setData({ keyId: v })}/>,
                            validation.rules.keyRequired,
                            context.word('key_required')
                        )}
                    </EditTable>
                }/>

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyCategory)}>{context.word('new_category')}</div>
            <PagedSearchTable<CategoryResponse>
                componentRef={pagedTableRef}
                call={CategoryController.paged}
                columns={[
                    { header: context.word('key'), row: item => item.key },
                    { header: context.word('name'), row: item => item.name }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Categories
