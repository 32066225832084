import React from 'react'

interface EnumOptions<T> {
    value: T;
    onChange: (value: T) => void;
    options: Record<string, string>;
}

export const SelectEnum: React.FC<EnumOptions<string>> = (props) => {
    const entries = Object.entries(props.options)
    return <select value={props.value} className="input"
                   onChange={event => props.onChange(event.target.value)}>
        {entries.map(([text, value]) => <option key={value} value={value}>{text}</option>)}
    </select>
}

export const SelectEnumNullable: React.FC<EnumOptions<string | null>> = (props) => {
    const entries = Object.entries(props.options)
    return <select value={props.value ? props.value : ''} className="input"
                   onChange={event => props.onChange(!event.target.value ? event.target.value : null)}>
        {entries.map(([text, value]) => <option key={value} value={value}>{text}</option>)}
    </select>
}
