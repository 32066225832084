import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react'
import AuthController from './controllers/AuthController'
import './login.css'
import AppContext from './appContext'
import { bind, onEnter, wrapLoader } from './wrapper'
import { useLocation, useNavigate } from 'react-router-dom'
import AppState from './controllers/AppState'
import { useValidation } from './validation'
import { focusFirstInput } from './components/Fields'
import Dialog from './components/Dialog'

const ForgotPassword: React.FC<{ show: boolean, setShow: Dispatch<SetStateAction<boolean>> }> = (props) => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const forgot = () => {
        AuthController.forgotPassword({
            email
        }).then(data => {
            if (data) {
                setMessage('Please check your email.')
            } else {
                setMessage('Could not find email. Please try again.')
            }
        })
    }

    function cancel () {
        setEmail('')
        setMessage('')
        props.setShow(false)
    }

    return (
        <Dialog mounted={focusFirstInput} title="Forgotten your password?" show={props.show} setShow={props.setShow}
            body={
                message === ''
                    ? <>
                        <p className="p-2">Enter your details below and click send to receive <br/> instructions per email to set your
                            password.</p>
                        <div className="flex p-2 items-center">
                            <div>Email:</div>
                            <input type="text" className="input ml-2" {...bind(email, setEmail)}/>
                        </div>
                        <div className="flex justify-end p-2">
                            <button className="btn bg-red-500" onClick={cancel}>cancel</button>
                            <button className="btn bg-primary-500" onClick={forgot}>submit</button>
                        </div>
                    </>
                    : <>
                        <p className="p-2">{message}</p>
                        <div className="flex justify-end p-2">
                            <button className="btn bg-primary-500" onClick={cancel}>ok</button>
                        </div>
                    </>
            }
        />
    )
}

const Login: React.FC<{ setInitial: (state: AppState) => void }> = (props) => {
    const app = useContext(AppContext)
    const navigate = useNavigate()
    const [forgotShow, setForgotShow] = useState(false)

    useEffect(() => {
        if (app.initial.loggedIn) {
            navigate('/')
        }
    }, [app, navigate])

    // combine multiple state into one?
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState('')

    const location = useLocation()

    const validation = useValidation({
        usernameRequired: () => username.length > 0,
        passwordRequired: () => password.length > 0
    })

    function login () {
        if (!validation.validate()) { return }

        wrapLoader(app, AuthController.login({
            user: username,
            pass: password,
            rememberMe: false
        }), data => {
            props.setInitial(data)

            if (data.loggedIn) {
                // @ts-ignore
                const from = location.state?.from?.pathname || '/'
                navigate(from)
            } else {
                setError(data.message)
            }
        })
    }

    return (
        <div className="h-screen back w-full flex justify-center items-center">
            <div
                className="mx-auto md:mx-8 p-8 bg-white border-t-4 border-primary rounded-lg text-center shadow-xl flex items-center flex-col md:flex-row">
                <div className="md:border-r md:pr-4 text-right">
                    <img className="w-40 md:w-full" src="logo.png" alt=""/>
                    <div className="text-xs text-gray-600">Agritechnovation Fertiliser<span
                        className="bg-primary-600 text-white rounded-md px-1 mx-1">v{app.initial.version}</span></div>
                </div>
                <div className="pl-4 text-left" onKeyUp={onEnter(login)}>
                    <div className="text-xs text-gray-800 p-1 text-left label-color">Username</div>
                    <input className="p-1 text-left bg-gray-200 border rounded"
                        type="text"
                        autoFocus {...bind(username, setUsername)}/>

                    <div
                        className="text-red-400">{!validation.rules.usernameRequired && 'Username is required'}</div>

                    <div className="text-xs text-gray-800 p-1 text-left label-color">Password</div>
                    <input className="p-1 text-left bg-gray-200 border rounded"
                        type="password" {...bind(password, setPassword)}/>
                    <div
                        className="text-red-400">{!validation.rules.passwordRequired && 'Password is required'}</div>

                    <div className="text-center bg-primary my-2 rounded py-1 text-white cursor-pointer shadow-lg"
                        onClick={login}>LOGIN
                    </div>
                    <div className="w-48 text-sm text-red-500">{error}</div>
                    <a className="text-center cursor-pointer text-gray-800 text-sm" onClick={_ => setForgotShow(true)}>Forgot password?</a>
                </div>
            </div>
            <ForgotPassword setShow={setForgotShow} show={forgotShow}></ForgotPassword>
        </div>
    )
}

export default Login
