import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import ProgramController from '../controllers/ProgramController'
import React, { useContext, useRef } from 'react'
import { dateFormat } from '../date'
import { useNavigate } from 'react-router-dom'
import AppContext from '../appContext'
import PermissionEnum from '../controllers/PermissionEnum'
import TemplateListResult from '../controllers/TemplateListResult'
import Warning, { useWarningState } from './program/Warning'
import { showSuccessOrFailed } from '../Snacks'

const Templates: React.FC = () => {
    const navigate = useNavigate()
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<TemplateListResult>>()
    const warningState = useWarningState<number>(-1)

    function rowRemoveWarning (id: number) {
        warningState.show(`${context.word('removing')}?`, id)
    }

    function rowRemove (id: number) {
        showSuccessOrFailed(context, ProgramController.removeTemplate({ id }), 'successfully_deleted', 'failed_to_delete').then(() => {
            pagedTableRef.current?.refresh()
        })
    }

    function copyClicked (event: React.MouseEvent<HTMLSpanElement>, id: number) {
        navigate(`/templates/new?copy=${id}`)
        event.stopPropagation()
        event.preventDefault()
    }

    return <>
        <Warning state={warningState} onYes={rowRemove}/>
        <div className="btn bg-primary m-2" onClick={() => navigate('/templates/new')}>{context.word('new_template')}</div>
        <PagedSearchTable
            rowClick={row => navigate(`/templates/${row.id}`)}
            componentRef={pagedTableRef}
            call={ProgramController.templatesPaged} keyExtractor={t => t.id} columns={[
                {
                    header: context.word('name'),
                    row: t => t.name
                },
                {
                    header: context.word('updated_on'),
                    row: t => dateFormat(t.updateOn)
                },
                {
                    header: context.word('created_on'),
                    row: t => dateFormat(t.createdOn)
                },
                {
                    header: context.word('actions'),
                    row: t => <>
                        {context.hasPermission(PermissionEnum.ManageProgramsRemovePermission)
                            ? <span className="cursor-pointer underline py-2 pl-2 font-medium" onClick={event => {
                                event.stopPropagation()
                                rowRemoveWarning(t.id)
                            }}>{context.word('delete')}</span>
                            : null
                        }
                        <span>&nbsp;|&nbsp;</span>
                        <span onClick={event => copyClicked(event, t.id)} className="cursor-pointer py-2 pr-2 underline font-medium">{context.word('copy')}</span>
                    </>
                }
            ]}/>
    </>
}

export default Templates
