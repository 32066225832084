import MeasurementUnit from '../../controllers/MeasurementUnit';
import MeasureValue from '../../controllers/MeasureValue';
import ProductUnit from '../../controllers/ProductUnit';
import { getMeasureValue } from './Functions';
import { measureValue } from './Helpers';
import { conversions, ConvertData, convertKgPerHa } from './units'

// Custom units.
// Units and conversions outside the Product conversions.

interface CustomUnitSystem {
    display: string;
    invert: (value: number) => number;
}

// always set of two
export interface CustomUnit {
    metric: CustomUnitSystem;
    imperial: CustomUnitSystem;
}

const emptyConvertData: ConvertData = {
    sg: 1,
    size: { ha: 1, ac: 1 },
    trees: 1,
    water: { galAc: 1, lHa: 1 }
}


export function measureValueProductUnit(input: number | MeasureValue, unitMetric: ProductUnit, unitImperial: ProductUnit, system: MeasurementUnit, convertData?: ConvertData): MeasureValue {
    const num = typeof input == 'number' 
        ? input 
        : getMeasureValue(input, system);

    const convert = convertData ?? emptyConvertData;
    
    if (system == MeasurementUnit.METRIC)
        return  {
            metric: num,
            imperial: convertKgPerHa({value: num, unit: unitMetric},  unitImperial, convert).value
        }
    
        return  {
            imperial: num,
            metric: convertKgPerHa({value: num, unit: unitImperial},  unitMetric, convert).value
        }
}

export function measureValueCustomUnit(input: number | MeasureValue, unit: CustomUnit, system: MeasurementUnit): MeasureValue {
    
    const value = typeof input == 'number' ? input : getMeasureValue(input, system);

    if (system === MeasurementUnit.METRIC)
        return measureValue(value, unit.metric.invert(value))
    
    return measureValue(unit.imperial.invert(value), value)
}
export function unitCustom(system: MeasurementUnit, unit: CustomUnit | ProductUnit): string {

    if (typeof unit == 'string') {
        return system == MeasurementUnit.METRIC ?
            conversions[unit].display : 
            conversions[conversions[unit].opposite].display
    }

    if (system == MeasurementUnit.METRIC)
        return unit.metric.display

    return unit.imperial.display;
}


export const customUnits = {
    kgha_lbAc: {
        metric: {
            display: 'kg/ha',
            invert: val => val * 0.892179
        },
        imperial: {
            display: 'lb/ac',
            invert: val => val / 0.892179
        }
    } as CustomUnit,

    tonHa_tonAc: {
        metric: {
            display: 'ton/ha',
            invert: val => val * 0.44609
        },
        imperial: {
            display: 'ton/ac',
            invert: val => val / 0.44609
        }
    } as CustomUnit,
    
    binHa_binAc: {
        metric: {
            display: 'bin/ha',
            invert: val => val * 2.47105
        },
        imperial: {
            display: 'bin/ac',
            invert: val => val / 2.47105
        }
    } as CustomUnit,

    bushelsHa_bushelsAc: {
        metric: {
            display: 'bsh/ha',
            invert: val => val* 2.47105
        },
        imperial: {
            display: 'bsh/ac',
            invert: val => val/ 2.47105
        }
    } as CustomUnit,
    
    kgLand_lbLand: {
        metric: {
            display: 'kg/land',
            invert: val => val * 2.47105
        },
        imperial: {
            display: 'lb/ranch',
            invert: val => val / 2.47105
        }
    } as CustomUnit,
    mm_Feet: {
        metric: {
            display: 'mm',
            invert: val => val * 0.00328084
        },
        imperial: {
            display: 'feet',
            invert: val => val / 0.00328084
        }
    } as CustomUnit,
    tonHa_lbAc: {
        
        metric: {
            display: 'ton/ha',
            invert: val => val * 892.179
        },
        imperial: {
            display: 'lb/ac',
            invert: val => val / 892.179
        }
    } as CustomUnit,
    
    kgRemovedTonHa_ozRemovedLbAc: {
        metric: {
            display: 'kg_removed/ton/ha',
            invert: val => val * 0.016
        },
        imperial: {
            display: 'oz_removed/lb/ac',
            invert: val => val * 0.016
        }
    } as CustomUnit,

    mgKg_OzLb: {
        metric: {
            display: 'mg/kg',
            invert: val => val * 0.000016
        },
        imperial: {
            display: 'oz/lb',
            invert: val => val / 0.000016
        }
    } as CustomUnit,

    ha_ac: {
        metric: {
            display: 'ha',
            invert: val => val * 2.47105
        },
        imperial: {
            display: 'ac',
            invert: val => val / 2.47105
        }
    } as CustomUnit,

    kgTon_lbTon: {
        metric: {
            display: 'kg/ton',
            invert: val => val * 2
        },
        imperial: {
            display: 'lb/ton',
            invert: val => val / 2
        }
    } as CustomUnit,

    liter_gallon: {
        metric: {
            display: 'litre',
            invert: val => val * 0.264172
        },
        imperial: {
            display: 'gallon',
            invert: val => val / 0.264172
        }
    } as CustomUnit,

    lHa_gallonAc: {
        metric: {
            display: 'l/Ha',
            invert: val => val * 0.1069066
        },
        imperial: {
            display: 'gallon/Ac',
            invert: val => val / 0.1069066
        }
    } as CustomUnit
}
