/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */

enum SummaryType {
    Normal = "Normal",
    Jdv = "Jdv",
    Mdv = "Mdv",
    Normal2 = "Normal2",
    Jdv2 = "Jdv2",
    Mdv2 = "Mdv2"
}

export default SummaryType;
