import React, { useContext, useMemo, useState } from 'react'
import PlayFieldProgram from '../../controllers/PlayFieldProgram'
import { buildSetter } from '../../immutableState'
import MaterProgramLookups from '../../controllers/MaterProgramLookups'
import {model, useAjaxData, useStateAjax} from '../../wrapper'
import ProgramController from '../../controllers/ProgramController'
import AppContext from '../../appContext'
import { dateFormat } from '../../date'
import { AutoComplete, AutoCompleteNullable } from '../../components/AutoComplete'
import CheckBox from '../../components/CheckBox'
import { distImageString } from '../Distributors'
import SelectNumberNullable from "../../components/SelectNumberNullable";
import CurrencyController from "../../controllers/CurrencyController";

const MasterTab: React.FC<{
    program: PlayFieldProgram;
    onChange: (data: PlayFieldProgram) => void;
}> = (props) => {
    const [data, _setData] = useState(props.program)
    const [imageDistributorLogoId, setImageDistributorLogoId] = useState<number | null>(props.program.distributorLogoId ? props.program.distributorLogoId : null)
    const [currencies, _] = useStateAjax(() => CurrencyController.index())

    function setData (data: PlayFieldProgram) {
        props.onChange(data)
        _setData(data)
    }

    const setter = buildSetter(data, setData)

    const [lookups, setLookups] = useState<MaterProgramLookups>({
        addresses: [],
        agents: [],
        distributors: []
    })
    const distributorAgents = useMemo(() => lookups.agents.filter(a => a.distributorId === data.distributorId), [data.distributorId, lookups])

    useAjaxData(() => ProgramController.lookups(), setLookups)

    const app = useContext(AppContext)
    return (
        <div>

            <div className="flex w-full justify-center">
                <div className="pt-4">
                    <div className="text-sm text-gray-600">{dateFormat(new Date())}</div>
                    <div className="font-bold uppercase text-primary-800 tracking-wider text-xl leading-none">
                        {props.program.clientName}
                    </div>

                    <table>
                        <tbody>
                            <tr>
                                <td className="p-1">{app.word('farm_name')}:</td>
                                <td className="p-1">
                                    <input type="text" className="input" {...model(data, setData, 'farmName')} />
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('distributor')}:</td>
                                <td className="p-1">
                                    <AutoCompleteNullable options={lookups.distributors} textFunc={d => d.value}
                                        valueFunc={d => d.id} value={data.distributorId}
                                        onChange={(val, option) => {
                                            setImageDistributorLogoId(option !== null ? option.distributorLogoId : null)
                                            setter({ distributorId: val })
                                        }}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('agent')}:</td>
                                <td className="p-1">
                                    <AutoCompleteNullable options={distributorAgents} textFunc={d => d.value}
                                        valueFunc={d => d.id} value={data.agentId}
                                        onChange={(val, agent) => setter({ agentId: val, agentName: agent?.value, agentAddress: agent?.address, agentEmail: agent?.email, agentTelephone: agent?.telephone, agentArea: agent?.area })}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('show_the_compiling_user')}:</td>
                                <td className="p-1">
                                    <CheckBox value={data.showAgriculturist}
                                        onChange={val => setter({ showAgriculturist: val })}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('foliar_program')}:</td>
                                <td className="p-1">
                                    <CheckBox value={data.leafRepresentation}
                                        onChange={v => setter({ leafRepresentation: v })}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('table_grapes')}:</td>
                                <td className="p-1">
                                    <CheckBox value={data.tableGrapes}
                                        onChange={v => setter({ tableGrapes: v })}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('pricing_module')}:</td>
                                <td className="p-1">
                                    <CheckBox value={data.pricingModule}
                                        onChange={v => setter({ pricingModule: v })}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('select_currency')}:</td>
                                <td className="p-1">
                                    <SelectNumberNullable options={currencies}
                                                          textFunc={t => t.name}
                                                          valueFunc={t => t.id}
                                                          value={data.currency?.id ?? null}
                                                          onChange={v => setter({ currency: currencies.find(c => c.id == v) })}/>
                                </td>
                            </tr>
                            <tr>
                                <td className="p-1">{app.word('address')}</td>
                                <td className="p-1">
                                    <AutoComplete options={lookups.addresses} textFunc={d => d.value} valueFunc={d => d.id}
                                        value={data.addressId}
                                        onChange={val => setter({ addressId: val })}/>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                    <div className="flex text-sm">

                        <div className="flex-1">
                            <div className="font-bold text-primary-800 tracking-wider pl-2">{app.word('agent')}</div>

                            {data.agentId
                                ? <div>
                                    <div className="pl-2">
                                        <img
                                            className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                            src="/icons/irrigation.svg" alt="agentName"/>
                                        <span className="align-middle">{data.agentName}</span>
                                    </div>
                                    <div className="pl-2">
                                        <img alt="agent"
                                            className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                            src="/icons/aim.svg"/>
                                        <span className="align-middle">{data.agentArea}</span>
                                    </div>
                                    <div className="pl-2">
                                        <img alt="agent"
                                            className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                            src="/icons/aim.svg"/>
                                        <span className="align-middle">{data.agentAddress}</span>
                                    </div>
                                    <div className="pl-2">
                                        <img alt="agent"
                                            className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                            src="/icons/trees.svg"/>
                                        <span className="align-middle">{data.agentTelephone}</span>
                                    </div>
                                    <div className="pl-2">
                                        <img alt="agent"
                                            className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                            src="/icons/trees.svg"/>
                                        <span className="align-middle">{data.agentEmail}</span>
                                    </div>
                                </div>
                                : <div>{app.word('no_agent_selected')}</div>}
                        </div>

                        <div className="flex-1 text-sm">
                            <div
                                className="font-bold text-primary-800 tracking-wider pl-2">{app.word('landboukundige')}</div>

                            <div className="pl-2">
                                <img alt="irrigation"
                                    className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                    src="/icons/irrigation.svg"/>
                                <span className="align-middle">{data.approvedBy}({data.approvedByDegree ?? 'NA'})</span>
                            </div>
                            <div className="pl-2">
                                <img alt="aim"
                                    className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                    src="/icons/aim.svg"/>
                                <span className="align-middle">{data.approvedByPhoneNumber}</span>
                            </div>
                            <div className="pl-2">
                                <img alt="trees"
                                    className="text-red-800 stoke-current px-1 w-6 h-6 inline-block align-middle"
                                    src="/icons/trees.svg"/>
                                <span className="align-middle">{data.approvedByEmail}</span>
                            </div>
                            <div>{app.word('not_yet_approved')}</div>
                        </div>
                    </div>

                </div>

                <div className="max-w-md">
                    {imageDistributorLogoId === null ?
                        null :
                        <img alt="distributor" className="ml-8 w-[25rem]" src={distImageString(imageDistributorLogoId)}/>
                        }
                </div>
            </div>
        </div>
    )
}

export default MasterTab
