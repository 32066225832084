/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import Language from "./Language";
import PaginationResponse from "./PaginationResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import KeysLookup from "./KeysLookup";
import LanguageKey from "./LanguageKey";
import LangResult from "./LangResult";
import LanguageTextRequest from "./LanguageTextRequest";
import KeyDisplayRequest from "./KeyDisplayRequest";

const urls = {
    index(): string {
        return "/language/index";
    },
    keysLookup(): string {
        return "/language/keyslookup";
    }
}

export default {
    urls,
    index(): Promise<Language[]> {
        return contentOnly(Axios.get("/language/index", defaultConfig));
    },
    languages(request: PaginationRequestSearch): Promise<PaginationResponse<Language>> {
        return contentOnly(Axios.post("/language/languages", request, defaultConfig));
    },
    keysLookup(): Promise<KeysLookup[]> {
        return contentOnly(Axios.get("/language/keyslookup", defaultConfig));
    },
    uniqueKey(key: LanguageKey): Promise<boolean> {
        return contentOnly(Axios.post("/language/uniquekey", key, defaultConfig));
    },
    keys(request: PaginationRequestSearch): Promise<PaginationResponse<LangResult>> {
        return contentOnly(Axios.post("/language/keys", request, defaultConfig));
    },
    upsertLanguage(request: Language): Promise<boolean> {
        return contentOnly(Axios.post("/language/upsertlanguage", request, defaultConfig));
    },
    upsertKeyText(request: LanguageTextRequest): Promise<boolean> {
        return contentOnly(Axios.post("/language/upsertkeytext", request, defaultConfig));
    },
    changeDisplay(request: KeyDisplayRequest): Promise<boolean> {
        return contentOnly(Axios.post("/language/changedisplay", request, defaultConfig));
    },
    addNewkey(request: LanguageKey): Promise<number> {
        return contentOnly(Axios.post("/language/addnewkey", request, defaultConfig));
    }
}
