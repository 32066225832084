import React, {useContext, useEffect, useRef, useState} from 'react'
import {Params, useBlocker, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import ProgramController from '../controllers/ProgramController'
import {emptyConvertSize} from './program/Functions'
import PlayfieldTemplate, {usePlayFieldProduct} from './program/PlayfieldTemplate'
import {
    buildPlayFieldState,
    emptyPlayFieldState,
    stateToPlayField,
    usePlayFieldState
} from './program/playFieldState'
import AppContext from '../appContext'
import {useValidation} from '../validation'
import Input from '../components/Input'
import PlayFieldTemplateResponse from '../controllers/PlayFieldTemplateResponse'
import ChooseTemplate from './ChooseTemplate'
import {showSuccessOrFailed} from '../Snacks'
import Dialog from '../components/Dialog'
import {MultiCheckbox} from "../components/MultiCheckBoxString";
import RegionController from "../controllers/RegionController";
import {arrayToRec} from "../array";

function parseId(params: Readonly<Params<string>>): number | null {
    if (params.templateId === 'new') {
        return null
    }

    return parseInt(params.templateId as string)
}



const EditTemplate: React.FC = () => {
    const params = useParams()
    const [queryParams] = useSearchParams()
    const [template, setTemplate] = useState<number | null>(null)
    const context = useContext(AppContext)
    const [state, stateDispatch] = usePlayFieldState(emptyPlayFieldState)
    const [name, setName] = useState('')
    const [templateRegions, setTemplateRegions] = useState<number[]>([])

    const [regions, setRegions] = useState<Record<number, string>>({})
    
    useEffect(() => {
        RegionController.index().then(e => {
            setRegions(arrayToRec(e, r => r.id, r => r.name));
        })
    }, []);

    const blocker = useBlocker(_ => state.change);

    const navigate = useNavigate()

    const programId = useRef<number | null>(parseId(params))
    const playFieldProducts = usePlayFieldProduct(stateDispatch)

    function templateChanged(value: number | null, item: PlayFieldTemplateResponse | null) {
        setTemplate(value)
        if (item === null) {
            loadInitial()
            return
        }

        playFieldProducts.setProducts(item.products)
        stateDispatch({
            type: 'set',
            state: buildPlayFieldState(item.playField.data, emptyConvertSize, context.initial.system, item.products)
        })
    }

    function loadInitial() {
        if (programId.current === null) {
            stateDispatch({
                type: 'set',
                state: {
                    system: context.initial.system,
                    columns: [],
                    rows: [],
                    cells: [],
                    convertSize: emptyConvertSize
                }
            })
            return
        }

        ProgramController.getTemplate(programId.current).then(res => {
            stateDispatch({
                type: 'set',
                state: buildPlayFieldState(res.playField.data, emptyConvertSize, context.initial.system, res.products)
            })
            playFieldProducts.setProducts(res.products)
            setName(res.playField.name)
            setTemplateRegions(res.playField.regions)
        })
    }

    useEffect(() => {
        loadInitial()

        // load copy if present
        const query = queryParams.get('copy')
        if (query) {
            const templateId = parseInt(query)
            ProgramController.getTemplate(templateId).then(res => {
                templateChanged(templateId, res)
            })
        }
    }, [])

    function saveTemplate(): Promise<void> {
        if (!validation.validate()) {
            return Promise.resolve();
        }

        return showSuccessOrFailed(context, ProgramController.saveTemplate({
            name,
            regions: templateRegions,
            playField: stateToPlayField(state),
            playFieldId: programId.current
        })).then((res) => {
            programId.current = res
        })
    }

    const validation = useValidation({
        nameRequired: name.length > 0,
        regionsRequired: templateRegions.length > 0
    })

    return <>
        <Dialog title={context.word('save_template')}
                show={blocker.state == 'blocked'}
                setShow={() => blocker.reset?.()}
                body={
                    <div className="p-4">
                        <div className="text-center mb-4">
                            <div>{context.word('unsaved_templates_lost')}.</div>
                            <div>{context.word('save_before_closing')}?</div>
                        </div>
                        <div className="flex w-full justify-between items-center">
                            <div className="uppercase text-error-400 pr-4 cursor-pointer underline"
                                 onClick={() => blocker.proceed?.()}>
                                {context.word('discard_changes')}
                            </div>
                            <div>
                                <div className="btn bg-primary"
                                     onClick={() => saveTemplate().then(_ => blocker.proceed?.())}>{context.word('save_template')}</div>
                            </div>
                        </div>
                    </div>
                }
        />

        <div>
            <div className="btn bg-primary m-2" onClick={() => navigate('/templates/')}>{context.word('back')}</div>
            <div className="flex">
                <div className="flex flex-1">
                    <div className="py-3 pr-16 text-gray-800">
                        {context.word('name')}
                    </div>

                    {!validation.rules.nameRequired ?
                        <div className="text-error-600">{context.word('name_required')}</div> : null}
                    <div className="w-full">
                        <Input value={name} change={v => setName(v)}/>
                    </div>
                </div>
                <div className="flex flex-1">
                    <div className="py-3 pr-16 text-gray-800">
                        {context.word('regions')}
                    </div>
    
                    <MultiCheckbox options={regions} value={templateRegions}
                                   setValue={v => setTemplateRegions(v)}/>
    
                    {!validation.rules.regionsRequired ?
                        <div className="text-error-600">{context.word('region_required')}</div> : null}
                </div>
            </div>

            <ChooseTemplate filterByRegions={false} value={template} onChange={templateChanged}/>

            <PlayfieldTemplate state={[state, stateDispatch]} 
                               products={playFieldProducts.products}
                               addProduct={playFieldProducts.addProduct} 
                               editProduct={playFieldProducts.editProduct} 
                               />
            <div className="text-right">
                <div className="btn bg-primary" onClick={() => saveTemplate()}>{context.word('save_template')}</div>
            </div>
        </div>
    </>
}

export default EditTemplate
