import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import CultivarController from '../controllers/CultivarController'
import CultivarResponse from '../controllers/CultivarResponse'
import AppContext from '../appContext'
import { customUnits, unitCustom } from './program/CustomUnits'
import CropController from '../controllers/CropController'
import { AutoComplete } from '../components/AutoComplete'
import LanguageController from '../controllers/LanguageController'
import { useAjaxData } from '../wrapper'
import { useValidation } from '../validation'
import KeysLookup from '../controllers/KeysLookup'
import { buildSetter } from '../immutableState'
import { showSuccessOrFailed } from '../Snacks'
import NullableMeasureValue from '../controllers/NullableMeasureValue'
import { NullableMeasureValueField } from './program/MeasureValueField'
import MeasurementUnit from '../controllers/MeasurementUnit'
import { getValueNullable, measureValueNullable } from './program/Helpers'
import CropResponse from '../controllers/CropResponse'
import { roundToNullable } from './program/ViewBlock'

// exactly the same as CultivarResponse, but with optional withdrawal numbers
interface CultivarForm {
    id: number,
    languageKeyId: number,
    cropId: number,
    withdrawal_N: NullableMeasureValue,
    withdrawal_P: NullableMeasureValue,
    withdrawal_P205: NullableMeasureValue,
    withdrawal_K: NullableMeasureValue,
    withdrawal_K20: NullableMeasureValue,
    withdrawal_Ca: NullableMeasureValue,
    withdrawal_Mg: NullableMeasureValue,
    name: string,
    key: string,
    active: boolean
}

function toForm (resp: CultivarResponse): CultivarForm {
    return {
        active: resp.active,
        cropId: resp.cropId,
        id: resp.id,
        key: resp.key,
        languageKeyId: resp.languageKeyId,
        name: resp.name,
        withdrawal_Ca: resp.withdrawal_Ca,
        withdrawal_K: resp.withdrawal_K,
        withdrawal_K20: resp.withdrawal_K20,
        withdrawal_Mg: resp.withdrawal_Mg,
        withdrawal_N: resp.withdrawal_N,
        withdrawal_P: resp.withdrawal_P,
        withdrawal_P205: resp.withdrawal_P205

    }
}

const emptyCultivar: CultivarForm = {
    active: true,
    cropId: 0,
    id: 0,
    key: '',
    languageKeyId: 0,
    name: '',
    withdrawal_Ca: { metric: null, imperial: null },
    withdrawal_K: { metric: null, imperial: null },
    withdrawal_K20: { metric: null, imperial: null },
    withdrawal_Mg: { metric: null, imperial: null },
    withdrawal_N: { metric: null, imperial: null },
    withdrawal_P: { metric: null, imperial: null },
    withdrawal_P205: { metric: null, imperial: null }
}

function lineThrough (data: (row: CultivarResponse) => React.ReactNode): (row: CultivarResponse) => React.ReactNode {
    // eslint-disable-next-line react/display-name
    return (item: CultivarResponse) => <span className={!item.active ? 'text-red-500 italic line-through' : ''}>{data(item)}</span>
}

const Cultivars: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<CultivarResponse>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<CultivarForm>(emptyCultivar)
    const setData = buildSetter(upsertData, setUpsertData)

    const [crops, setCrops] = useState<CropResponse[]>([])

    useAjaxData(() => CropController.index(), setCrops)

    function showPopup (preset: CultivarForm) {
        setUpsertData(preset)
        setShow(true)
    }

    function upsertCallback (data: CultivarForm) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, CultivarController.upsert({
            active: data.active,
            cropId: data.cropId,
            id: data.id,
            languageKeyId: data.languageKeyId,
            withdrawal_Ca: data.withdrawal_Ca ?? 0,
            withdrawal_K: data.withdrawal_K ?? 0,
            withdrawal_K20: data.withdrawal_K20 ?? 0,
            withdrawal_Mg: data.withdrawal_Mg ?? 0,
            withdrawal_N: data.withdrawal_N ?? 0,
            withdrawal_P: data.withdrawal_P ?? 0,
            withdrawal_P205: data.withdrawal_P205 ?? 0
        })).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    function setActive (item: CultivarResponse, active: boolean) {
        showSuccessOrFailed(context, CultivarController.toggleActive({
            active,
            id: item.id
        })).then(() => {
            pagedTableRef.current?.refresh()
        })
    }

    const [langOptions, setLangOptions] = useState<KeysLookup[]>([])
    useAjaxData(() => LanguageController.keysLookup(), setLangOptions)

    const validation = useValidation({
        nameRequired: () => upsertData.languageKeyId !== 0,
        cropRequired: () => upsertData.cropId > 0
    })

    return (
        <>
            <Dialog title={upsertData.id === 0 ? context.word('new_cultivar') : context.word('edit_cultivar')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)}
                        saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('name'),
                            <AutoComplete options={langOptions} textFunc={t => t.text} valueFunc={t => t.keyId}
                                value={upsertData.languageKeyId}
                                onChange={v => setData({ languageKeyId: v })}/>,
                            validation.rules.nameRequired,
                            context.word('name_required')
                        )}
                        {EditRow(
                            context.word('crop'),
                            <AutoComplete options={crops} textFunc={t => t.name} valueFunc={t => t.id}
                                value={upsertData.cropId} onChange={v => setData({ cropId: v })}/>,
                            validation.rules.cropRequired,
                            context.word('crop_required')
                        )}
                        <tr >
                            <td colSpan={2}>
                                {context.word('withdrawals') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc))}):
                                <table>
                                    <tbody>


                                        <tr>
                                            <td className="text-gray-600 text-sm">{context.word('n')}</td>
                                            <td className="text-gray-600 text-sm">{context.initial.system === MeasurementUnit.METRIC ? context.word('p') : context.word('p205')} </td>
                                            <td className="text-gray-600 text-sm">{context.initial.system === MeasurementUnit.METRIC ? context.word('k') : context.word('k20')}</td>
                                            <td className="text-gray-600 text-sm">{context.word('ca')}</td>
                                            <td className="text-gray-600 text-sm">{context.word('mg')}</td>
                                        </tr>
                                        <tr>
                                            <td className="w-20 pr-2">
                                                <NullableMeasureValueField unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} value={upsertData.withdrawal_N} update={v => setData({ withdrawal_N: v })}/>
                                            </td>
                                            <td className="w-20 pr-2">
                                                {context.initial.system === MeasurementUnit.METRIC
                                                    ? <NullableMeasureValueField value={upsertData.withdrawal_P}
                                                        unit={customUnits.kgRemovedTonHa_ozRemovedLbAc}
                                                        update={v => setData({
                                                            withdrawal_P: measureValueNullable(v.metric, null),
                                                            withdrawal_P205: measureValueNullable(null, v.metric !== null ? v.metric * 2.2915 : null)
                                                        })}/>
                                                    : <NullableMeasureValueField value={upsertData.withdrawal_P205}
                                                        unit={customUnits.kgRemovedTonHa_ozRemovedLbAc}
                                                        update={v => setData({
                                                            withdrawal_P205: measureValueNullable(null, v.imperial),
                                                            withdrawal_P: measureValueNullable(v.imperial !== null ? v.imperial / 2.2915 : null, null)
                                                        })}/>
                                                }
                                            </td>
                                            <td className="w-20 pr-2">
                                                {context.initial.system === MeasurementUnit.METRIC
                                                    ? <NullableMeasureValueField value={upsertData.withdrawal_K}
                                                        unit={customUnits.kgRemovedTonHa_ozRemovedLbAc}
                                                        update={v => setData({
                                                            withdrawal_K: measureValueNullable(v.metric, null),
                                                            withdrawal_K20: measureValueNullable(null, v.metric !== null ? v.metric * 1.2047 : null)
                                                        })}/>
                                                    : <NullableMeasureValueField value={upsertData.withdrawal_K20}
                                                        unit={customUnits.kgRemovedTonHa_ozRemovedLbAc}
                                                        update={v => setData({
                                                            withdrawal_K20: measureValueNullable(null, v.imperial),
                                                            withdrawal_K: measureValueNullable(v.imperial !== null ? v.imperial / 1.2047 : null, null)
                                                        })}/>
                                                }
                                            </td>
                                            <td className="w-20 pr-2">
                                                <NullableMeasureValueField unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} value={upsertData.withdrawal_Ca} update={v => setData({ withdrawal_Ca: v })}/>
                                            </td>
                                            <td className="w-20 pr-2">
                                                <NullableMeasureValueField unit={customUnits.kgRemovedTonHa_ozRemovedLbAc} value={upsertData.withdrawal_Mg} update={v => setData({ withdrawal_Mg: v })}/>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </EditTable>
                }
            />

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyCultivar)}>{context.word('add_cultivar')}</div>
            <PagedSearchTable<CultivarResponse>
                componentRef={pagedTableRef}
                call={CultivarController.paged}
                columns={[
                    {
                        header: context.word('crop_name'),
                        row: lineThrough(item => item.cropName)
                    },
                    {
                        header: context.word('cultivar_name'),
                        row: lineThrough(item => item.name)
                    },
                    {
                        header: context.word('withdrawal_n') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                        row: lineThrough(item => roundToNullable(getValueNullable(context.initial.system, item.withdrawal_N), 2))
                    },
                    context.initial.system === MeasurementUnit.METRIC
                        ? {
                            header: context.word('withdrawal_p') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')',
                            row: lineThrough(item => roundToNullable(getValueNullable(MeasurementUnit.METRIC, item.withdrawal_P), 2))
                        }
                        : { header: context.word('withdrawal_p205') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')', row: lineThrough(item => roundToNullable(getValueNullable(MeasurementUnit.IMPERIAL, item.withdrawal_P205), 2)) },
                    context.initial.system === MeasurementUnit.METRIC
                        ? { header: context.word('withdrawal_k') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')', row: lineThrough(item => roundToNullable(getValueNullable(MeasurementUnit.METRIC, item.withdrawal_K), 2)) }
                        : { header: context.word('withdrawal_k20') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')', row: lineThrough(item => roundToNullable(getValueNullable(MeasurementUnit.IMPERIAL, item.withdrawal_K20), 2)) },
                    { header: context.word('withdrawal_ca') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')', row: lineThrough(item => roundToNullable(getValueNullable(context.initial.system, item.withdrawal_Ca), 2)) },
                    { header: context.word('withdrawal_mg') + ' (' + context.word(unitCustom(context.initial.system, customUnits.kgRemovedTonHa_ozRemovedLbAc)) + ')', row: lineThrough(item => roundToNullable(getValueNullable(context.initial.system, item.withdrawal_Mg), 2)) },
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="underline cursor-pointer" onClick={() => showPopup(toForm(item))}>{context.word('edit')}</span>
                            <span>&nbsp;|&nbsp;</span>
                            {item.active
                                ? <span className="underline cursor-pointer" onClick={() => setActive(item, false)}>{context.word('deactivate')}</span>
                                : <span className="underline cursor-pointer" onClick={() => setActive(item, true)}>{context.word('activate')}</span>
                            }
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Cultivars
