/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import SetLanguageIdRequest from "./SetLanguageIdRequest";
import UnitRequest from "./UnitRequest";
import UpdateUserPropRequest from "./UpdateUserPropRequest";
import ArchiveRequest from "./ArchiveRequest";
import IdRequest from "./IdRequest";
import ApproverResponse from "./ApproverResponse";
import UserUpdate from "./UserUpdate";
import PaginationResponse from "./PaginationResponse";
import UserPaged from "./UserPaged";
import PaginationRequestSearch from "./PaginationRequestSearch";
import UserConnected from "./UserConnected";
import LoginHistory from "./LoginHistory";
import UserResponse from "./UserResponse";

const urls = {
    approvers(): string {
        return "/user/approvers";
    },
    all(): string {
        return "/user/all";
    }
}

export default {
    urls,
    setLangauge(request: SetLanguageIdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/user/setlangauge", request, defaultConfig));
    },
    isUnique(username: string, id: number): Promise<boolean> {
        return contentOnly(Axios.post(queryUrl("/user/isunique", {username, id}), defaultConfig));
    },
    changeDisplayUnit(request: UnitRequest): Promise<boolean> {
        return contentOnly(Axios.post("/user/changedisplayunit", request, defaultConfig));
    },
    updateProps(request: UpdateUserPropRequest): Promise<boolean> {
        return contentOnly(Axios.post("/user/updateprops", request, defaultConfig));
    },
    archive(request: ArchiveRequest): Promise<boolean> {
        return contentOnly(Axios.post("/user/archive", request, defaultConfig));
    },
    restore(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/user/restore", request, defaultConfig));
    },
    approvers(): Promise<ApproverResponse[]> {
        return contentOnly(Axios.get("/user/approvers", defaultConfig));
    },
    sendPassword(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/user/sendpassword", request, defaultConfig));
    },
    upsert(request: UserUpdate): Promise<boolean> {
        return contentOnly(Axios.post("/user/upsert", request, defaultConfig));
    },
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<UserPaged>> {
        return contentOnly(Axios.post("/user/paged", request, defaultConfig));
    },
    activeUsers(request: PaginationRequestSearch): Promise<PaginationResponse<UserConnected>> {
        return contentOnly(Axios.post("/user/activeusers", request, defaultConfig));
    },
    loginHistory(request: PaginationRequestSearch): Promise<PaginationResponse<LoginHistory>> {
        return contentOnly(Axios.post("/user/loginhistory", request, defaultConfig));
    },
    all(): Promise<UserResponse[]> {
        return contentOnly(Axios.get("/user/all", defaultConfig));
    }
}
