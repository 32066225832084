/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import PaginationResponse from "./PaginationResponse";
import AgentPagedResponse from "./AgentPagedResponse";
import PaginationRequestSearch from "./PaginationRequestSearch";
import Agent from "./Agent";
import IdRequest from "./IdRequest";
import AgentRequest from "./AgentRequest";

export default {
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<AgentPagedResponse>> {
        return contentOnly(Axios.post("/agent/paged", request, defaultConfig));
    },
    byDistributor(request: IdRequest): Promise<Agent[]> {
        return contentOnly(Axios.post("/agent/bydistributor", request, defaultConfig));
    },
    upsert(request: AgentRequest): Promise<number> {
        return contentOnly(Axios.post("/agent/upsert", request, defaultConfig));
    },
    archive(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/agent/archive", request, defaultConfig));
    },
    restore(request: IdRequest): Promise<boolean> {
        return contentOnly(Axios.post("/agent/restore", request, defaultConfig));
    }
}
