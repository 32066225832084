import Dialog from '../../components/Dialog'
import React, { useContext, useMemo, useState } from 'react'
import AppContext from '../../appContext'
import { arrayPush, arrayRemoveIndex, arrayUpdate } from '../../immutableState'
import AddPumpHouse from './AddPumpHouse'
import { createFertigationState, FertigationState, FertigationStateAction, fertiliserReducer, TankToAdd } from './fertigationCalcs'
import { PlayFieldCalcData } from './playFieldState'
import { distinct, mapMany } from '../../array'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import ProductTank from './ProductTank'

function getLandsIds (data: FertigationState[]): number[] {
    const ids = mapMany(data, d => d.lands.map(l => l.landId))
    return distinct(ids)
}

const Fertigation: React.FC<{
    state: FertigationState[];
    setState: (state: FertigationState[]) => void;
    lands: PlayFieldCalcData[];
    products: ProductLookUpResponse[]
}> = (props) => {
    const { word } = useContext(AppContext)
    const [show, setShow] = useState(false)

    const landsAvailable = useMemo(() => {
        const landsTaken = getLandsIds(props.state)
        return props.lands.filter(l => !landsTaken.includes(l.landId))
    }, [props.state, props.lands])

    function addPumphouse (name: string, landIds: number[], tanks: TankToAdd[]) {
        const pumphouse = {
            name,
            tanks: tanks.map(m => ({
                name: m.name,
                rows: [],
                products: [],
                size: m.size
            })),
            lands: landIds
        }

        props.setState(arrayPush(props.state, createFertigationState(pumphouse, props.lands, props.products)))
        setShow(false)
    }

    function handleDispatch (action: FertigationStateAction, index: number, pump: FertigationState) {
        props.setState(arrayUpdate(props.state, index, fertiliserReducer(pump, action)))
    }

    return <div>
        <div className="m-1 btn bg-primary" onClick={() => setShow(true)}>{word('add_pumpHouse')}</div>

        <Dialog title={word('pumphouse')} body={
            <AddPumpHouse onSave={addPumphouse} lands={landsAvailable}/>} show={show} setShow={setShow}/>

        {props.state.map((pump, pumpIndex) =>
            <div id="removePumphouse" key={pumpIndex}>
                <div>
                    <div className="inline-block text-2xl text-gray-700 tracking-wider underline"> {word('pumphouse')}: {pump.name}</div>
                    <button className="inline-block text-red-500 mx-3 hover:text-xl" onClick={() => {
                        props.setState(arrayRemoveIndex(props.state, pumpIndex))
                    }}>X
                    </button>
                </div>
                <div>
                    {pump.tanks.map((tank, tankIndex) =>
                        <ProductTank
                            key={tankIndex}
                            tankIndex={tankIndex}
                            pumphouse={pump}
                            tank={tank}
                            products={props.products}
                            dispatch={(action) => handleDispatch(action, pumpIndex, pump)}
                        />
                    )}
                </div>
            </div>
        )}
    </div>
}

export { Fertigation }
