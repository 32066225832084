import React, { useContext, useRef, useState } from 'react'
import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import AgentController from '../controllers/AgentController'
import AgentPagedResponse from '../controllers/AgentPagedResponse'
import Dialog from '../components/Dialog'
import AppContext from '../appContext'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import DistributorController from '../controllers/DistributorController'
import AgentRequest from '../controllers/AgentRequest'
import { useStateAjax } from '../wrapper'
import Input from '../components/Input'
import { buildSetter } from '../immutableState'
import SelectNumber from '../components/SelectNumber'
import { useValidation } from '../validation'
import { showSuccessOrFailed } from '../Snacks'
import Success from '../components/Success'

function emptyAgent (): AgentRequest {
    return {
        name: '',
        telephone: '',
        distributorId: 0,
        address: '',
        area: '',
        email: '',
        id: 0
    }
}

const Agents: React.FC = () => {
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<AgentRequest>(emptyAgent)
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<AgentPagedResponse>>()
    const setData = buildSetter(upsertData, setUpsertData)
    const [dialogPopup, setDialogPopup] = useState<React.ReactNode>(null)
    const [distributors] = useStateAjax(() => DistributorController.all())

    const ArchivePopup: React.FC<{
        agentId: number;
        close: () => void;
    }> = (props) => {
        function archiveAgents () {
            showSuccessOrFailed(context, AgentController.archive({ id: props.agentId })).then(() => {
                props.close()
            })
        }
        return <div className="p-2">
            <p>{context.word('would_you_like_to_archive')}?</p>
            <div className="p-2">
                <button className="btn bg-red-500" onClick={() => props.close()}>{context.word('cancel')}</button>
                <button className="btn bg-primary-500" onClick={archiveAgents}>{context.word('archive')}</button>
            </div>
        </div>
    }

    function showPopup (preset: AgentRequest | AgentPagedResponse) {
        setUpsertData({
            address: preset.address ?? '',
            area: preset.address ?? '',
            distributorId: preset.distributorId,
            email: preset.email ?? '',
            name: preset.name ?? '',
            telephone: preset.telephone ?? '',
            id: preset.id
        })
        setShow(true)
    }

    const strikeOut = (agent: AgentPagedResponse): string => {
        return agent.archived ? 'text-red-500 italic line-through' : ''
    }

    function upsertCallback (data: AgentRequest) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, AgentController.upsert(data)).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    function toggleArchive (id: number, archived: boolean) {
        if (archived) {
            AgentController.restore({ id }).then(() => {
                context.showSnack(<Success title={context.word('successfully_restored')}/>)
                pagedTableRef.current?.updateRow(r => r.id === id, r => {
                    r.archived = !archived
                })
            })
        } else {
            setDialogPopup(<ArchivePopup agentId={id} close={() => {
                setDialogPopup(null)
                pagedTableRef.current?.refresh()
            }}/>)
        }
    }

    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0,
        distributorRequired: () => upsertData.distributorId !== 0
    })

    return (
        <>
            {dialogPopup ? <Dialog title={context.word('archive_agent')} body={dialogPopup} show={true} setShow={() => setDialogPopup(null)}/> : null}

            <Dialog mounted={focusFirstInput} title={upsertData.id === 0 ? context.word('new_agent') : context.word('edit_agent')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('name'),
                            <Input value={upsertData.name} change={v => setData({ name: v })} />,
                            validation.rules.nameRequired,
                            context.word('name_required')
                        )}
                        {EditRow(
                            context.word('telephone'),
                            <Input value={upsertData.telephone} change={v => setData({ telephone: v })} />
                        )}
                        {EditRow(
                            context.word('area'),
                            <Input value={upsertData.area} change={v => setData({ area: v })} />
                        )}
                        {EditRow(
                            context.word('address'),
                            <Input value={upsertData.address} change={v => setData({ address: v })} />
                        )}
                        {EditRow(
                            context.word('email'),
                            <Input value={upsertData.email} change={v => setData({ email: v })} />
                        )}
                        {EditRow(
                            context.word('distributor'),
                            <SelectNumber options={distributors} textFunc={t => t.name ?? ''} valueFunc={t => t.id} value={upsertData.distributorId} onChange={v => setData({ distributorId: v })}/>,
                            validation.rules.distributorRequired,
                            context.word('distributor_required')
                        )}
                    </EditTable>
                }/>

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyAgent())}>{context.word('add_agent')}</div>

            <PagedSearchTable<AgentPagedResponse>
                componentRef={pagedTableRef}
                call={AgentController.paged}
                columns={[
                    { header: context.word('name'), row: item => <div className={strikeOut(item)}>{item.name}</div> },
                    { header: context.word('telephone'), row: item => <div className={strikeOut(item)}>{item.telephone}</div> },
                    { header: context.word('area'), row: item => <div className={strikeOut(item)}>{item.area}</div> },
                    { header: context.word('address_has_changed'), row: item => <div className={strikeOut(item)}>{item.address}</div> },
                    { header: context.word('email'), row: item => <div className={strikeOut(item)}>{item.email}</div> },
                    // { header: context.word('distributor'), row: item => item.distributorName },
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="cursor-pointer underline font-medium" onClick={() => showPopup(item)}>{context.word('edit')}</span>
                            <span>&nbsp;|&nbsp;</span>
                            <span className="underline cursor-pointer"
                                onClick={() => toggleArchive(item.id, item.archived)}> {item.archived ? context.word('restore') : context.word('archive')} </span>
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}></PagedSearchTable>
        </>)
}

export default Agents
