import React, { useContext } from 'react'
import AppContext from '../appContext'

const Pagination: React.FC<{
    total: number,
    rowsPerPage: number,
    page: number,
    gotoPage: (page: number) => void,
    setRowsPerPage: (rows: number) => void,
}> = (props) => {
    const totalPages = Math.ceil(props.total / props.rowsPerPage)
    const start = (props.page * props.rowsPerPage) + 1
    const end = start + props.rowsPerPage - 1
    const app = useContext(AppContext)

    function previous () {
        if (props.page > 0) { props.gotoPage(props.page - 1) }
    }

    function next () {
        if (props.page < totalPages - 1) { props.gotoPage(props.page + 1) }
    }

    return (
        <nav
            className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
            aria-label="Pagination"
        >
            <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                    {app.word('showing')} <span className="font-medium">{start}</span> {app.word('to')} <span
                        className="font-medium">{end}</span> {app.word('of')}{' '}
                    <span className="font-medium">{props.total}</span> {app.word('results')}
                </p>

            </div>

            <div className="flex-1 flex justify-between items-center sm:justify-end">
                <div className="text-sm px-4">
                    <span>{app.word('rows_per_page')}: </span>
                    <select onChange={e => props.setRowsPerPage(parseInt(e.target.value))} value={props.rowsPerPage} >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                    </select>
                </div>

                <a onClick={() => previous()}
                    href="#"
                    className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    {app.word('previous')}
                </a>
                <a onClick={() => next()}
                    href="#"
                    className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                >
                    {app.word('next')}
                </a>
            </div>
        </nav>
    )
}

export default Pagination
