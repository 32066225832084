import Pumphouse from '../../controllers/Pumphouse'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import Tank from '../../controllers/Tank'
import { calculateSummary, PlayFieldCalcData, productLookup } from './playFieldState'
import ProductData from '../../controllers/ProductData'
import TankRows from '../../controllers/TankRows'
import MeasureValue from '../../controllers/MeasureValue'
import { addMeasureValue, divMeasureValue, measureValue, mulMeasureValue } from './Helpers'
import { createArray, mutate } from '../../immutableState'
import JsonPlayField from '../../controllers/JsonPlayField'
import Row from '../../controllers/Row'
import ProductTank from '../../controllers/ProductTank'
import { Reducer } from 'react'
import {isProductColumn} from "./Functions";

export interface FertigationTank {
    name: string,
    size: MeasureValue,
    // products applied to this tank.
    products: ProductLookUpResponse[];
    // row of the tank. Equal to the rows of the summary
    rows: TankRows[]
}

export interface FertigationState {
    // pumphouse name
    name: string,
    // tanks within the pumphouse
    tanks: FertigationTank[],

    // lands applied to this pumphouse.
    // tanks in this pumphouse can only contain products
    lands: PlayFieldCalcData[];
    // summary calculation for the given lands
    summary: JsonPlayField;
}

export interface TankToAdd {
    name: string;
    size: MeasureValue;
}

export type FertigationStateAction =
    | { type: 'setDissolved', tankIndex: number; rowIndex: number; value: number | null }
    | { type: 'setEc', tankIndex: number; rowIndex: number; value: string }
    | { type: 'addProduct', tankIndex: number; product: ProductLookUpResponse }
    | { type: 'removeProduct', tankIndex: number; index: number }
    | { type: 'editProduct', tankIndex: number; index: number, product: ProductLookUpResponse }
    | { type: 'recalculate', lands: PlayFieldCalcData[], products: ProductLookUpResponse[] }

function calculateLandData(lands: PlayFieldCalcData[], rowIndex: number, landsSummary: JsonPlayField, dissolve: number | null, tankProducts: {id: number}[]): MeasureValue[] {
    const factor = (dissolve ?? 0) / 100
    
    return lands.map(land => {
        const products = land.data.columns.map(c => c.product);
        // create summary for only the land
        const singleSummary = calculateSummary([land], products);
        
        // filter on tank products only
        const filterProducts = products
            .filter(c => tankProducts.findIndex(p => p.id === c.id) != -1);

        // then find the mapped indexes
        const sumRow = landsSummary.rows[rowIndex]!;
        const singleRowIndex = singleSummary.rows.findIndex(r => r.desc == sumRow.desc && r.month === sumRow.month);
        
        const arr = filterProducts.map(p => {
            // .filter(c => tankProducts.findIndex(p => p.id === c.productId) != -1)
            const colIndex = singleSummary.columns.findIndex(c => isProductColumn(c) && c.productId === p.id);
            if (colIndex === -1)
                return measureValue(0, 0);
            
            const value = singleSummary.cells.find(c => c.row === singleRowIndex && c.col == colIndex);
            return divMeasureValue(value?.standard ?? measureValue(0, 0), measureValue(factor));
        })
        
        return sumOfMeasureValue(arr);
    })
}

function createRows (s: FertigationState, tank: FertigationTank): TankRows[] {
    return createArray(s.summary.rows.length, rowIndex => {
        const row = tank.rows[rowIndex]!

        // row extraction
        const productData = getProductData(tank.products, rowIndex, s.summary)
        const calc = recalculateRow(productData, tank.size, row.dissolved)
        const landData = calculateLandData(s.lands, rowIndex, s.summary, row.dissolved, tank.products);
        
        return {
            landData,
            landTotal: sumOfMeasureValue(landData),

            desc: row.desc,
            month: row.month,
            dissolved: row.dissolved,
            ec: row.ec,
            productData: calc.productData,
            calcTotal: calc.calcTotal,
            total: calc.total,
            tanksNeeded: calc.tanksNeeded,
            valueTotal: calc.valueTotal
        }
    })
}

export const fertiliserReducer: Reducer<FertigationState, FertigationStateAction> = (state: FertigationState, action: FertigationStateAction): FertigationState => {
    switch (action.type) {
    case 'recalculate': {
        // calculateFertigation(action.)
        // calculate a separate summary for the lands selected for the pumphouse
        const landIds = state.lands.map(l => l.landId)
        const resolvedLands = action.lands.filter(l => {
            return landIds.indexOf(l.landId) >= 0
        })
        const summary = calculateSummary(resolvedLands, action.products)

        return {
            summary,
            name: state.name,
            lands: resolvedLands,
            tanks: state.tanks.map<FertigationTank>(tank => calculateFertigationTank(tank, summary, action.products, state.lands))
        }
    }

    case 'setDissolved':
        return mutate(state, s => {
            const tank = s.tanks[action.tankIndex]
            if (!tank) return state
            const row = tank.rows[action.rowIndex]
            if (!row) return state

            row.dissolved = action.value
            // rebuild all rows
            tank.rows = createRows(s, tank)
        })
    case 'setEc':
        return mutate(state, s => {
            const row = s.tanks[action.tankIndex]?.rows[action.rowIndex]
            if (row) {
                row.ec = action.value
            }
        })
    case 'addProduct':
        return mutate(state, s => {
            const tank = s.tanks[action.tankIndex]
            if (!tank) return state

            // add product
            tank.products = tank.products.concat(action.product)
            // rebuild all rows
            tank.rows = createRows(s, tank)
        })
    case 'removeProduct':
        return mutate(state, s => {
            const tank = s.tanks[action.tankIndex]
            if (!tank) return state
            // remove product
            tank.products = tank.products.filter((p, i) => i !== action.index)
            // rebuild all rows
            tank.rows = createRows(s, tank)
        })

    case 'editProduct':
        return mutate(state, s => {
            const tank = s.tanks[action.tankIndex]
            if (!tank) return state

            tank.products = tank.products.map((p, i) => i === action.index ? action.product : p)
            tank.rows = createRows(s, tank)
        })
    }
}

export function toPumpHouse (state: FertigationState): Pumphouse {
    return {
        name: state.name,
        lands: state.lands.map(l => l.landId),
        tanks: state.tanks.map(t => ({
            name: t.name,
            products: t.products.map(p => ({ id: p.id })),
            rows: t.rows,
            size: t.size
        }))
    }
}

export function createFertigationState (pumphouse: Pumphouse, lands: PlayFieldCalcData[], products: ProductLookUpResponse[]): FertigationState {
    return calculateFertigation(pumphouse, lands, products)
}

export function calculateFertigation (pumphouse: Pumphouse, lands: PlayFieldCalcData[], products: ProductLookUpResponse[]): FertigationState {
    // calculate a separate summary for the lands selected for the pumphouse
    const resolvedLands = lands.filter(l => pumphouse.lands.indexOf(l.landId) >= 0)
    const summary = calculateSummary(resolvedLands, products)

    return {
        summary,
        name: pumphouse.name,
        lands: resolvedLands,
        tanks: pumphouse.tanks.map<FertigationTank>(tank => calculateFertigationTank(tank, summary, products, lands))
    }
}

function sumOfMeasureValue (arr: MeasureValue[]): MeasureValue {
    return arr.reduce<MeasureValue>((prev, cur) => addMeasureValue(prev, cur), measureValue(0, 0))
}

function sumProductData (arr: ProductData[], func: (data: ProductData) => MeasureValue) {
    return sumOfMeasureValue(arr.map(func))
}

export function recalculateRow (
    productData: ProductData[],
    tankSize: MeasureValue,
    dissolve: number | null
) {
    // valueTotal = sum of ProductData.value
    const valueTotal = sumProductData(productData, d => d.value)

    const factor = (dissolve ?? 0) / 100
    const ratio = divMeasureValue(tankSize, measureValue(1000))

    const pd = productData.map(pRow => ({
        // 1000 liter water or 1000 gallons
        calcValue: mulMeasureValue(divMeasureValue(pRow.value, valueTotal), measureValue((dissolve ?? 0) * 10)),
        value: pRow.value
    }))

    // calcTotal total per tanksize
    const calcTotal = mulMeasureValue(sumProductData(pd, p => p.calcValue), ratio)

    const tanksNeeded = divMeasureValue(valueTotal, calcTotal)
    const total = divMeasureValue(valueTotal, measureValue(factor))

    return {
        valueTotal,
        total,
        tanksNeeded,
        productData: pd,
        calcTotal
    }
}

function getProductData (products: ProductTank[], rowIndex: number, summary: JsonPlayField) {
    return products.map((p) => {
        const columnIndex = summary.columns.findIndex(c => isProductColumn(c) && c.productId === p.id)
        const summaryCell = summary.cells.find(c => c.col === columnIndex && c.row === rowIndex)
        return {
            value: summaryCell?.standard ?? measureValue(0),
            calcValue: measureValue(0)
        }
    })
}

function calculateRow (filteredProducts: ProductTank[], summary: JsonPlayField, rowIndex: number, tank: Tank, r: Row, existing: TankRows | null = null, lands: PlayFieldCalcData[]): TankRows {
    const productData = getProductData(filteredProducts, rowIndex, summary)
    const calc = recalculateRow(productData, tank.size, existing?.dissolved ?? null)

    const landData = calculateLandData(lands, rowIndex, summary, existing?.dissolved ?? null, filteredProducts);
    
    return {
        landData,
        landTotal: sumOfMeasureValue(landData),
        desc: r.desc,
        month: r.month,
        dissolved: existing?.dissolved ?? null,
        ec: existing?.ec ?? '1.6',
        productData: calc.productData,
        calcTotal: calc.calcTotal,
        total: calc.total,
        tanksNeeded: calc.tanksNeeded,
        valueTotal: calc.valueTotal
    }
}

function calculateFertigationTank (tank: Tank, summary: JsonPlayField, products: ProductLookUpResponse[], lands: PlayFieldCalcData[]): FertigationTank {
    function containsProduct (id: number) {
        return summary.columns.findIndex(s => isProductColumn(s) &&  s.productId === id) !== -1
    }

    const filteredProducts = tank.products.filter(p => containsProduct(p.id))

    // make sure that every row in summary is contained in the fertigation rows
    // fill the new pumphouses tanks with calculated summary rows
    const rows = summary.rows.map<TankRows>((r, rowIndex) => {
        const found = tank.rows.find(s => s.desc === r.desc && s.month === r.month)
        return calculateRow(filteredProducts, summary, rowIndex, tank, r, found, lands);
    })

    return {
        rows,
        products: filteredProducts.map(f => productLookup(products, f.id)),
        size: tank.size,
        name: tank.name
    }
}
