import React, { InputHTMLAttributes } from 'react'

export interface InputProps<T> {
    value: T;
    change: (value: T) => void;

    placeHolder?: string;
    children?: (attrs: InputHTMLAttributes<any>) => React.ReactNode;
}

const Input: React.FC<InputProps<string>> = (props) => {
    function onChange (e: React.FormEvent<HTMLInputElement>) {
        props.change((e.target as HTMLInputElement).value)
    }

    if (props.children) {
        return <>
            {props.children({
                value: props.value,
                onInput: onChange
            })}
        </>
    }

    return <input placeholder={props.placeHolder ?? ''} type="text" className="input" value={props.value} onInput={onChange}/>
}

export default Input




