import PagedSearchTable from '../components/PagedSearchTable'
import ProgramController from '../controllers/ProgramController'
import React, { useContext } from 'react'
import { dateFormat } from '../date'
import { useNavigate } from 'react-router-dom'
import ProgramBaseResponseList from '../controllers/ProgramBaseResponseList'
import AppContext from '../appContext'

const Templates: React.FC = () => {
    const navigate = useNavigate()
    const app = useContext(AppContext)

    function rowClicked (item: ProgramBaseResponseList) {
        navigate(`/approval/${item.id}`)
    }

    return <PagedSearchTable
        rowClick={item => rowClicked(item)}
        call={ProgramController.approvals} keyExtractor={t => t.id} columns={[
            {
                header: app.word('name'),
                row: t => t.name
            },
            {
                header: app.word('submitted_on'),
                row: t => dateFormat(t.submittedOn)
            },
            {
                header: app.word('created_by'),
                row: t => <>
                    <div>{t.createdBy}</div>
                    <div>{t.userEmail}</div>
                </>
            },
            {
                header: app.word('approved_on'),
                row: t => dateFormat(t.approvedOn)
            }
        ]}/>
}

export default Templates
