import { AppContextData } from './appContext'
import { AxiosResponse } from 'axios'
import React, {
    DependencyList,
    Dispatch,
    FormEventHandler,
    InputHTMLAttributes,
    ReactNode,
    SetStateAction,
    useEffect, useMemo,
    useRef,
    useState
} from 'react'
import { KeyOfType } from './components/Fields'

// export function compareField<T>(field: keyof NonNullable<T>): (a: T, b: T) => boolean {
//     return (a, b) => (a as any)[field] === (b as any)[field];
// }

export function useWatcher<S> (state: S, comparer: (a: S, b: S) => boolean, callback: (value: S) => void) {
    const prev = useRef(state)
    // only fire when there is a change
    useEffect(() => {
        if (!comparer(prev.current, state)) {
            callback(state)
            prev.current = state
        }
    }, [state])
}

interface Switcher<T> {
    case(value: T | ((predicate: T) => boolean), render: () => React.ReactNode): Switcher<T>

    default(render: () => React.ReactNode): void;
}

export function switcher<T> (value: T, cases: (arg: Switcher<T>) => void): ReactNode {
    const caseList: ({ condition: T, render: () => React.ReactNode })[] = []
    let _default: () => React.ReactNode = () => null

    const ins: Switcher<T> = {
        case (condition: T, render: () => React.ReactNode): Switcher<T> {
            caseList.push({ condition, render })
            return ins
        },
        default (render: () => React.ReactNode): void {
            _default = render
        }
    }

    cases(ins)
    const first = caseList.find(c => typeof c.condition === 'function' ? c.condition(value) : value === c.condition)
    if (first) { return first.render() }
    return _default()
}

export function useStateAjax<T> (caller: () => Promise<T[]>): [T[], Dispatch<SetStateAction<T[]>>] {
    const [state, setState] = useState<T[]>([])
    useEffect(() => {
        caller().then(resp => {
            setState(resp)
        })
    }, [])

    return [state, setState]
}

export function useAjaxData<T> (caller: () => Promise<T>, setter: (data: T) => void) {
    useEffect(() => {
        caller().then(resp => {
            setter(resp)
        })
    }, [])
}


export function useMemoAjax<T>(initial: T, call: () => Promise<T>, deps: DependencyList) {
    const [data, setData] = useState<T>(initial);
    useMemo(() => {
        call().then(d => {
            setData(d);
        })
    }, deps)

    return data
}

// wrap ajax loader to handle loader indicator and errors
export function wrapLoader<T> (app: AppContextData, call: Promise<T>, setter: (data: T) => void): void {
    const hideLoader = app.showLoader()
    call.then(res => {
        setter(res)
    }).catch(err => {
        // app.showError('Something is wrong');
        console.log('Something else is wrong', err)
        throw err
    }).finally(() => {
        hideLoader()
    })
}

export function onInputHandler (action: (value: string) => void) : FormEventHandler<HTMLInputElement> {
    return (e) => {
        action((e.target as HTMLInputElement).value)
    }
}

export function model<T> (data: T, setData: Dispatch<T>, key: KeyOfType<T, string>): InputHTMLAttributes<any> {
    return {
        value: data[key] as any as string,
        onInput (event) {
            setData({ ...data, [key]: (event.target as HTMLInputElement).value })
        }
    }
}

export function clone<T>(obj: T): T {
    return JSON.parse(JSON.stringify(obj));
}

// bind helper function to shorten two-way data bindings
// give const [username, setUsername] = useState(..) <input {...bind(username, setUsername)}
export function bind (value: string, setValue: Dispatch<SetStateAction<string>>): InputHTMLAttributes<any> {
    return {
        value,
        onInput (event) {
            setValue((event.target as HTMLInputElement).value)
        }
    }
}

// <div onKeyUp={onEnter(functionCaller)}>
export function onEnter (action: Function): (event: React.KeyboardEvent<HTMLDivElement>) => void {
    return (event) => {
        if (event.key === 'Enter') { action(event) }
    }
}

export function classNames (...classes: string[]): string {
    return classes.filter(Boolean).join(' ')
}

export function max<T> (arr: T[], field: (data: T) => number, initial: number = 0): number {
    return arr.reduce((max, curr) => Math.max(field(curr), max), initial)
}

export function min<T> (arr: T[], field: (data: T) => number, initial: number = 0): number {
    return arr.reduce((max, curr) => Math.min(field(curr), max), initial)
}
