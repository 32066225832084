/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */

enum MeasurementUnit {
    METRIC = "METRIC",
    IMPERIAL = "IMPERIAL"
}

export default MeasurementUnit;
