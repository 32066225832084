import React, { PropsWithChildren, useContext } from 'react'
import AppContext from '../appContext'
import SelectOptions from './SelectOptions'

const SelectNumberNullable = <TOptions, >(props: PropsWithChildren<SelectOptions<TOptions, number | null>>) =>  {
    
    const context = useContext(AppContext)
    return <select className="input"
        onChange={e => props.onChange(e.target.value === '' ? null : parseInt(e.target.value))}
        value={props.value ?? ''}
    >
        <option value="">{context.word('choose_an_option')}</option>
        {props.options.map((l) => <option key={props.valueFunc(l)}
            value={props.valueFunc(l)}>{props.textFunc(l)}</option>)}
    </select>
}

export default SelectNumberNullable
