import React, { useContext, useState } from 'react'
import { useStateAjax } from '../../wrapper'
import UserController from '../../controllers/UserController'
import SelectNumber from '../../components/SelectNumber'
import AppContext from '../../appContext'

const ProgramApprove: React.FC<{
    onSubmit: (approver: number) => void;
    onCancel: () => void;
}> = (props) => {
    const app = useContext(AppContext)
    const [approversList] = useStateAjax(() => UserController.approvers())
    const [approver, setApprover] = useState<number>(0)

    return <div className="p-4">

        <SelectNumber options={approversList} textFunc={a => a.name} valueFunc={a => a.id} value={approver}
            onChange={setApprover}/>

        <div className="flex w-full justify-between items-center mt-2">
            <div className="btn bg-error-600"
                onClick={() => props.onCancel()}>
                {app.word('cancel')}
            </div>
            <div>
                {approver === 0 ? null : <div className="btn bg-primary"
                    onClick={() => props.onSubmit(approver)}>{app.word('submit')}</div>}
            </div>
        </div>

    </div>
}

export default ProgramApprove
