import React, { useContext, useMemo } from 'react'
import AppContext from '../../appContext'
import PlayFieldViewBlock from '../../controllers/PlayFieldViewBlock'
import { FormatValue, getValue } from './Helpers'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import { customUnits, unitCustom } from './CustomUnits'
import ProductObject from '../../controllers/ProductObject'
import { classNames } from '../../wrapper'
import MeasureValue from '../../controllers/MeasureValue'
import { roundTo } from './ViewBlock'

function shortageClass (target: number, shortage: number): string {
    // If shortage is more than 10% of target mark as red.
    const boundary = target * 0.10
    if (Math.abs(shortage) < boundary) { return 'bg-primary-500' }
    return 'bg-red-400'
}

const Withdrawal: React.FC<{
    system: MeasurementUnit;
    viewBlock: PlayFieldViewBlock;
    elementTotals: ProductObject<MeasureValue>;
}> = (props) => {
    const app = useContext(AppContext)

    const calc = useMemo(() => {
        const data: ([MeasureValue, MeasureValue])[] = [
            [props.viewBlock.withdrawal_N, props.elementTotals.n],
            (app.initial.system === 'METRIC') ? [props.viewBlock.withdrawal_P, props.elementTotals.p] : [props.viewBlock.withdrawal_P205, props.elementTotals.p205],
            (app.initial.system === 'METRIC') ? [props.viewBlock.withdrawal_K, props.elementTotals.k] : [props.viewBlock.withdrawal_K20, props.elementTotals.k10],
            [props.viewBlock.withdrawal_Ca, props.elementTotals.ca],
            [props.viewBlock.withdrawal_Mg, props.elementTotals.mg]
        ]

        return data.map(([withdrawal, total], index) => {
            const target = getValue(props.system, withdrawal) * getValue(props.system, props.viewBlock.estimateProduction)
            const farmSize = getValue(props.system, props.viewBlock.size)
            const totalPerFarm = getValue(props.system, total)
            const shortage = (target - totalPerFarm / farmSize)
            return {
                key: index,
                withdrawal,
                target,
                shortage
            }
        })
    }, [props.viewBlock, props.system, props.elementTotals])

    return <table>
        <tbody>

            <tr>
                <td className="border p-1">{app.word('cultivar')}:</td>
                <td className="border p-1">{props.viewBlock.cultivarNameKey}</td>
                <td className="border p-1"></td>
                <td className="border p-1">{app.word('n')}</td>
                <td className="border p-1">{app.wordUnit('p', 'p205')}</td>
                <td className="border p-1">{app.wordUnit('k', 'k20')}</td>
                <td className="border p-1">{app.word('ca')}</td>
                <td className="border p-1">{app.word('mg')}</td>
            </tr>
            <tr>
                <td className="border p-1">{app.word('target')}:</td>
                <td className="border p-1">{roundTo(getValue(props.system, props.viewBlock.estimateProduction), 2)}</td>
                <td className="border p-1">{app.word('withdrawal')}: ({app.word(unitCustom(props.system, customUnits.kgRemovedTonHa_ozRemovedLbAc))})</td>
                {calc.map(c => <td key={c.key} className="border p-1"><FormatValue value={roundTo(getValue(props.system, c.withdrawal), 1)}/></td>)}
            </tr>
            <tr>
                <td colSpan={2}></td>
                <td className="border p-1">{app.word('target')}</td>
                {calc.map(c => <td key={c.key} className="border p-1"><FormatValue value={roundTo(c.target, 2)}/></td>)}
            </tr>
            <tr>
                <td colSpan={2}></td>
                <td className="border p-1">{app.word('shortage')}</td>
                {calc.map(c => <td key={c.key} className={classNames('border p-1', shortageClass(c.target, c.shortage))}><FormatValue value={roundTo(c.shortage, 2)}/></td>)}
            </tr>
        </tbody>
    </table>
}

export default React.memo(Withdrawal)
