import React, {Dispatch, useContext, useMemo, useRef, useState} from 'react'
import AppContext from '../../appContext'
import ProductLookUpResponse from '../../controllers/ProductLookUpResponse'
import {formatMeasureValue, measureValue} from './Helpers'
import Dialog from '../../components/Dialog'
import ProductLookup from './ProductLookup'
import NumberNullable from '../../components/NumberNullable'
import MeasurementUnit from '../../controllers/MeasurementUnit'
import {customUnits, unitCustom} from './CustomUnits'
import PaginationProductLookupRequest from '../../controllers/PaginationProductLookupRequest'
import PaginationResponse from '../../controllers/PaginationResponse'
import {FertigationState, FertigationStateAction, FertigationTank} from './fertigationCalcs'
import Input from '../../components/Input'
import productUnit from '../../controllers/ProductUnit'
import ProductUnit from '../../controllers/ProductUnit'
import {isProductColumn} from "./Functions";
import ProductColumn from "../../controllers/ProductColumn";

function getDisplayUnitL (system: MeasurementUnit) {
    return unitCustom(system, customUnits.liter_gallon)
}

interface ProductUnitMapped {
    product: ProductLookUpResponse;
    unit: productUnit;
}

function defaultFertigationUnit (system: MeasurementUnit, solid: boolean) {
    if (system === MeasurementUnit.METRIC) {
        return solid ? ProductUnit.Kg : ProductUnit.Liter;
    }

    return solid ? ProductUnit.Lb : ProductUnit.Gallon;
}

function emptyProduct(): ProductColumn {
    return {
        hexColor: "",
        imperialUnit: ProductUnit.Lb,
        index: 0,
        land: measureValue(0),
        price: measureValue(0),
        priceTotal: measureValue(0),
        productId: 0,
        standard: measureValue(0),
        textColor: "",
        type: "",
        unit: ProductUnit.Kg,
    }
}

const ProductTank: React.FC<{
    tankIndex: number;
    pumphouse: FertigationState;
    dispatch: Dispatch<FertigationStateAction>;
    tank: FertigationTank;
    products: ProductLookUpResponse[];
}> = (props) => {
    const { word, initial: { system } } = useContext(AppContext)

    const products = props.tank.products
    const selectedProduct = useRef<number | null>(null)

    const [showProduct, setShowProduct] = useState(false)

    const availableProductIds = useMemo(() => {
        // Product available to the pumphouse is the products used in the summary for the given blocks
        const range = props.pumphouse.summary.columns.filter(isProductColumn).map(c => c.productId)

        // minus the products already taken
        const taken = props.pumphouse.tanks.reduce<number[]>((prev, cur) => prev.concat(cur.products.map(p => p.id)), [])

        return range.filter(r => taken.indexOf(r) === -1)
    }, [props.pumphouse, props.tank, products])

    function productsAvailableCall (request: PaginationProductLookupRequest): PaginationResponse<ProductLookUpResponse> {
        const search = props.products.filter(p => availableProductIds.indexOf(p.id) >= 0 && p.name.includes(request.search))
        return {
            items: search,
            total: search.length
        }
    }

    function prepareProductLookup () {
        selectedProduct.current = null
        setShowProduct(true)
    }

    function addProduct (product: ProductLookUpResponse) {
        if (selectedProduct.current === null) {
            props.dispatch({ type: 'addProduct', tankIndex: props.tankIndex, product })
        } else {
            props.dispatch({ type: 'editProduct', product, index: selectedProduct.current, tankIndex: props.tankIndex })
        }

        setShowProduct(false)
    }

    function editProduct (index: number) {
        selectedProduct.current = index
        setShowProduct(true)
    }

    const productsUnit = useMemo<ProductUnitMapped[]>(() => products.map<ProductUnitMapped>((p) => {
        const summaryIndex = props.pumphouse.summary.columns.findIndex(id => isProductColumn(id) && id.productId === p.id)
        if (summaryIndex === -1) {
            return {
                product: p,
                unit: defaultFertigationUnit(system, p.solid)
            }
        }
        
        const col = props.pumphouse.summary.columns[summaryIndex];
        const product: ProductColumn = (col && isProductColumn(col)) ? col : emptyProduct();
        
        const unit = system === MeasurementUnit.METRIC
            ? product.unit
            : product.imperialUnit

        return { product: p, unit }
    }), [props.tank.products, props.pumphouse])

    return <div className="border border-gray-200 rounded-t-lg w-full">

        <Dialog show={showProduct} setShow={setShowProduct} title="Choose product"
            body={<ProductLookup 
                showRegion={false} 
                selectedRegionId={null} // if showRegion is false, this is not used
                setSelectedRegionId={() => {}} // if showRegion is false, this is not used
                productSelected={addProduct} 
                call={productsAvailableCall}/>}/>

        <table className="text-xs w-full border-collapse border-none">
            <tbody>
                <tr className="border">
                    <th colSpan={2} rowSpan={2} className="text-2xl tracking-wider text-gray-600 text-left">
                        <img className="w-12 h-12 inline-block" src="/icons/tank.svg" alt="tank"/>
                        {props.tank.name}
                    </th>
                    <th className="border" colSpan={products.length}>{word('total_product_per_block')}</th>
                    <th colSpan={2}></th>
                    <th className="border"
                        colSpan={products.length}>{word('product_per')} 1000 {getDisplayUnitL(system)} {word('water')}</th>
                </tr>
                
                <tr className="border">
                    {products.map(p =>
                        <th key={p.id} className="border text-xs text-gray-800">
                            {p.nameKey !== null ? word(p.nameKey) : p.name }
                        </th>
                    )}
                
                    <td className="text-gray-600" colSpan={2}>
                        <div className="w-8 h-8 cursor-pointer text-center text-white rounded-full table-cell align-middle text-lg bg-primary-500 shadow hover:bg-primary-600"
                            onClick={() => prepareProductLookup()}>+
                        </div>
                    </td>
                
                    {products.map(p =>
                        <th key={p.id} className="border-l border-r bg-gray-200 text-xs text-gray-800">
                            <div className="border-l border-r bg-gray-200 text-xs text-gray-800">{p.nameKey !== null ? word(p.nameKey) : p.name }</div>
                        </th>
                    )}
                </tr>

                <tr className="border">
                    <td className="border bg-gray-200 text-gray-800 text-center">{word('fenologies')}</td>
                    <td className="border bg-gray-200 text-gray-800 text-center">{word('month')}</td>
                    {productsUnit.map((p, index) =>
                        <th key={index} className="border-l border-r bg-gray-200 text-xs text-gray-800">
                            <div className="border-l border-r bg-gray-200 text-xs text-gray-800">
                                {p.unit}
                            </div>
                            <div className="inline-block align-middle text-green-500 mx-1 cursor-pointer" onClick={() => editProduct(index)}>{word('edit')}</div>

                            <div className="inline-block align-middle text-red-500 mx-1 cursor-pointer" onClick={() => {
                                props.dispatch({ type: 'removeProduct', tankIndex: props.tankIndex, index })
                            }}>X</div>
                        </th>
                    )}
                    <td className="border bg-gray-200 text-gray-800 text-center">{word('total')}</td>
                    <td className="border bg-gray-200 text-gray-800 text-center">{word('dissolved')} %</td>

                    {productsUnit.map((p, index) =>
                        <th key={index} className="border-l border-r bg-gray-200 text-xs text-gray-800">
                            <div className="border-l border-r bg-gray-200 text-xs text-gray-800">
                                {p.unit}
                            </div>
                        </th>
                    )}

                    <th>{word('total_per')} {formatMeasureValue(system, props.tank.size)} {getDisplayUnitL(system)} {word('water')}</th>
                    <th>{word('water')} ({getDisplayUnitL(system)})</th>
                    <th>{word('ec')}</th>
                    <th>{word('tanks_needed')}</th>
                    <th>{word('total')}</th>
                </tr>

                {props.tank.rows.map((row, index) =>
                    <tr key={index}>
                        <td className="border"> {row.desc}</td>
                        <td className="border"> {row.month}</td>
                        {products.map((_, index) =>
                            <td key={index} className="border">
                                {formatMeasureValue(system, row.productData[index]!.value)}
                            </td>
                        )}

                        <td className="border"> {formatMeasureValue(system, row.valueTotal)}</td>
                        <td className="border whitespace-nowrap flex w-full">
                            <NumberNullable
                            // fractionDigits={2}
                                value={row.dissolved}
                                change={value => props.dispatch({ type: 'setDissolved', tankIndex: props.tankIndex, value, rowIndex: index })}
                            >
                                {attrs => <input type="number" className="outline-none w-full border-none" {...attrs}/>}
                            </NumberNullable>

                            <svg className="h-4 w-4 ml-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                            </svg>
                        </td>
                        {products.map((_, index) =>
                            <td key={index} className="border">
                                {formatMeasureValue(system, row.productData[index]!.calcValue)}
                            </td>
                        )}
                        <td className="border"> {formatMeasureValue(system, row.calcTotal)}</td>
                        <td className="border"> {formatMeasureValue(system, props.tank.size)}</td>

                        <td className="border whitespace-nowrap flex w-full">

                            <Input value={row.ec} change={value => props.dispatch({ type: 'setEc', tankIndex: props.tankIndex, rowIndex: index, value })}>
                                {attrs => <input type="text" className="w-full outline-none cursor-pointer bg-white" {...attrs} />}
                            </Input>

                            <svg className="h-4 w-4 ml-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"/>
                            </svg>
                        </td>
                        <td className="border">{formatMeasureValue(system, row.tanksNeeded)}</td>
                        <td className="border bg-gray-200">{formatMeasureValue(system, row.total)}</td>
                    </tr>
                )}
            </tbody>
        </table>

        <table className="text-xs w-full border-collapse border border-gray my-4">
            <tbody>
                <tr className="border bg-gray-200">
                    <th className="bg-white" colSpan={2}>{word('blocks')}</th>
                    {props.pumphouse.lands.map((land ,index) =>
                        <th key={index + land.viewBlock.blockName}>{land.viewBlock.blockName}</th>
                    )}
                    <th> {word('total')}</th>
                </tr>
                {props.tank.rows.map((row, index) =>
                    <tr key={index} className="border">
                        <td>{row.desc}</td>
                        <td>{row.month}</td>
                        {props.pumphouse.lands.map((_, landIndex) =>
                            <td key={landIndex} className="border">
                                {row.landData[landIndex] ? formatMeasureValue(system, row.landData[landIndex]!) : 0}
                            </td>
                        )}
                        <td className="bg-gray-200"> {formatMeasureValue(system, row.landTotal)}</td>
                    </tr>
                )}
            </tbody>
        </table>
    </div>
}

export default ProductTank
