/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */

enum CropType {
    Annual = "Annual",
    Perennial = "Perennial"
}

export default CropType;
