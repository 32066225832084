import { HTMLAttributes, useRef, useState } from 'react'

export default function useDrag<T> (uniqueId: string, onDrop: (target: T, destination: T) => void) {

    const fromData = useRef<T | null>(null)
    const [highlight, setHighlight] = useState<T | null>(null)

    function target (data: T): HTMLAttributes<any> {
        return {
            draggable: true,
            onDragStart: e => {
                e.dataTransfer.setData('text', uniqueId)
                fromData.current = data
            },
            onDragEnd: e => {
                setHighlight(null);
                fromData.current = null;
            }
        }
    }

    function dest (data: T): HTMLAttributes<any> {
        return {
            onDragOver: e => {
                // default action is not droppable
                // prevent default is enable dropzone
                setHighlight(data);
                e.preventDefault();
            },
            onDrop: e => {
                if (fromData.current != null) {
                    onDrop(fromData.current, data);
                    setHighlight(null);
                    fromData.current = null;
                }
            }
        }
    }

    function targetAndDest(data: T): HTMLAttributes<any> {
        return {...target(data), ...dest(data)};
    }

    function isOver(data: T): boolean {
        return highlight === data;
    }

    return {
        target,
        currentDrag: fromData.current,
        dest,
        targetAndDest,
        isOver
    }
}
