/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */

enum Originate {
    Local = "Local",
    MfwInternational = "MfwInternational",
    MfwUnitedStates = "MfwUnitedStates"
}

export default Originate;
