import React, {PropsWithChildren} from 'react'

const CheckBox: React.FC<PropsWithChildren<{
    value: boolean;
    onChange?: (state: boolean) => void;
    // wrap checkbox around label. Sometimes parent want to add its own label. Default to true
    renderLabel?: boolean
}>> = (props) => {
    function changeHandler (e: React.ChangeEvent<HTMLInputElement>) {
        if (props.onChange) {
            props.onChange(e.target.checked)
        }
    }

    const content = <div className="m-1 inline-block">
        <div className="align-middle w-6 h-6 inline-block relative rounded border select-none bg-gray-200 border-gray-800 mr-1">
            <input className="opacity-0 w-0 h-0 absolute" checked={props.value} type="checkbox" onChange={changeHandler}/>
            <div className="checkbox-mark absolute inset-0 rounded hover:bg-primary-200"/>
        </div>
        {props.children}
    </div>

    if (props.renderLabel !== false) {
        return <label className="cursor-pointer">{content}</label>
    }

    return content
}

export default CheckBox
