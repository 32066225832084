/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import PaginationResponse from "./PaginationResponse";
import Client from "./Client";
import PaginationRequestSearch from "./PaginationRequestSearch";
import ClientPaged from "./ClientPaged";
import Farm from "./Farm";
import ClientAddFarmRequest from "./ClientAddFarmRequest";
import ClientRequest from "./ClientRequest";

export default {
    paged(request: PaginationRequestSearch): Promise<PaginationResponse<Client>> {
        return contentOnly(Axios.post("/client/paged", request, defaultConfig));
    },
    search(request: PaginationRequestSearch): Promise<PaginationResponse<ClientPaged>> {
        return contentOnly(Axios.post("/client/search", request, defaultConfig));
    },
    addWFarm(request: ClientAddFarmRequest): Promise<Farm> {
        return contentOnly(Axios.post("/client/addwfarm", request, defaultConfig));
    },
    upsert(request: ClientRequest): Promise<boolean> {
        return contentOnly(Axios.post("/client/upsert", request, defaultConfig));
    }
}
