import React, { PropsWithChildren, useContext } from 'react'
import SelectOptions from './SelectOptions'
import AppContext from '../appContext'

const SelectStringNullable = <TOptions, > (props: PropsWithChildren<SelectOptions<TOptions, string | null>>) => {

    const context = useContext(AppContext)
    return <select className={props.className || "input"}
                   onChange={e => props.onChange(e.target.value === '' ? null : e.target.value)}
                   value={props.value ?? ''}
    >
        <option value="">{context.word('choose_an_option')}</option>
        {props.options.map((l) => <option key={props.valueFunc(l)}
                                          value={props.valueFunc(l)}>{props.textFunc(l)}</option>)}
    </select>
}

export default SelectStringNullable;