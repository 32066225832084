/* eslint-disable */
/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */
import Axios, {AxiosResponse} from 'axios';
import {queryUrl, toFormData, defaultConfig, defaultFormConfig, contentOnly} from "./helper";
import AppState from "./AppState";
import Login from "./Login";
import ForgotRequest from "./ForgotRequest";
import ResetPasswordRequest from "./ResetPasswordRequest";
import ChangePasswordRequest from "./ChangePasswordRequest";

const urls = {
    logout(): string {
        return "/auth/logout";
    }
}

export default {
    urls,
    login(post: Login): Promise<AppState> {
        return contentOnly(Axios.post("/auth/login", post, defaultConfig));
    },
    getAppState(): Promise<AppState> {
        return contentOnly(Axios.post("/auth/getappstate", defaultConfig));
    },
    forgotPassword(request: ForgotRequest): Promise<boolean> {
        return contentOnly(Axios.post("/auth/forgotpassword", request, defaultConfig));
    },
    logout(): Promise<boolean> {
        return contentOnly(Axios.get("/auth/logout", defaultConfig));
    },
    resetPassword(request: ResetPasswordRequest): Promise<boolean> {
        return contentOnly(Axios.post("/auth/resetpassword", request, defaultConfig));
    },
    changePassword(request: ChangePasswordRequest): Promise<boolean> {
        return contentOnly(Axios.post("/auth/changepassword", request, defaultConfig));
    }
}
