import React, {InputHTMLAttributes, RefAttributes, useEffect, useRef, useState} from 'react'
import { classNames } from '../wrapper'

function toString (value: number | null, fractionDigits?: number): string {
    if (value === null) { return '' }

    if (fractionDigits === undefined) { return value.toString() }

    return value.toFixed(fractionDigits)
}

function parse(text: string): number | null {
    let num = parseFloat(text.replace(',', '.'))
    if (isNaN(num) || !isFinite(num)) {
        return null;
    }
    return num;
}

const NumberNullable: React.FC<{
    value: number | null;
    change: (number: number | null) => void;
    children?: (attrs: InputHTMLAttributes<any> & RefAttributes<any>) => React.ReactNode
    fractionDigits?: number;
    error?: boolean;
}> = (props) => {
    const [last, setLast] = useState('')
    const inputRef = useRef<HTMLInputElement>(null)
    
    useEffect(() => {
        if (inputRef.current !== document.activeElement) {
            setLast(toString(props.value, props.fractionDigits))
        }
    }, [props.value])

    function onChange (input: string) {
        setLast(input)
        if (input === '') {
            props.change(null)
            return
        }
        const v = parse(input)
        props.change(v)
    }

    function onBlur () {
        // revert to last valid value
        setLast(toString(props.value, props.fractionDigits))
    }

    if (props.children) {
        return <>
            {props.children({
                value: last,
                onBlur: () => onBlur(),
                onInput: (e) => onChange((e.target as HTMLInputElement).value),
                ref: inputRef
            })}
        </>
    }

    
    return <input ref={inputRef} className={classNames('input border', props.error ? 'border-error-300' : 'border-gray-300')} value={last} onBlur={() => onBlur()} onInput={(e) => onChange((e.target as HTMLInputElement).value)}/>
}

export default NumberNullable
