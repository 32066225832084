/**
 * This file was generated by TypeScriptGen v0.0.3
 * Any changes to this file will be lost if regenerated 
 */

enum ProductUnit {
    TonHa = "TonHa",
    STonAc = "STonAc",
    KgHa = "KgHa",
    LbAc = "LbAc",
    GHa = "GHa",
    OzAc = "OzAc",
    KgTree = "KgTree",
    LbTree = "LbTree",
    GTree = "GTree",
    OzTree = "OzTree",
    LHa = "LHa",
    GallonAc = "GallonAc",
    MlHa = "MlHa",
    PintAc = "PintAc",
    M3Ha = "M3Ha",
    Ft3Ac = "Ft3Ac",
    LTree = "LTree",
    GallonTree = "GallonTree",
    MlTree = "MlTree",
    PintTree = "PintTree",
    QuartAc = "QuartAc",
    G100Litre = "G100Litre",
    Ml100Litre = "Ml100Litre",
    Pint100Gallon = "Pint100Gallon",
    Oz100Gallon = "Oz100Gallon",
    L100Litre = "L100Litre",
    Floz100Gallons = "Floz100Gallons",
    FlozAc = "FlozAc",
    Kg = "Kg",
    Lb = "Lb",
    Liter = "Liter",
    Gallon = "Gallon",
    BinHa = "BinHa",
    BinAc = "BinAc",
    BushHa = "BushHa",
    BushAc = "BushAc"
}

export default ProductUnit;
