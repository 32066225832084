import React, { Key, useEffect, useImperativeHandle, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/outline'
import { classNames } from '../wrapper'

export interface StepTab {
    number?: string;
    text: string;
    content: () => React.ReactNode;
    key: Key;

    onFocus?: () => void;
}

export interface StepperRef {
    next: () => void;
}

type TabChangeSource = 'click' | 'next';

const Stepper: React.ForwardRefRenderFunction<StepperRef, {
    tabs: StepTab[];
    onChange?: (fromIndex: number, toIndex: number, source: TabChangeSource, fromTab: StepTab, toTab: StepTab) => boolean;
    // recreate each tab or toggle visibility between tabs
    tabMode: 'recreate' | 'toggle';
}> = (props, ref) => {
    const [active, setActive] = useState(0)

    function change (index: number, sender: TabChangeSource) {
        if (props.onChange) {
            if (props.onChange(active, index, sender, props.tabs[active]!, props.tabs[index]!)) { setActive(index) }
        } else {
            setActive(index)
        }
    }

    useImperativeHandle(ref, () => ({
        next () {
            if (active < props.tabs.length - 1) { change(active + 1, 'next') }
        }
    }))

    useEffect(() => {
        props.tabs[active]?.onFocus?.()
    }, [active])

    return <div className="relative">
        <div className="flex w-full relative items-center">
            {props.tabs.map((tab, index) => <React.Fragment key={tab.key}>
                <hr className="flex-1"/>
                <div className="text-center" onClick={() => change(index, 'click')}>
                    <div
                        className={classNames('text-white rounded-full w-6 text-center ml-2 cursor-pointer inline-block', active >= index ? 'bg-primary' : 'bg-gray-500')}>{tab.number ?? (index + 1)}</div>
                    <div className="p-1 text-sm text-secondary cursor-pointer ">{tab.text}</div>
                </div>
                {index < props.tabs.length - 1
                    ? <>
                        <hr className="flex-1 border"/>
                        <ChevronRightIcon className="h-5 text-gray-400"/>
                    </>
                    : null}

            </React.Fragment>)}
            <hr className="flex-1"/>
        </div>

        {props.tabMode === 'toggle'
            ? props.tabs.map((t, index) => <div key={t.key} className={active === index ? 'block' : 'hidden'}>{t.content()}</div>)
            : props.tabs[active]!.content()}
    </div>
}

export default React.forwardRef(Stepper)
