import React, {useContext, useRef, useState} from "react";
import PagedSearchTable, {PagedTableFunctions} from "../components/PagedSearchTable";
import CurrencyController from "../controllers/CurrencyController";
import AppContext from "../appContext";
import {EditRow, EditTable, focusFirstInput} from "../components/Fields";
import Input from "../components/Input";
import Dialog from "../components/Dialog";
import CurrencyPagedResponse from "../controllers/CurrencyPagedResponse";
import {useValidation} from "../validation";
import {buildSetter} from "../immutableState";
import {showSuccessOrFailed} from "../Snacks";
import Warning, {useWarningState} from "./program/Warning";

const emptyCurrency: CurrencyPagedResponse = {
    id: 0,
    name: '',
    format: '',
    symbol: '',
    removed: false
}

const Currencies: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<CurrencyPagedResponse>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<CurrencyPagedResponse>(emptyCurrency)
    const setData = buildSetter(upsertData, setUpsertData)
    
    const warningState = useWarningState(-1)
    
    const validation = useValidation({
        name: () => upsertData.name.length > 0,
        symbol: () => upsertData.symbol.length > 0,
        format: () => upsertData.format.length > 0
    })
    function showPopup (preset: CurrencyPagedResponse) {
        setUpsertData({
            id: preset.id,
            name: preset.name ?? '',
            symbol: preset.symbol ?? '',
            format: preset.format ?? '',
            removed: preset.removed
        })
        setShow(true)
    }

    function upsertCallback () {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, CurrencyController.upsert(upsertData)).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    function deleteCurrency() {
        showSuccessOrFailed(context, CurrencyController.remove({id: warningState.state.data})).then(() => {
            pagedTableRef.current?.refresh()
            warningState.hide()
    })}

    return <div>
        <div className="btn bg-primary m-2" onClick={() => showPopup(emptyCurrency)}>{context.word('add_currency')}</div>
        <PagedSearchTable call={CurrencyController.paged}
                          componentRef={pagedTableRef}
                          keyExtractor={c => c.id}
                          columns={[
                              {
                                  header: context.word('name'),
                                  row: (item) => item.name
                              },
                              {
                                  header: context.word('symbol'),
                                  row: (item) => item.symbol
                              },
                              {
                                  header: context.word('format'),
                                  row: (item) => item.format
                              },
                              {
                                  header: <div className="">{context.word('action')}</div>,
                                  row: item => <div className="">
                                      <span className="underline cursor-pointer"
                                            onClick={() => showPopup(item)}>{context.word('edit')}</span>
                                      <span>&nbsp;|&nbsp;</span>
                                      <span onClick={() => warningState.show("Are you sure you want to remove " + item.name, item.id)}
                                            className="cursor-pointer py-2 pr-2 underline font-medium">{context.word('remove')}</span>

                                  </div>
                              }
                          ]}/>
        <Dialog mounted={focusFirstInput} title={context.word('currency')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback()} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('name'),
                            <Input value={upsertData.name} change={v => setData({name: v})} />,
                            validation.rules.name,
                            context.word('name_required')
                        )}
                        {EditRow(
                            context.word('symbol'),
                            <Input value={upsertData.symbol} change={v => setData({symbol: v})} />,
                            validation.rules.symbol,
                            context.word('symbol_required')
                        )}
                        {EditRow(
                            context.word('format'),
                            <Input value={upsertData.format} change={v => setData({format: v})} />,
                            validation.rules.format,
                            context.word('format_required')
                        )}
                    </EditTable>
                }/>
        
        <Warning 
            state={warningState}
            onYes={() => deleteCurrency()}>
        </Warning>
    </div>
}

export default Currencies;