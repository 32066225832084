import PagedSearchTable, { PagedTableFunctions } from '../components/PagedSearchTable'
import React, { useContext, useRef, useState } from 'react'
import Dialog from '../components/Dialog'
import { EditRow, EditTable, focusFirstInput } from '../components/Fields'
import CompanyController from '../controllers/CompanyController'
import Company from '../controllers/Company'
import AppContext from '../appContext'
import CompanyRequest from '../controllers/CompanyRequest'
import { buildSetter } from '../immutableState'
import { useValidation } from '../validation'
import Input from '../components/Input'
import { showSuccessOrFailed } from '../Snacks'

const emptyCompany: CompanyRequest = {
    id: 0,
    name: ''
}

const Companies: React.FC = () => {
    const context = useContext(AppContext)
    const pagedTableRef = useRef<PagedTableFunctions<Company>>()
    const [show, setShow] = useState(false)
    const [upsertData, setUpsertData] = useState<CompanyRequest>(emptyCompany)
    const setData = buildSetter(upsertData, setUpsertData)

    function showPopup (preset: Company) {
        setUpsertData({
            id: preset.id,
            name: preset.name ?? ''
        })
        setShow(true)
    }

    function upsertCallback (data: CompanyRequest) {
        if (!validation.validate()) { return }
        showSuccessOrFailed(context, CompanyController.upsert(data)).then(() => {
            pagedTableRef.current?.refresh()
            setShow(false)
        })
    }

    const validation = useValidation({
        nameRequired: () => upsertData.name.length > 0
    })

    return (
        <>
            <Dialog mounted={focusFirstInput} title={context.word('company')} show={show} setShow={setShow}
                body={
                    <EditTable save={() => upsertCallback(upsertData)} discard={() => setShow(false)} saveWord={upsertData.id === 0 ? 'insert' : 'update'}>
                        {EditRow(
                            context.word('name'),
                            <Input value={upsertData.name} change={v => setData({ name: v })} />,
                            validation.rules.nameRequired,
                            context.word('name_required')
                        )}
                    </EditTable>
                }/>

            <div className="btn bg-primary m-2" onClick={() => showPopup(emptyCompany)}>{context.word('add_company')}</div>
            <PagedSearchTable<Company>
                componentRef={pagedTableRef}
                call={CompanyController.paged}
                columns={[
                    { header: context.word('name'), row: item => item.name },
                    {
                        header: context.word('action'),
                        row: item => <div>
                            <span className="underline cursor-pointer" onClick={() => showPopup(item)}>{context.word('edit')}</span>
                        </div>
                    }
                ]}
                keyExtractor={i => i.id}/>

        </>
    )
}

export default Companies
